const errorCodes = Object.freeze({
  // // Authentication
  // 10: "У Вас нет прав на доступ к данному ресурсу",
  // // Account does not exist
  // 11: "Аккаунт не найден",
  // // Authentication status cannot be received
  // 12: "Ошибка аутентификации",
  // // Account as set to inactive state
  // 13: "Аккаунт неактивен",
  //
  // 14: "Аккаунт уже используется",
  //
  // 20: "Такой гость не существует",
  //
  // 30: "Ошибка создания клиента",
  // 31: "Карточка уже существует",
  // 32: "Такой клиент не существует",
  //
  // 40: "Фото с таким ID не найдено",
  // 41: "Ошибка удаления фотографии",
  //
  // 51: "Не все поля посетителя",
  //
  // 60: "Ошибка редактирования комментария",
  //
  // 70: "Ошибка удаления комментария",
  //
  // "default": "Ошибка соединения",
  // Authentication
  10: "errors.10",
  // Account does not exist
  11: "errors.11",
  // Authentication status cannot be received
  12: "errors.12",
  // Account as set to inactive state
  13: "errors.13",

  14: "errors.14",

  20: "errors.20",

  30: "errors.30",
  31: "errors.31",
  32: "errors.32",

  40: "errors.40",
  41: "errors.41",

  51: "errors.51",

  60: "errors.60",

  70: "errors.70",

  "default": "errors.default",
});

export function getErrorCodeMessage(errorCode){
  const errorText = errorCodes[errorCode];
  if(!errorText) return errorCodes.default;
  else return errorText;
}