import {NOTIFICATION_CONFIG} from "../../notification/notification";
import {createAction} from "../../factory/redux/action";

export const ACTION_TYPE = Object.freeze({
  SHOW_NOTIFICATION: "SHOW_NOTIFICATION",
});

export const SHOW_NOTIFICATION = (
    payload = {
      type: NOTIFICATION_CONFIG.TEST,
      title: "",
      message: "",
      actions: null,
    }
) => createAction(
    ACTION_TYPE.SHOW_NOTIFICATION,
    payload,
);
