import {ACTION_TYPE as COMMON_ACTION_TYPE} from "../actions/common";
import {ACTION_TYPE} from "../actions/comment";

const initialState = {

  inProcess: false,

  add: {
    showModal: false,
    comment: null,
  },
  edit: {
    showModal: false,
    comment: null,
  },
  delete: {
    showModal: false,
    comment: null,
  },

};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPE.DROP_COMMENT_DATA:
    case COMMON_ACTION_TYPE.CLEAR_STORE:
      return {
        ...initialState,
      };

    case ACTION_TYPE.SET_COMMENT_IN_PROCESS:
      return {
        ...state,
        inProcess: action.payload,
      };

      // add
    case ACTION_TYPE.SET_COMMENT_SHOW_ADD_MODAL: {
      return {
        ...state,
        add: {
          ...state.add,
          showModal: action.payload,
        },
      };
    }

    case ACTION_TYPE.SET_COMMENT_ADD: {
      return {
        ...state,
        add: {
          ...state.add,
          comment: action.payload,
        },
      };
    }

    // edit
    case ACTION_TYPE.SET_COMMENT_EDIT_SHOW_MODAL: {
      return {
        ...state,
        edit: {
          ...state.edit,
          showModal: action.payload,
        },
      };
    }

    case ACTION_TYPE.SET_COMMENT_EDIT: {
      return {
        ...state,
        edit: {
          ...state.edit,
          comment: action.payload,
        },
      }
    }

      // delete
    case ACTION_TYPE.SET_COMMENT_DELETE_SHOW_MODAL: {
      return {
        ...state,
        delete: {
          ...state.delete,
          showModal: action.payload,
        },
      };
    }

    case ACTION_TYPE.SET_COMMENT_DELETE: {
      return {
        ...state,
        delete: {
          ...state.delete,
          comment: action.payload,
        },
      }
    }

    default:
      return state;
  }
}