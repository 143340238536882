export function createServerInfoRedux({
                                        organizationName = "",
                                        showNameToOthers = false,
                                        organizationAddress = "",
                                        version = "",
                                      }) {

  return {

    organizationName: {
      value: organizationName,
      isValid: null,
    },
    showNameToOthers: {
      value: showNameToOthers,
      isValid: null
    },
    organizationAddress: {
      value: organizationAddress,
      isValid: null,
    },

    version: {
      value: version,
      isValid: null,
    },
  };

}