import {parseDate} from "../../utils/date";
import {createDocumentData} from "./documentData";


export const createPersonalData = ({
                                     id = null,
                                     familyName = "",
                                     familyNameIsValid = null,
                                     name = "",
                                     nameIsValid = null,
                                     patronymic = "",
                                     patronymicIsValid = null,
                                     dateOfBirth = null,
                                     dateOfBirthIsValid = null,
                                     kaloevId = "",
                                     establishmentId=-1,
                                     kaloevIdIsValid = null,
                                     receivedFromDevice = false,
                                     exists = false,
                                     documentDataList = [],
                                     ...other
                                   }) => ({
  id,
  familyName: {
    value: familyName,
    isValid: familyNameIsValid,
    errorMessage: "",
  },
  name: {
    value: name,
    isValid: nameIsValid,
    errorMessage: "",
  },
  patronymic: {
    value: patronymic,
    isValid: patronymicIsValid,
    errorMessage: "",
  },
  dateOfBirth: {
    value: parseDate(dateOfBirth),
    isValid: dateOfBirthIsValid,
    errorMessage: "",
  },
  kaloevId: {
    value: kaloevId,
    isValid: kaloevIdIsValid,
    errorMessage: "",
  },
  establishmentId: establishmentId,
  receivedFromDevice,

  // client with provided parameters already exists in system
  exists: {
    value: exists,
    cardId: null,
    showModal: false,
  },

  // documentDataList: [
  //   ...documentDataList,
  // ],
  documentDataList: (
      (documentDataList.length > 0)
          ? (documentDataList.map(
          (documentData) => createDocumentData(documentData)
          ))
          : [createDocumentData({})]
  ),

  // original values
  original: {
    id,
    familyName,
    familyNameIsValid,
    name,
    nameIsValid,
    patronymic,
    patronymicIsValid,
    dateOfBirth,
    dateOfBirthIsValid,
    kaloevId,
    kaloevIdIsValid,
    receivedFromDevice,
    exists,
    documentDataList,
    ...other,
  },
});