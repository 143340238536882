import {ACTION_TYPE as COMMON_ACTION_TYPE} from "../actions/common";
import {ACTION_TYPE} from "../actions/visitorData";
import {createComment} from "../../factory/redux/comment";
import {createVisitor} from "../../factory/redux/visitor";


const initialState = {
  inProcess: false,
  addCommentToVisitorInProcess: false,
  visitor: createVisitor({}),
  fullMatchCards: [],
  partialMatchCards: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPE.DROP_VISITOR_DATA:
    case COMMON_ACTION_TYPE.CLEAR_STORE:
      return {
        ...initialState,
      };
    case ACTION_TYPE.SET_VISITOR_DATA_IN_PROCESS:
      return {
        ...state,
        inProcess: action.payload,
      };
    case ACTION_TYPE.SET_ADD_COMMENT_TO_VISITOR_IN_PROCESS:
      return {
          ...state,
        addCommentToVisitorInProcess: action.payload,
      };
    case ACTION_TYPE.SET_VISITOR_DATA:
      return {
        ...state,
        visitor: createVisitor(action.payload.visitor),
        // visitor: {
        //     ...state.visitor,
        //     ...action.payload.visitor,
        // },

        fullMatchCards: [...action.payload.fullMatchCards],
        partialMatchCards: [...action.payload.partialMatchCards],

      };

    default:
      return state;
  }
}