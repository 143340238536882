import {ACTION_TYPE as COMMON_ACTION_TYPE} from "../actions/common";
import {ACTION_TYPE} from "../actions/theme";
import {theme} from "../../constants/theme";


const initialState = {
  theme: theme.DARK,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    // case COMMON_ACTION_TYPE.CLEAR_STORE:
    //   return {
    //     ...initialState,
    //   };
    case ACTION_TYPE.SET_THEME: {

      // turned off for production

      // localStorage.setItem('theme', action.payload);
      //
      // return {
      //   ...state,
      //   theme: theme[action.payload]
      //       ? theme[action.payload]
      //       : initialState.theme,
      // };

      return state;
    }

    default:
      return state;
  }
}