import {createAction} from "../../factory/redux/action";
import {MESSAGE_TYPES} from "../../constants/websocket";
import {joinStrings} from "../../utils/string";
import {ADD_VISITOR, DELETE_VISITOR, loadVisitors, UPDATE_VISITOR} from "./visitors";
import {NOTIFICATION_CONFIG} from "../../notification/notification";
import {
  ADD_CLIENT_BY_WEBSOCKET,
  DELETE_CLIENT_BY_WEBSOCKET, loadClients, loadNewClients, SET_NEW_UNREAD_CLIENTS_COUNTER,
  UPDATE_CLIENT_BY_WEBSOCKET
} from "./cards";
import {
  SET_SHOW_LICENCE_MODAL,
  SET_SHOW_LICENCE_WILL_TERMINATE
} from "./licence";
import {checkAccessRights} from "../../utils/authentication";
import {roles} from "../../constants/roles";
import {commentTypes} from "../../constants/commentTypes";
import {createNotification} from "../../factory/redux/notification";
import {SHOW_NOTIFICATION} from "./notification";
import {SET_OPTIONS} from "./options";
import addNotification from 'react-push-notification';
import {formatUrl} from "../../utils/url";
import {appUrls} from "../../constants/urls";

export const ACTION_TYPE = Object.freeze({
  WS_CONNECT: "WS_CONNECT",
  WS_CONNECTING: "WS_CONNECTING",
  WS_CONNECTED: "WS_CONNECTED",
  WS_DISCONNECT: "WS_DISCONNECT",
  WS_DISCONNECTED: "WS_DISCONNECTED",

  WS_ERROR: "WS_ERROR",

  WS_SEND_MESSAGE: "WS_SEND_MESSAGE",
});


export const WS_CONNECT = (host) => createAction(
    ACTION_TYPE.WS_CONNECT,
    host
);
export const WS_CONNECTING = (host) => createAction(
    ACTION_TYPE.WS_CONNECTING,
    host
);
export const WS_CONNECTED = (host) => createAction(
    ACTION_TYPE.WS_CONNECTED,
    host
);
export const WS_DISCONNECT = (host) => createAction(
    ACTION_TYPE.WS_DISCONNECT,
    host
);
export const WS_DISCONNECTED = (host) => createAction(
    ACTION_TYPE.WS_DISCONNECTED,
    host
);
export const WS_ERROR = (host) => createAction(
    ACTION_TYPE.WS_ERROR,
    host
);



export const WS_SEND_MESSAGE = (message) => createAction(
    ACTION_TYPE.WS_SEND_MESSAGE,
    message
);


export function processMessage(message) {

  return (dispatch, getState) => {

    // trigger appropriate event on message receive
    switch (message.type) {
      case MESSAGE_TYPES.NEW_CARD_CREATED: {
        return _processNewClientMessage({
          dispatch,
          getState,
          message
        });
      }
      case MESSAGE_TYPES.NEW_VISITOR_CREATED: {
        return _processNewVisitorMessage({
          dispatch,
          getState,
          message
        });
      }
      case MESSAGE_TYPES.CLIENT_CREATED: {
        return _processNewVisitorMessage({
          dispatch,
          getState,
          message
        });
      }
      case MESSAGE_TYPES.LICENCE_WARNING: {
        return _processLicenceWarningMessage({
          dispatch,
          getState,
          message
        });
      }

      case MESSAGE_TYPES.OPTIONS_UPDATED: {
        return _processOptionsUpdatedMessage({
          dispatch,
          getState,
          message
        });
      }

      case MESSAGE_TYPES.VISITOR_CREATED: {
        return _processVisitorCreatedMessage({
          dispatch,
          getState,
          message
        });
      }
      case MESSAGE_TYPES.VISITOR_UPDATED: {
        return _processVisitorUpdatedMessage({
          dispatch,
          getState,
          message
        });
      }
      case MESSAGE_TYPES.VISITOR_DELETED: {
        return _processVisitorDeletedMessage({
          dispatch,
          getState,
          message
        });
      }
      // case MESSAGE_TYPES.CLIENT_CREATED: {
      //   return _processClientCreatedMessage({
      //     dispatch,
      //     getState,
      //     message
      //   });
      // }
      case MESSAGE_TYPES.CLIENT_DELETED: {
        return _processClientDeletedMessage({
          dispatch,
          getState,
          message
        });
      }
      case MESSAGE_TYPES.CLIENT_UPDATED: {
        return _processClientUpdatedMessage({
          dispatch,
          getState,
          message
        });
      }

      default:
        break;
    }

  }
}

/**
 * On receiving New Client Created message
 * @param dispatch
 * @param getState
 * @param message
 * @private
 */
function _processNewClientMessage({
                                         dispatch,
                                         getState,
                                         message,
                                         ...other
                                       }) {
  const globalState = getState();
  const {accountData} = globalState.authentication;

  if (parseInt(accountData.establishment, 10) === message.establishmentId) {
    return;
  }

  dispatch(loadClients());
  setTimeout(()=>{
    dispatch(loadNewClients());
  }, 1000);
  dispatch(SET_NEW_UNREAD_CLIENTS_COUNTER(countUnRead(getState().cards.newList, getState().authentication)));

  /*
   * Pop-up notification if needed
   */
  // return addNotification({
  //   title: 'Внимание',
  //   subtitle: 'Найден новый гость',
  //   message: `Новый гость ${message.cardName} из ${message.establishment}`,
  //   duration: 3000000,
  //   backgroundTop: 'rgb(32, 32, 32)',
  //   backgroundBottom: 'rgb(57,57,57)',
  //   onClick: (e) => {
  //     if (e.target.className !== 'rpn-notification-card-close undefined') {
  //       let clientLink = formatUrl(
  //         appUrls.client.url,
  //         {
  //           clientId: message.cardId,
  //           establishmentId: message.establishmentId,
  //         }
  //       );
  //       // window.history.push(clientLink);
  //       window.location.href = clientLink;
  //     }
  //   }
  // });
}
function countUnRead(newClients, authentication) {
  let counter = 0;
  if (!newClients)
    return counter;
  newClients.forEach(client => {
    if (client.establishmentID !== authentication.accountData.establishment  && client.new && !client.read)
      counter++;
  })
  return counter;
}

/**
 * On receiving New Visitor Created message
 * @param dispatch
 * @param getState
 * @param message
 * @private
 */
function _processNewVisitorMessage({
                                    dispatch,
                                    getState,
                                    message,
                                    ...other
                                  }) {
  const globalState = getState();
  const {accountData} = globalState.authentication;
  if (parseInt(accountData.establishment, 10) !== message.establishmentId) {
    return;
  }

  if (message.type === "CLIENT_CREATED") {
    dispatch(loadClients());
  } else {
    dispatch(loadVisitors());
  }

}

/**
 * On receiving Licence Warning message
 * @param dispatch
 * @param getState
 * @param message
 * @private
 */
function _processLicenceWarningMessage({
                                         dispatch,
                                         getState,
                                         message,
                                         ...other
                                       }) {
  const
      willTerminate = message.payload;
  if (willTerminate) {
    dispatch(SET_SHOW_LICENCE_WILL_TERMINATE(true));
    dispatch(SET_SHOW_LICENCE_MODAL(true));

  } else {

    dispatch(SET_SHOW_LICENCE_WILL_TERMINATE(false));
    dispatch(SET_SHOW_LICENCE_MODAL(true));

  }

}

function _processOptionsUpdatedMessage({
                                         dispatch,
                                         getState,
                                         message,
                                         ...other
                                       }) {

  const
      options = message.payload;

  if(options) {
    dispatch(SET_OPTIONS(options));
  }


}


/**
 * On receiving Visitor Created message
 * @param dispatch
 * @param getState
 * @param messageObj
 * @private
 */
function _processVisitorCreatedMessage({
                                         dispatch,
                                         getState,
                                         message: messageObj,
                                         ...other
                                       }) {

  const
      globalState = getState();

  const
      {accountData} = globalState.authentication,
      visitorData = messageObj.payload;


  // check if visitor should be displayed for the current account
  if (checkAccessRights(
      accountData.roles,
      [
        roles.admin,
      ],
      true
  )) {
    // do nothing and continue execution
  } else if (visitorData.establishmentId === -1) {
    // exit function for visitors with no establishment
    return;
  } else {

    const
        accountEstablishments = accountData.establishment.split(",");

    let
        establishmentFound = false;

    // iterate through establishments of account and compare with visitor
    accountEstablishments.forEach((establishmentId) => {
      if(establishmentId == visitorData.establishmentId) {
        establishmentFound = true;
      }
    });

    if (establishmentFound) {
      // do nothing and continue execution
    } else {
      // exit function
      return;
    }

  }


  dispatch(ADD_VISITOR(visitorData));


  // NOTIFICATIONS

  const
      criticalList = [],
      warningList = [],
      greyList = [],
      pinkList = [],

      title = joinStrings(
          [
            visitorData.familyName,
            visitorData.name,
            visitorData.patronymic
          ],
          " "
      );

  // notify about new document
  if (visitorData.documentChange) {
    pinkList.push({
      config: NOTIFICATION_CONFIG.WARNING_PINK,
      title,
      message: "Новый документ добавлен в карточку",
    });
  }

  // notify about new document
  if (visitorData.documentInvalid) {
    criticalList.push({
      config: NOTIFICATION_CONFIG.CRITICAL_DOC,
      title,
      message: "Невалидный документ",
    });
  }

  // notify about new document
  if (visitorData.ageBan) {
    criticalList.push({
      config: NOTIFICATION_CONFIG.CRITICAL_21,
      title,
      message: "Моложе 21 года",
    });
  }

  // tax service
  if (visitorData.taxServiceBan) {
    criticalList.push({
      config: NOTIFICATION_CONFIG.TAX_BAN,
      title,
      message: "Запрет от налоговой",
    });
  }

  const
      commentTypeList = getState().options.commentTypeList;

  // local comments
  for (const localComment of visitorData.localComments) {
    for (const commentType of commentTypeList) {

      if (localComment.type === commentType.type) {

        // add to critical list
        if (commentType.critical) {
          if (commentType.type === commentTypes.BLACKLIST) {

            criticalList.push({
              config: NOTIFICATION_CONFIG.BLACKLIST,
              title,
              message: "Гость занесён в чёрный список заведения"
            });

          } else {

            criticalList.push({
              config: NOTIFICATION_CONFIG.WARNING_RED,
              title,
              message: commentType.nameShort,
            });

          }
        }

        // add to warning list
        if (commentType.warning) {
          warningList.push({
            config: NOTIFICATION_CONFIG.WARNING_YELLOW,
            title,
            message: commentType.nameShort,
          });
        }

        if (commentType.unimportant) {
          greyList.push({
            config: NOTIFICATION_CONFIG.WARNING_GRAY,
            title,
            message: commentType.nameShort,
          });
        }

        break;
      }
    }
  }

  let
      showNotification = false,
      config = NOTIFICATION_CONFIG.WARNING_GRAY,
      message = "";

  // display notifications
  if (criticalList.length > 0) {

    config = NOTIFICATION_CONFIG.WARNING_RED;

    if (criticalList.length === 1) {

      ({config, message} = criticalList[0]);

    } else {
      message = joinStrings(
          criticalList.map(criticalElement => criticalElement.message),
          "\n"
      );
    }

    // attach pink messages
    if (pinkList.length > 0) {
      message = joinStrings(
          [
            message,
            pinkList.map(pinkElement => pinkElement.message),
          ],
          "\n"
      );
    }

    showNotification = true;

  } else if (warningList.length > 0) {

    config = NOTIFICATION_CONFIG.WARNING_YELLOW;

    if (warningList.length === 1) {

      ({config, message} = warningList[0]);
      message = "BlackList + комментарии: " + message;

    } else {
      message = "BlackList + комментарии: " + joinStrings(
          warningList.map(warningElement => warningElement.message),
          ", "
      );
    }

    // attach pink messages
    if (pinkList.length > 0) {
      message = joinStrings(
          [
            message,
            pinkList.map(pinkElement => pinkElement.message),
          ],
          "\n"
      );
    }

    showNotification = true;

  } else if (greyList.length > 0) {

    config = NOTIFICATION_CONFIG.WARNING_GRAY;

    if (greyList.length === 1) {

      ({config, message} = greyList[0]);
      message = "Локальные заметки: " + message;

    } else {
      message = "Локальные заметки: " + joinStrings(
          greyList.map(greyElement => greyElement.message),
          ", "
      );
    }

    // attach pink messages
    if (pinkList.length > 0) {
      message = joinStrings(
          [
            message,
            pinkList.map(pinkElement => pinkElement.message),
          ],
          "\n"
      );
    }

    showNotification = true;

  } else if (pinkList.length > 0) {

    config = NOTIFICATION_CONFIG.WARNING_PINK;

    if (pinkList.length === 1) {

      ({config, message} = pinkList[0]);

    } else {
      message = joinStrings(
          pinkList.map(pinkElement => pinkElement.message),
          "/n"
      );
    }

    showNotification = true;
  }

  if(showNotification) {

    dispatch(SHOW_NOTIFICATION(
        createNotification({
          config,
          title,
          message,
        })
    ));

  }

}


function _processVisitorUpdatedMessage({
                                         dispatch,
                                         getState,
                                         message,
                                         ...other
                                       }) {
  const visitorData = message.payload;
  dispatch(UPDATE_VISITOR(visitorData));


  // NOTIFICATIONS

  let canDisplayNotification = visitorData.notifyGlobalComments;

  if (
      visitorData.documentInvalid ||
      visitorData.ageBan ||
      visitorData.taxServiceBan ||
      visitorData.localComments.length > 0
  ) {
    canDisplayNotification = false;
  }


  // show notification for global comments
  if (
      canDisplayNotification &&
      visitorData.globalComments.length > 0
  ) {

    const
        commentTypeList = getState().options.commentTypeList,
        config = NOTIFICATION_CONFIG.WARNING_GRAY,
        usedGlobalCommentsNames = [];

    let
        message = "";

    for (const globalComment of visitorData.globalComments) {
      for (const commentType of commentTypeList) {

        if (globalComment.type === commentType.type) {
          if (usedGlobalCommentsNames.indexOf(commentType.nameShort) === -1) {
            usedGlobalCommentsNames.push(commentType.nameShort);
          }
        }
      }
    }

    if (usedGlobalCommentsNames.length > 0) {
      const
          title = joinStrings(
              [
                visitorData.familyName,
                visitorData.name,
                visitorData.patronymic
              ],
              " "
          ),
          message = "Глобальные комментарии: " + joinStrings(
              usedGlobalCommentsNames,
              ", "
          );

      dispatch(SHOW_NOTIFICATION(
          createNotification({
            config: NOTIFICATION_CONFIG.WARNING_GRAY,
            title,
            message,
          })
      ));

    }
  }
}

function _processVisitorDeletedMessage({
                                         dispatch,
                                         getState,
                                         message,
                                         ...other
                                       }) {
  const visitorData = message.payload;
  dispatch(DELETE_VISITOR(visitorData));
}

/**
 * On receiving Client Created message
 * @param dispatch
 * @param getState
 * @param message
 * @param other
 * @private
 */
function _processClientCreatedMessage({
                                        dispatch,
                                        getState,
                                        message,
                                        ...other
                                      }) {
  const clientData = message.payload;

  dispatch(ADD_CLIENT_BY_WEBSOCKET(clientData));
}

/**
 * On receiving Client Created message
 * @param dispatch
 * @param getState
 * @param message
 * @param other
 * @private
 */
function _processClientDeletedMessage({
                                        dispatch,
                                        getState,
                                        message,
                                        ...other
                                      }) {
  const cardId = message.payload;

  dispatch(DELETE_CLIENT_BY_WEBSOCKET(cardId));
}

/**
 * On receiving Client Updated message
 * @param dispatch
 * @param getState
 * @param message
 * @param other
 * @private
 */
function _processClientUpdatedMessage({
                                        dispatch,
                                        getState,
                                        message,
                                        ...other
                                      }) {
  const clientData = message.payload;

  dispatch(UPDATE_CLIENT_BY_WEBSOCKET(clientData));


}