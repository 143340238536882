import {fetchPost} from "../../utils/request";
import {apiUrls} from "../../constants/urls";
import {createAction} from "../../factory/redux/action";


export const ACTION_TYPE = Object.freeze({
  DROP_CARD_DATA: "DROP_CARD_DATA",
  SET_CARD_DATA: "SET_CARD_DATA",
  SET_CARD_DATA_IN_PROCESS: "SET_CARD_DATA_IN_PROCESS",
  SET_CARD_DATA_SUCCESS: "SET_CARD_DATA_SUCCESS",
  SET_CARD_DATA_PHOTO: "SET_CARD_DATA_PHOTO",
  SET_CARD_DATA_SHOW_DELETE_CARD: "SET_CARD_DATA_SHOW_DELETE_CARD",
  SET_CARD_DATA_DELETE_CARD_ID: "SET_CARD_DATA_DELETE_CARD_ID",

});

export const DROP_CARD_DATA = (payload) => createAction(
    ACTION_TYPE.DROP_CARD_DATA,
    payload
);

const SET_CARD_DATA = (payload) => createAction(
    ACTION_TYPE.SET_CARD_DATA,
    payload
);

const SET_CARD_DATA_IN_PROCESS = (payload) => createAction(
    ACTION_TYPE.SET_CARD_DATA_IN_PROCESS,
    payload
);

const SET_CARD_DATA_SUCCESS = (payload) => createAction(
    ACTION_TYPE.SET_CARD_DATA_SUCCESS,
    payload
);

const SET_CARD_DATA_PHOTO = (payload) => createAction(
    ACTION_TYPE.SET_CARD_DATA_PHOTO,
    payload
);

export const SET_CARD_DATA_SHOW_DELETE_CARD = (payload) => createAction(
    ACTION_TYPE.SET_CARD_DATA_SHOW_DELETE_CARD,
    payload
);

export const SET_CARD_DATA_DELETE_CARD_ID = (payload) => createAction(
    ACTION_TYPE.SET_CARD_DATA_DELETE_CARD_ID,
    payload
);





export function loadClientFullData(clientId, establishmentId) {
  return (dispatch, getState) => {

    dispatch(DROP_CARD_DATA());
    dispatch(SET_CARD_DATA_IN_PROCESS(true));

    return fetchPost(
        apiUrls.client.get.url,
        {
          clientId, establishmentId
        }
    )
        .then((response) => {
          if (response.success) {

            const visitorData = response.data;
            const {photoList} = visitorData;

            dispatch(SET_CARD_DATA(visitorData));

            dispatch(loadClientPhotos(photoList.map(photo => photo.id)));

            dispatch(SET_CARD_DATA_SUCCESS(true));

          } else {
            dispatch(SET_CARD_DATA_SUCCESS(false));

            return Promise.reject(new Error("Card error"));
          }
        })
        .finally(() => {
          dispatch(SET_CARD_DATA_IN_PROCESS(false));
        });
  }
}

function loadClientPhotos(photoIdArray = []) {
  return async (dispatch, getState) => {

    for (const photoId of photoIdArray) {

      const getPhotoResponse = await loadPhoto(photoId);

      if (getPhotoResponse.success) {
        dispatch(SET_CARD_DATA_PHOTO(getPhotoResponse.photoData));
      }
    }

  }
}

function loadPhoto(photoId) {
  return fetchPost(
      apiUrls.photo.getPhoto.url,
      {
        photoId: photoId,
      }
  )
}

export function deleteCard(cardId) {
  return (dispatch, getState) => {
    dispatch(SET_CARD_DATA_IN_PROCESS(true));

    fetchPost(
        apiUrls.client.delete.url,
        {
          cardId,
        }
    )
        .then((response) => {
          if(response.success) {

          }
        })
        .finally(() => {
          dispatch(SET_CARD_DATA_IN_PROCESS(false));
        });

  }
}