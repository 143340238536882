export const ErrorCode = Object.freeze({
  AUTHENTICATION_STATUS_ERROR: 12,
});

export const ErrorCodeText = Object.freeze({
  // UNAUTHORIZED
  10: "UNAUTHORIZED",
  // Account does not exist
  // ACCOUNT_DOES_NOT_EXIST
  11: "Аккаунт не найден",
  // Authentication status cannot be received
  // AUTHENTICATION_STATUS_ERROR
  12: "Ошибка аутентификации",
  // Account as set to inactive state
  // ACCOUNT_IS_INACTIVE
  13: "Аккаунт неактивен",

  // VISITOR_DOES_NOT_EXIST
  20: "Такой гость не существует",

  // CARD_CREATE_FAILED
  30: "Ошибка создания клиента",
  // CARD_ALREADY_EXISTS
  31: "Карточка уже существует",
  // CARD_DOES_NOT_EXIST
  32: "Такой клиент не существует",
  // CARD_DELETE_FAILED
  33: "Ошибка удаления карточки",

  // PHOTO_WITH_ID_NOT_FOUND
  40: "Фото с таким ID не найдено",
  // DELETE_PHOTO_FAILED
  41: "Ошибка удаления фотографии",

  // VISITOR_CREATION_ERROR
  51: "Не все поля посетителя",

  // UPDATE_COMMENT_FAILED
  60: "Ошибка редактирования комментария",
  // ADD_COMMENT_FAILED
  61: "Ошибка создания комментария",

  // DELETE_COMMENT_FAILED
  70: "Ошибка удаления комментария",


  // HASH_RECALCULATION_FAILED
  80: "Ошибка пересчёта хэша",
});