import {requestTypes} from "../constants/requestTypes";
import {IS_DEBUG} from "../constants/debug";
import {replaceAll} from "./string";
import {appUrls, DEVELOPMENT_SERVER_URL} from "../constants/urls";
import {ErrorCode} from "../error/api/ErrorCode";
import {signOut} from "../redux/actions/authentication";

let
    dispatch = null,
    history = null;

export function setRequestDispatch(newDispatch) {
    dispatch = newDispatch;
}

export function setRequestHistory(newHistory) {
    history = newHistory;
}

const encodeFormParams = (json) => (
    Object
        .keys(json)
        .map((key) =>
            encodeURIComponent(key) + "=" + encodeURIComponent(json[key])
        )
        .join("&")
);

export const getAuthHeader = (clientId, clientSecret) => ({
    "Authorization": "Basic " + btoa(`${clientId}:${clientSecret}`),
    "Content-type": "application/x-www-form-urlencoded; charset=utf-8",
});

const fetchRequest = ({
                          url,
                          method,
                          signal,
                          params,
                          isJson
                      }) => {

    if (method === requestTypes.GET || method === requestTypes.HEAD)
        throw new Error("Для GET и HEAD запроса требуется использовать fetchGetHeadRequest");

    const headers = {
        "Content-Type": isJson
            ? "application/json"
            : "application/x-www-form-urlencoded",
    };

    return doFetch(
        {
            url,
            method,
            signal,
            credentials: 'include',
            headers,
            body: isJson ? JSON.stringify(params) : encodeFormParams(params),

            responseHandler: response => {
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.includes("application/json")) {
                    return response.text().then(text => {
                        let result = replaceAll(text, "\"true\"", "true");
                        result = replaceAll(text, "\"false\"", "false");
                        return JSON.parse(result);
                    })
                        .then((response) => {
                            if (
                                !response.success &&
                                response.error &&
                                (
                                    response.error.code ===
                                    ErrorCode.AUTHENTICATION_STATUS_ERROR
                                )
                            ) {
                                if (dispatch !== null) {
                                    dispatch(signOut());
                                }
                                if (history !== null) {
                                    history.push(appUrls.landing.url);
                                }
                            }

                            return response;
                        });
                } else {
                    return {
                        success: false,

                        error: {
                            message: "При запросе произошла ошибка",
                        },
                    };
                }
            },
        }
    );
};

const fetchGetHeadRequest = (
    url,
    method,
    ignoreUnauthenticatedRedirect,
    isJson
) => {
    return doFetch(
        {
            url,
            method: method,
            credentials: 'include',
            responseHandler: response => {
                switch (method) {
                    case requestTypes.HEAD:
                        return null;
                    default:
                        const contentType = response.headers.get("content-type");
                        if (isJson && contentType && contentType.includes("application/json")) {
                            return response.text().then(text => {
                                let result = replaceAll(text, "\"true\"", "true");
                                result = replaceAll(result, "\"false\"", "false");
                                return JSON.parse(result);
                            })
                                .then((response) => {
                                    if (
                                        !ignoreUnauthenticatedRedirect &&
                                        !response.success &&
                                        response.error &&
                                        (
                                            response.error.code ===
                                            ErrorCode.AUTHENTICATION_STATUS_ERROR
                                        )
                                    ) {
                                        if (dispatch !== null) {
                                          dispatch(signOut());
                                        }
                                        if (history !== null) {
                                            history.push(appUrls.landing.url);
                                        }
                                    }

                                    return response;
                                });
                        } else if (!isJson) {
                            return response.text();
                        }

                        return {
                            success: false,

                            error: {
                                message: "При запросе произошла ошибка",
                            },
                        };
                }
            },
        }
    );
};

const doFetch = ({
                     url,
                     signal,
                     method,
                     credentials,
                     headers,
                     body,
                     responseHandler
                 }) => {
    let processedUrl = (IS_DEBUG ? (DEVELOPMENT_SERVER_URL + url) : url);

    return fetch(
        processedUrl,
        {
            method,
            signal,
            credentials,
            headers,
            body
        }
    )
        .then(response => {
            if (response.redirected) {
                // commonFetchErrorHandler(null, new AuthorizationError(method));

                return {
                    success: false,
                    message: "Ошибка авторизации. Повторите попытку ещё раз"
                }
            }

            if (response.status >= 500) {
                return {
                    success: false,
                    message: "При запросе произошла ошибка.",
                };
            }

            return responseHandler(response);
        })
        .catch(error => {
            return {
                success: false,
                message: "При запросе произошла ошибка. Проверьте ваше интернет соединение.",
            };
        });
};

/**
 * Make get request
 * @param url - url to request
 * @param isJson - Content-Type" is "application/json"
 * @returns {*} promise of fetch
 */
export const fetchGet = (
    url,
    ignoreUnauthenticatedRedirect = false
) => (
    fetchGetHeadRequest(
        url,
        requestTypes.GET,
        ignoreUnauthenticatedRedirect,
        true
    )
);

/**
 * Make post request
 * @param url - url to request
 * @param params - parameters of the request
 * @param signal - signal to break request execution
 * @param isJson - Content-Type" is "application/json"
 * @returns {*} promise of fetch
 */
export const fetchPost = (url, params = {}, signal, isJson = true) => (
    fetchRequest({
        url,
        signal,
        method: requestTypes.POST,
        params,
        isJson
    })
);
