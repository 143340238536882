import {ACTION_TYPE as COMMON_ACTION_TYPE} from "../actions/common";
import {ACTION_TYPE} from "../actions/cardEdit";
import {createPersonalData} from "../../factory/redux/personalData";
import {createDocumentData} from "../../factory/redux/documentData";

const initialState = {
  inProcess: false,
  cardId: null,
  dateOfBirth: null,
  dateOfBirthLocked: false,
  establishmentId: null,
  personalDataList: [],

  personalDataRemoveList: [],
  documentDataRemoveList: [],

  photos: [
    // {
    //   file: ...,
    //   name: "",
    //   data: ...,
    // }
  ],
  comments: [],

  commentAdd: {
    showModal: null,
  },

  commentDelete: {
    showModal: false,
    comment: null,
  },
  commentEdit: {
    showModal: false,
    comment: null,
  },

  photoDelete: {
    showModal: false,
    photo: null,
  },
  photoUpload: {
    showModal: false,
    photo: {
      name: "",
      data: "",
    }
  },

};

/**
 * Shortcut for setting personalData values
 * @param state - current state
 * @param fieldName - filed name
 * @param personalDataIndex - index of personal data
 * @param value - new value
 * @returns {{personalDataList: *[]}}
 */
function setPersonalDataFieldValue(
    state,
    fieldName,
    personalDataIndex,
    value
) {

  return {
    ...state,
    personalDataList: [
      ...state.personalDataList.map((personalData, index) => {
        if (index === personalDataIndex) {
          return {
            ...personalData,
            [fieldName]: {
              ...personalData[fieldName],
              value,
            },
          }
        } else {
          return personalData;
        }
      })
    ],
  };
}

/**
 * Shortcut for setting personalData valid values
 * @param state - current state
 * @param fieldName - filed name
 * @param personalDataIndex - index of personal data
 * @param isValid - new isValid
 * @returns {{personalDataList: *[]}}
 */
function setPersonalDataFieldValid(
    state,
    fieldName,
    personalDataIndex,
    isValid
) {

  return {
    ...state,
    personalDataList: [
      ...state.personalDataList.map((personalData, index) => {
        if (index === personalDataIndex) {
          return {
            ...personalData,
            [fieldName]: {
              ...personalData[fieldName],
              isValid,
            },
          }
        } else {
          return personalData;
        }
      })
    ],
  };
}

/**
 * Shortcut for setting documentData values
 * @param state - current state
 * @param fieldName - filed name
 * @param personalDataIndex - index of personal data
 * @param documentDataIndex - index of document data in array
 * @param value - new value
 * @returns {{personalDataList: *[]}}
 */
function setDocumentDataFieldValue(
    state,
    fieldName,
    personalDataIndex,
    documentDataIndex,
    value
) {

  return {
    ...state,
    personalDataList: [
      ...state.personalDataList.map((personalData, index) => {
        if (index === personalDataIndex) {
          return {
            ...personalData,
            documentDataList: [
              ...personalData.documentDataList.map((documentData, index) => {
                // dataIndex !== index
                if (index === documentDataIndex) {
                  return {
                    ...documentData,
                    [fieldName]: {
                      ...documentData[fieldName],
                      value,
                    },
                  };
                } else {
                  return documentData;
                }
              }),
            ],
          }
        } else {
          return personalData;
        }
      })
    ],
  };
}

/**
 * Shortcut for setting documentData values
 * @param state - current state
 * @param fieldName - filed name
 * @param personalDataIndex - index of personal data
 * @param documentDataIndex - index of document data in array
 * @param isValid - new isValid
 * @returns {{personalDataList: *[]}}
 */
function setDocumentDataFieldValid(
    state,
    fieldName,
    personalDataIndex,
    documentDataIndex,
    isValid
) {

  return {
    ...state,
    personalDataList: [
      ...state.personalDataList.map((personalData, index) => {
        if (index === personalDataIndex) {
          return {
            ...personalData,
            documentDataList: [
              ...personalData.documentDataList.map((documentData, index) => {
                // dataIndex !== index
                if (index === documentDataIndex) {
                  return {
                    ...documentData,
                    [fieldName]: {
                      ...documentData[fieldName],
                      isValid,
                    },
                  };
                } else {
                  return documentData;
                }
              }),
            ],
          }
        } else {
          return personalData;
        }
      })
    ],
  };
}


export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPE.DROP_CLIENT_EDIT_DATA:
    case COMMON_ACTION_TYPE.CLEAR_STORE:
      return {
        ...initialState,
      };
    case ACTION_TYPE.SET_CLIENT_EDIT_IN_PROCESS:
      return {
        ...state,
        inProcess: action.payload,
      };

    case ACTION_TYPE.SET_CARD_ID:
      return {
        ...state,
        cardId: action.payload,
      };
    case ACTION_TYPE.SET_CLIENT_EDIT_ESTABLISHMENT_ID:
      return {
        ...state,
        establishmentId: action.payload,
      };

      // common data
    case ACTION_TYPE.SET_CLIENT_EDIT_CLIENT_DATE_OF_BIRTH:
      return {
        ...state,
        dateOfBirth: action.payload.dateOfBirth,
        dateOfBirthLocked: action.payload.dateOfBirthLocked,
      };

    case ACTION_TYPE.SET_CLIENT_EDIT_CLIENT_LIST: {
      const personalDataList = (
          action.payload ||
          initialState.personalDataList
      );

      return {
        ...state,
        personalDataList: [
          ...personalDataList.map(personalData =>

              createPersonalData({
                id: personalData.id,
                name: personalData.name,
                familyName: personalData.familyName,
                patronymic: personalData.patronymic,
                dateOfBirth: personalData.dateOfBirth,
                kaloevId: personalData.kaloevId,
                establishmentId: personalData.establishmentId,
                receivedFromDevice: personalData.receivedFromDevice,

                documentDataList: personalData.documentDataList,
                // documentDataList: [
                //     ...(
                //         (personalData.documentDataList.length > 0)
                //         ? (
                //                 personalData.documentDataList
                //                     .map(documentData =>
                //                         createDocumentData({
                //                           id: documentData.id,
                //                           country: documentData.country,
                //                            type: documentData.type,
                //                           number: documentData.number,
                //                           issueDate: documentData.issueDate,
                //                           expireDate: documentData.expireDate,
                //                           personalId: documentData.personalId,
                //                           receivedFromDevice: documentData.receivedFromDevice,
                //                         })
                //                     )
                //             )
                //             : [createDocumentData({})]
                //     ),
                // ]
              })
          )
        ],
        photos: [], // clear photos
        comments: [], // clear comments
      };
    }

    case ACTION_TYPE.ADD_PERSONAL_DATA: {


      return {
        ...state,
        personalDataList: [
          ...state.personalDataList,
          {
            ...createPersonalData({
              dateOfBirth: state.dateOfBirth,
            }),
            documentDataList: [
              createDocumentData({})
            ],
          },
        ],
      }
    }

    case ACTION_TYPE.REMOVE_PERSONAL_DATA: {
      const {personalDataIndex, personalDataId} = action.payload;

      let newPersonalDataRemoveList = [...state.personalDataRemoveList];
      if (personalDataId != null) {
        newPersonalDataRemoveList.push(personalDataId)
      }

      return {
        ...state,
        personalDataList: [
          ...state.personalDataList
              .filter((personalData, index) => (personalDataIndex !== index))
        ],
        personalDataRemoveList: newPersonalDataRemoveList,
      };
    }

    case ACTION_TYPE.SET_CLIENT_EDIT_PERSONAL_FIELD: {
      const {fieldName, personalDataIndex, value} = action.payload;

      return setPersonalDataFieldValue(
          state,
          fieldName,
          personalDataIndex,
          value
      );
    }

    case ACTION_TYPE.SET_CLIENT_EDIT_PERSONAL_FIELD_VALID: {
      const {fieldName, personalDataIndex, isValid} = action.payload;

      return setPersonalDataFieldValid(
          state,
          fieldName,
          personalDataIndex,
          isValid
      );
    }

    case ACTION_TYPE.SET_CLIENT_EDIT_EXISTS:
      return {
        ...state,
        personalDataList: state.personalDataList
            .map((personalData, index) => {
              if (index === action.payload.index) {
                return {
                  ...personalData,
                  exists: {
                    ...personalData.exists,
                    value: action.payload.value,
                    cardId: action.payload.cardId,
                  },
                }
              } else {
                return personalData;
              }
            })
      };
    case ACTION_TYPE.SET_CLIENT_EDIT_SHOW_EXISTS_MODAL:
      return {
        ...state,
        personalDataList: state.personalDataList
            .map((personalData, index) => {
              if (index === action.payload.index) {
                return {
                  ...personalData,
                  exists: {
                    ...personalData.exists,
                    showModal: action.payload.value,
                  },
                }
              } else {
                return personalData;
              }
            })
        // personalData: {
        //   ...state.personalData,
        //   exists: {
        //     ...state.personalData.exists,
        //     showModal: action.payload,
        //   },
        // }
      };

      // document data

      // case ACTION_TYPE.ADD_DOCUMENT_DATA: {
      //   const {personalDataIndex} = action.payload;
      //
      //   return {
      //     ...state,
      //     personalDataList: [
      //       ...state.personalDataList.map((personalData, index) => {
      //         if (index === personalDataIndex) {
      //           return {
      //             ...personalData,
      //             documentDataList: [
      //               ...personalData.documentDataList,
      //               {
      //                 id: null,
      //                 country: {
      //                   value: "",
      //                   isValid: null,
      //                   errorMessage: null,
      //                 },
      //                 type:  {
      //                   value: "",
      //                   isValid: null,
      //                   errorMessage: null,
      //                 },
      //                 number:  {
      //                   value: "",
      //                   isValid: null,
      //                   errorMessage: null,
      //                 },
      //                 issueDate:  {
      //                   value: null,
      //                   isValid: null,
      //                   errorMessage: null,
      //                 },
      //                 expireDate:  {
      //                   value: null,
      //                   isValid: null,
      //                   errorMessage: null,
      //                 },
      //                 personalId:  {
      //                   value: "",
      //                   isValid: null,
      //                   errorMessage: null,
      //                 },
      //                 receivedFromDevice: false
      //               },
      //             ],
      //           }
      //         } else {
      //           return personalData;
      //         }
      //       })
      //     ],
      //   }
      // }
      //
      // case ACTION_TYPE.REMOVE_DOCUMENT_DATA: {
      //   const {
      //     personalDataIndex,
      //     documentDataIndex,
      //     documentDataId
      //   } = action.payload;
      //
      //   let newDocumentDataRemoveList = [...state.documentDataRemoveList];
      //   if(documentDataId != null) {
      //     newDocumentDataRemoveList.push(documentDataId)
      //   }
      //
      //   return {
      //     ...state,
      //     personalDataList: [
      //       ...state.personalDataList.map((personalData, index) => {
      //         if (index === personalDataIndex) {
      //           return {
      //             ...personalData,
      //             documentDataList: [
      //               ...personalData.documentDataList
      //                   .filter((documentData, index) => (
      //                       documentDataIndex !== index
      //                   )),
      //             ],
      //           }
      //         } else {
      //           return personalData;
      //         }
      //       })
      //     ],
      //
      //     documentDataRemoveList: newDocumentDataRemoveList,
      //   }
      // }

      // document data fields

    case ACTION_TYPE.SET_CLIENT_EDIT_DOCUMENT_FIELD: {
      const {
        fieldName,
        personalDataIndex,
        index: documentDataIndex,
        value
      } = action.payload;

      return setDocumentDataFieldValue(
          state,
          fieldName,
          personalDataIndex,
          documentDataIndex,
          value
      );
    }

    case ACTION_TYPE.SET_CLIENT_EDIT_DOCUMENT_FIELD_VALID: {
      const {
        fieldName,
        personalDataIndex,
        index: documentDataIndex,
        isValid
      } = action.payload;

      return setDocumentDataFieldValid(
          state,
          fieldName,
          personalDataIndex,
          documentDataIndex,
          isValid
      );
    }


      // set comments list
    case ACTION_TYPE.SET_CLIENT_EDIT_COMMENTS:
      return {
        ...state,
        comments: [
          ...(action.payload || initialState.comments),
        ],
      };


      // photos
      // case ACTION_TYPE.SET_CLIENT_EDIT_PHOTOS:
      //   return {
      //     ...state,
      //     photos: [
      //       ...(action.payload || initialState.photos),
      //     ],
      //   };
    case ACTION_TYPE.ADD_CLIENT_EDIT_PHOTO:
      return {
        ...state,
        photos: [
          ...state.photos,
          action.payload,
        ],
      };
    case ACTION_TYPE.CLEAR_CLIENT_EDIT_PHOTO:
      return {
        ...state,
        photos: [],
      };
    // case ACTION_TYPE.ADD_CLIENT_EDIT_PHOTO_TO_ARRAY:
    //   return {
    //     ...state,
    //     photos: [...state.photos.push(action.payload)],
    //   };
    case ACTION_TYPE.SET_CLIENT_EDIT_PHOTO_DELETE:
      return {
        ...state,
        photoDelete: {
          ...state.photoDelete,
          photo: action.payload,
        }
      };
    case ACTION_TYPE.SET_CLIENT_EDIT_PHOTO_DELETE_SHOW_MODAL:
      return {
        ...state,
        photoDelete: {
          ...state.photoDelete,
          showModal: action.payload,
        }
      };
    case ACTION_TYPE.SET_CLIENT_EDIT_ADD_PHOTO: {
      const photo = {
        // file: action.payload.file,
        name: action.payload.file.name,
        data: action.payload.data,
      };

      return {
        ...state,
        photoUpload: {
          ...state.photoUpload,
          photo,
        },
      };
    }
    case ACTION_TYPE.SET_CLIENT_EDIT_ADD_PHOTO_SHOW_MODAL:
      return {
        ...state,
        photoUpload: {
          ...state.photoUpload,
          showModal: action.payload,
        },
      };


    default:
      return state;
  }
};