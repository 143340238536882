import {ACTION_TYPE} from "../actions/localCommentsExistSelector";

const initialState = {
    localCommentsExist: false,
};

export default function reducer (state = initialState, action){
    switch (action.type){
        case ACTION_TYPE.SET_COMMENT_SELECTOR:
            return {
                ...state,
                localCommentsExist: action.payload
            };
        default:
            return state;
    }
}