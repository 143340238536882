import {roles} from "./roles";

export const
    DEVELOPMENT_SERVER_URL = "http://127.0.0.1:8080";


export const appUrls = Object.freeze({
    landing: {url: `${process.env.PUBLIC_URL}/`, roles: [roles.all],},

    home: {
        name: "Старт",
        url: `${process.env.PUBLIC_URL}`,
        roles: [roles.all],
    },
    checkGuest: {
        name: "Проверить гостя",
        url: `${process.env.PUBLIC_URL}/cabinet/check-guest`,
        roles: [roles.hostes,
            roles.admin,
            roles.cv,
            roles.moderator],
    },
    authentication: {
        name: "Авторизация",
        url: `${process.env.PUBLIC_URL}/authentication`,
        roles: [roles.all],
    },

    cabinet: {
        url: `${process.env.PUBLIC_URL}/cabinet/*`,
        roles: [roles.all],
    },

    cabinetLanding: {
        url: `${process.env.PUBLIC_URL}/cabinet/landing`,
        // surveyed by cabinet
        roles: [
            roles.admin,
            roles.cv,
            roles.moderator,
            roles.shelterPlus,
            roles.shelter,
            roles.hostes,
        ],
    },

    clientCreate: {
        name: "Добавить гостя вручную",
        url: `${process.env.PUBLIC_URL}/cabinet/client-add/`,
        roles: [
            roles.admin,
            roles.moderator,
            roles.cv
        ],
    },
    clientEdit: {
        name: "Редактировать клиента",
        url: `${process.env.PUBLIC_URL}/cabinet/client/:cardId/edit/`,
        roles: [
            roles.admin,
            roles.cv,
            roles.moderator,
        ],
    },
    client: {
        name: "Карточка клиента",
        url: `${process.env.PUBLIC_URL}/cabinet/client/:clientId/:establishmentId`,
        roles: [
            roles.admin,
            roles.cv,
            roles.moderator,
            roles.shelterPlus,
        ],
    },
    clients: {
        name: "BlackList + комментарии",
        url: `${process.env.PUBLIC_URL}/cabinet/clients`,
        roles: [
            roles.admin,
            // roles.cv,
            roles.moderator
        ],
    },
    newClients: {
        name: "Новый Блэклист",
        url: `${process.env.PUBLIC_URL}/cabinet/new-clients`,
        roles: [
            roles.admin,
            roles.moderator
        ],
    },
    visitor: {
        name: "Карточка гостя",
        url: `${process.env.PUBLIC_URL}/cabinet/visitor/:visitorId/:establishmentId`,
        roles: [
            roles.admin,
            roles.cv,
            roles.moderator,
            roles.shelterPlus,
            roles.shelter
        ],
    },
    visitors: {
        name: "Гости",
        url: `${process.env.PUBLIC_URL}/cabinet/visitors`,
        roles: [
            roles.admin,
            roles.cv,
            roles.moderator,
            roles.shelterPlus,
            roles.shelter,
        ],
    },
    visitorsArchive: {
        name: "Архив",
        url: `${process.env.PUBLIC_URL}/cabinet/visitors-archive`,
        roles: [
            roles.admin,
        ],
    },

    admin: {
        url: `${process.env.PUBLIC_URL}/admin/*`,
        roles: [
            roles.admin
        ],
    },

    users: {
        name: "Пользователи",
        url: `${process.env.PUBLIC_URL}/admin/users`,
        roles: [
            roles.admin
        ],
    },

    userUpdate: {
        name: "Пользователь",
        url: `${process.env.PUBLIC_URL}/admin/user/:userId/`,
        roles: [
            roles.admin
        ],
    },

    userCreate: {
        name: "Новый пользователь",
        url: `${process.env.PUBLIC_URL}/admin/new-user`,
        roles: [
            roles.admin
        ],
    },

    userDelete: {
        url: `${process.env.PUBLIC_URL}/admin/delete-user/:userId/`,
        roles: [
            roles.admin
        ],
    },

    establishments: {
        name: "Отделения",
        url: `${process.env.PUBLIC_URL}/admin/establishments`,
        roles: [
            roles.admin
        ],
    },

    establishmentCreate: {
        url: `${process.env.PUBLIC_URL}/admin/establishments/create`,
        roles: [
            roles.admin
        ],
    },

    establishmentUpdate: {
        url: `${process.env.PUBLIC_URL}/admin/establishments/update/:establishmentId/`,
        roles: [
            roles.admin
        ],
    },

    establishmentDelete: {
        url: `${process.env.PUBLIC_URL}/admin/establishments/delete/:establishmentId/`,
        roles: [
            roles.admin
        ],
    },

    serverInfo: {
        name: "Параметры",
        url: `${process.env.PUBLIC_URL}/admin/server-info/`,
        roles: [
            roles.admin
        ],
    },

    // error pages
    notFound: {
        url: `${process.env.PUBLIC_URL}/notFound`,
        roles: [roles.all],
    },
    unauthorized: {
        url: `${process.env.PUBLIC_URL}/unauthorized`,
        roles: [roles.all],
    },

});

export const appExternalUrls = Object.freeze({
    updater: {
        url: `/sova-updater`,
    },
});


export const apiUrls = Object.freeze({
    authentication: {
        authenticate: {url: `${process.env.PUBLIC_URL}/api/authentication/authenticate`},
        isAuthenticated: {url: `${process.env.PUBLIC_URL}/api/authentication/is-authenticated`},
        signOut: {url: `${process.env.PUBLIC_URL}/api/authentication/sign-out`},
        status: {url: `${process.env.PUBLIC_URL}/api/authentication/status`},
    },
    client: {
        checkExistence: {url: `${process.env.PUBLIC_URL}/api/client/check-existence`},
        checkExistenceOnCheckPage: {url: `${process.env.PUBLIC_URL}/api/client/check-existence-check-page`},
        create: {url: `${process.env.PUBLIC_URL}/api/client/create`},
        // get all client data by id
        delete: {url: `${process.env.PUBLIC_URL}/api/client/delete`},
        get: {url: `${process.env.PUBLIC_URL}/api/client/`},
        getAll: {url: `${process.env.PUBLIC_URL}/api/client/get-all`},
        getNew: {url: `${process.env.PUBLIC_URL}/api/client/get-new`},
        merge: {url: `${process.env.PUBLIC_URL}/api/client/merge`},
        update: {url: `${process.env.PUBLIC_URL}/api/client/update`},
        getLocalComments: {url: `${process.env.PUBLIC_URL}/api/client/get-local-comments`},
        updateReadState: {url: `${process.env.PUBLIC_URL}/api/client/update-read-state`},
    },
    comment: {
        add: {url: `${process.env.PUBLIC_URL}/api/comment/add`},
        delete: {url: `${process.env.PUBLIC_URL}/api/comment/delete`},
        update: {url: `${process.env.PUBLIC_URL}/api/comment/update`},
        check: {url: `${process.env.PUBLIC_URL}/api/comment/check-comment`},
    },
    establishment: {
        getAll: {url: `${process.env.PUBLIC_URL}/api/establishment/get-all`},
        get: {url: `${process.env.PUBLIC_URL}/api/establishment/get`},
        add: {url: `${process.env.PUBLIC_URL}/api/establishment/add`},
        update: {url: `${process.env.PUBLIC_URL}/api/establishment/update`},
        delete: {url: `${process.env.PUBLIC_URL}/api/establishment/delete`},

    },
    photo: {
        delete: {url: `${process.env.PUBLIC_URL}/api/photo/delete`},
        getPhoto: {url: `${process.env.PUBLIC_URL}/api/photo/get`},
        pin: {url: `${process.env.PUBLIC_URL}/api/photo/pin`},
        upload: {url: `${process.env.PUBLIC_URL}/api/photo/upload`},
    },
    options: {
        getAll: {url: `${process.env.PUBLIC_URL}/api/options/get-all`},
        getServerInfo: {url: `${process.env.PUBLIC_URL}/api/options/get-server-info`},
        updateServerInfo: {url: `${process.env.PUBLIC_URL}/api/options/update-server-info`},
    },
    visitor: {
        addComment: {url: `${process.env.PUBLIC_URL}/api/visitor/add-comment`},
        addVisitor: {url: `${process.env.PUBLIC_URL}/api/visitor/add-visitor`},
        getAll: {url: `${process.env.PUBLIC_URL}/api/visitor/get-all`},
        getArchive: {url: `${process.env.PUBLIC_URL}/api/visitor/get-archive`},
        addHostesVisitor: {url: `${process.env.PUBLIC_URL}/api/visitor/add-hostes-visitor`},
        delete: {url: `${process.env.PUBLIC_URL}/api/visitor/delete`},
        // get all visitor data by id
        getVisitorFullData: {url: `${process.env.PUBLIC_URL}/api/visitor/full-data`},
    },
    users: {
        getAll: {url: `${process.env.PUBLIC_URL}/api/account/get-all`},
        get: {url: `${process.env.PUBLIC_URL}/api/account/get`},
        add: {url: `${process.env.PUBLIC_URL}/api/account/add`},
        update: {url: `${process.env.PUBLIC_URL}/api/account/update`},
        reset: {url: `${process.env.PUBLIC_URL}/api/account/reset-pass`},
        remove: {url: `${process.env.PUBLIC_URL}/api/account/remove`},
    },
});

/**
 * Urls of websocket service
 * @type {Readonly<{}>}
 */
export const websocketUrls = Object.freeze({
    location: {url: `${process.env.PUBLIC_URL}/owl-websocket`},

    // client to server
    cts: {url: "/ws/to-server"},
    notifications: {
        card: {
            url: `/topic/notification`
        },
        visitor: {
            url: `/topic/visitor/`
        }
    },
    channel: {
        card: {
            created: {
                url: `/from-server/card-created`,
            },
            deleted: {
                url: `/from-server/card-deleted`,
            },
            updated: {
                url: `/from-server/card-updated`,
            },
        },
        license: {
            warning: {
                url: `/from-server/licence`,
            },
        },
        options: {
            updated: {
                url: `/from-server/options-updated`,
            },
        },
        visitor: {
            created: {
                url: `/from-server/visitor-created`,
            },
            updated: {
                url: `/from-server/visitor-updated`,
            },
            deleted: {
                url: `/from-server/visitor-deleted`,
            },
        }
    },
});
