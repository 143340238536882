import {combineReducers} from "redux";
import authentication from "./authentication";
import cardCreate from "./cardCreate";
import cardData from "./cardData";
import cardEdit from "./cardEdit";
import cardMerge from "./cardMerge";
import cards from "./cards";
import comment from "./comment";
import establishment from "./establishment";
import licence from "./licence";
import options from "./options";
import serverInfo from "./serverInfo";
import theme from "./theme";
import visitorData from "./visitorData";
import visitors from "./visitors";
import visitorsArchive from "./visitorsArchive";
import users from "./users";
import user from "./user";
import selectLang from "./selectLang";
import localCommentsExistsSelector from  "./localCommentsExistSelector"

const reducer = combineReducers({
  authentication,
  cardCreate,
  cardData,
  cardEdit,
  cardMerge,
  cards,
  comment,
  establishment,
  licence,
  options,
  serverInfo,
  theme,
  visitorData,
  visitors,
  visitorsArchive,
  users,
  user,
  selectLang,
  localCommentsExistsSelector
});

export default reducer;