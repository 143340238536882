import {formatInt} from "./number";
import {isStr} from "./string";

export const DATE_TEMPLATE = "dd/MM/yyyy";
const delimiter = "/";

export function fromTimestamp(timestamp = 0) {
  return new Date(timestamp);
}

export function toTimestamp(date) {
  if (!date) date = new Date();

  return date.getTime();
}

export function formatTimestamp(timestamp, showTime = true) {
  const date = fromTimestamp(timestamp);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const min = date.getMinutes();
  const sec = date.getSeconds();

  const
      formatted =
          formatInt(day, 2) +
          delimiter +
          formatInt(month, 2) +
          delimiter +
          year.toString().substr(2,2) +
          (
              showTime
                  ? (
                      " " +
                      formatInt(hour, 2) +
                      ":" +
                      formatInt(min, 2)
                      // ":" +
                      // formatInt(sec, 2)
                  )
                  : ""
          );
  return formatted;
}

export function formatDate(dateStr, defaultValue = "") {

  let date = parseDate(dateStr);
  if (date) {
    const
        year = date.getFullYear(),
        month = date.getMonth() + 1,
        day = date.getDate(),
        hour = date.getHours(),
        min = date.getMinutes(),
        sec = date.getSeconds();

    let formatted =
        "" +
        formatInt(day, 2) +
        delimiter +
        formatInt(month, 2) +
        delimiter +
        formatInt(year, 4);

    return formatted;
  } else {
    return defaultValue;
  }
}

export function parseDate(
    dateStr,
    format = DATE_TEMPLATE
) {
  let formattedDate;

  if (!isStr(dateStr)) {
    return null;
  }

  try {
    let formatLowerCase = format.toLowerCase();
    let formatItems = formatLowerCase.split(delimiter);
    let dateItems = dateStr.split(delimiter);
    let monthIndex = formatItems.indexOf("mm");
    let dayIndex = formatItems.indexOf("dd");
    let yearIndex = formatItems.indexOf("yyyy");
    let year = parseInt(dateItems[yearIndex]);
    // adjust for 2 digit year
    if (year < 100) {
      year += 2000;
    }
    let month = parseInt(dateItems[monthIndex]);
    month -= 1;

    formattedDate = new Date(year, month, dateItems[dayIndex]);
  } catch (e) {
    formattedDate = null;
  }

  return formattedDate;
}

export function dateToString(date, defaultValue = "", showTime=false) {
  if (isValidDate(date)) {
    const
        year = date.getFullYear(),
        month = date.getMonth() + 1,
        day = date.getDate(),
        hour = date.getHours(),
        min = date.getMinutes(),
        sec = date.getSeconds();

    let formatted =
        "" +
        formatInt(day, 2) +
        delimiter +
        formatInt(month, 2) +
        delimiter +
        formatInt(year, 2);

    if(showTime) {
      formatted +=
          " " + formatInt(hour, 2) +
          ":" + formatInt(min, 2) +
          ":" + formatInt(sec, 2);
    }

    return formatted;
  } else {
    return defaultValue;
  }
}

export function isValidDate(dateObj) {
  return (dateObj instanceof Date) && !isNaN(dateObj.getTime());
}