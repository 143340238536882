import {ACTION_TYPE as COMMON_ACTION_TYPE} from "../actions/common";
import {ACTION_TYPE} from "../actions/cardData";


const initialState = {
  inProcess: false,
  data: null,
  success: null,
  // data: {
  //   personalDataList: [],
  //   localCommentList: [],
  //   globalCommentList: [],
  // },
  photos: [],

  delete: {
    cardId: null,
    showModal: false,
  },
  showDeleteCard: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case COMMON_ACTION_TYPE.CLEAR_STORE:
    case ACTION_TYPE.DROP_CARD_DATA:
      return {
        ...initialState,
      };
    case ACTION_TYPE.SET_CARD_DATA_IN_PROCESS:
      return {
        ...state,
        inProcess: action.payload,
      };

    case ACTION_TYPE.SET_CARD_DATA:
      return {
        ...state,
        data: {
          ...action.payload,
        },
        photos: [], // clear photos
      };

    case ACTION_TYPE.SET_CARD_DATA_SUCCESS:
      return {
        ...state,
        success: action.payload,
      };

    case ACTION_TYPE.SET_CARD_DATA_PHOTO:
      return {
        ...state,
        photos: [
          ...state.photos,
          action.payload,
        ],
      };

    case ACTION_TYPE.SET_CARD_DATA_SHOW_DELETE_CARD:
      return {
        ...state,
        delete: {
          ...state.delete,
          showModal: action.payload,
        },
      };

    case ACTION_TYPE.SET_CARD_DATA_DELETE_CARD_ID:
      return {
        ...state,
        delete: {
          ...state.delete,
          cardId: action.payload,
        },
      };

    default:
      return state;
  }
}