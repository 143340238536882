import {loadVisitors, SET_VISITORS} from "../actions/visitors";
import {ACTION_TYPE as AUTHENTICATION_ACTION_TYPE} from "../actions/authentication";

function visitorsMiddleware() {

  return (store) => (next) => (action) => {
    switch (action.type) {

      case AUTHENTICATION_ACTION_TYPE.SET_IS_AUTHENTICATED:
        if (action.payload) {
          store.dispatch(loadVisitors());
        } else {
          store.dispatch(SET_VISITORS());
        }
        return next(action);
      default:
        return next(action);
    }
  }
}

export default visitorsMiddleware();