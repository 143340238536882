import thunk from "redux-thunk";
import visitorsMiddleware from "./visitorsMiddleware";
import webSocketMiddleware from "./webSocketMiddleware";
import authenticationMiddleware from "./authenticationMiddleware";
import notificationMiddleware from "./notificationMiddleware";
import usersMidleware from "./usersMidleware";

const middleware = [
  thunk,
  authenticationMiddleware,
  notificationMiddleware,
  visitorsMiddleware,
  usersMidleware,
  webSocketMiddleware,
];

export default middleware;
