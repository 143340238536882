import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './containers/app/App';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import {BrowserRouter} from "react-router-dom";
import store from "./redux/store";
import DateFnsUtils from "@date-io/date-fns/build/index";
import 'date-fns';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import './i18n.js';



ReactDOM.render(
    <Suspense fallback={""}>
    <Provider store={store}>
      <BrowserRouter>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <App/>
        </MuiPickersUtilsProvider>
      </BrowserRouter>
    </Provider>
    </Suspense>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.unregister();
// serviceWorker.register();