import {createAction} from "../../factory/redux/action";
import {theme} from "../../constants/theme";

export const ACTION_TYPE = Object.freeze({
  SET_THEME: "SET_THEME",
});

export const SET_THEME = (payload) => createAction(
    ACTION_TYPE.SET_THEME,
    payload,
);

export function toggleTheme() {
  return (dispatch, getState) => {

    const
        themeState = getState().theme;

    const
        selectedTheme = themeState.theme,
        themeDefault = theme.DEFAULT;

    if(themeState.theme == theme.DEFAULT) {
      dispatch(SET_THEME(theme.DARK));
    } else {
      dispatch(SET_THEME(theme.DEFAULT));
    }
  }
}