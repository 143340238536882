import {fetchPost} from "../../utils/request";
import {apiUrls} from "../../constants/urls";
import {createAction} from "../../factory/redux/action";
import {logger} from "../../utils/logger";

export const ACTION_TYPE = Object.freeze({
  SET_LOAD_CLIENTS_IN_PROCESS: "SET_LOAD_CLIENTS_IN_PROCESS",
  SET_CLIENTS: "SET_CLIENTS",
  SET_NEW_CLIENTS: "SET_NEW_CLIENTS",
  SET_NEW_UNREAD_CLIENTS_COUNTER: "SET_NEW_UNREAD_CLIENTS_COUNTER",
  ADD_CLIENT: "ADD_CLIENT",
  DELETE_CLIENT: "DELETE_CLIENT",
  UPDATE_CLIENT: "UPDATE_CLIENT",

  TOGGLE_CARDS_FILTER: "TOGGLE_CARDS_FILTER",
  CLEAR_CARDS_FILTER: "CLEAR_CARDS_FILTER",
  SET_FILTER_SHOW_ONLY_TYPE: "SET_CARDS_FILTER_SHOW_ONLY_TYPE",
  SET_FILTER_FAMILY_NAME_OR_PASSPORT: "SET_CARDS_FILTER_FAMILY_NAME_OR_PASSPORT",
  SET_CLIENTS_FILTER_LOGIN: "SET_CLIENTS_FILTER_LOGIN",

  SET_TOTAL_AMOUNT: "SET_CARDS_TOTAL_AMOUNT",
  SET_NEW_TOTAL_AMOUNT: "SET_NEW_TOTAL_AMOUNT",
  SET_PAGINATION_PAGE: "SET_CARDS_PAGINATION_PAGE",
  SET_PAGINATION_AMOUNT_ON_PAGE: "SET_CARDS_PAGINATION_AMOUNT_ON_PAGE",
});

const SET_LOAD_CLIENTS_IN_PROCESS = (payload) => ({
  type: ACTION_TYPE.SET_LOAD_CLIENTS_IN_PROCESS,
  payload,
});

/**
 * Set initial clients list
 * @param payload
 * @returns {{type: string, payload: *}}
 * @constructor
 */
const SET_CLIENTS = (payload) => ({
  type: ACTION_TYPE.SET_CLIENTS,
  payload,
});

/**
 * Set initial new clients list
 * @param payload
 * @returns {{type: string, payload: *}}
 * @constructor
 */
const SET_NEW_CLIENTS = (payload) => ({
  type: ACTION_TYPE.SET_NEW_CLIENTS,
  payload,
});

export const SET_NEW_UNREAD_CLIENTS_COUNTER = (payload) => ({
  type: ACTION_TYPE.SET_NEW_UNREAD_CLIENTS_COUNTER,
  payload,
});

/**
 * Add a single client to the list from websocket
 * @param payload
 * @returns {{type: string, payload: *}}
 * @constructor
 */
export const ADD_CLIENT_BY_WEBSOCKET = (payload) => ({
  type: ACTION_TYPE.ADD_CLIENT,
  payload,
});

export const DELETE_CLIENT_BY_WEBSOCKET = (payload) => createAction(
    ACTION_TYPE.DELETE_CLIENT,
    payload
);

/**
 * Update existing client in the list from websocket
 * @param payload
 * @returns {{type: string, payload: *}}
 * @constructor
 */
export const UPDATE_CLIENT_BY_WEBSOCKET = (payload) => ({
  type: ACTION_TYPE.UPDATE_CLIENT,
  payload,
});

// filter

export const TOGGLE_CARDS_FILTER = (payload) => createAction(
    ACTION_TYPE.TOGGLE_CARDS_FILTER,
    payload
);

export const CLEAR_CARDS_FILTER = () => createAction(
    ACTION_TYPE.CLEAR_CARDS_FILTER,
    null
);

export const SET_CLIENTS_FILTER_SHOW_ONLY_TYPE = (payload) => ({
  type: ACTION_TYPE.SET_FILTER_SHOW_ONLY_TYPE,
  payload,
});

export const SET_CLIENTS_FILTER_FAMILY_NAME_OR_PASSPORT = (payload) => ({
  type: ACTION_TYPE.SET_FILTER_FAMILY_NAME_OR_PASSPORT,
  payload,
});

export const SET_CLIENTS_FILTER_LOGIN = (payload) => ({
  type: ACTION_TYPE.SET_CLIENTS_FILTER_LOGIN,
  payload,
});


// Pagination
export const SET_TOTAL_AMOUNT = (payload) => createAction(
    ACTION_TYPE.SET_TOTAL_AMOUNT,
    payload
);
export const SET_NEW_TOTAL_AMOUNT = (payload) => createAction(
  ACTION_TYPE.SET_NEW_TOTAL_AMOUNT,
  payload
);
export const SET_PAGINATION_PAGE = (payload) => createAction(
    ACTION_TYPE.SET_PAGINATION_PAGE,
    payload
);
export const SET_PAGINATION_AMOUNT_ON_PAGE = (payload) => createAction(
    ACTION_TYPE.SET_PAGINATION_AMOUNT_ON_PAGE,
    payload
);


// setup AbortController
let controller = new AbortController();

export const loadClients = (isNew) => {

  return (dispatch, getState) => {


    const inProcess = getState().cards.inProcess;
    // if still in process - abort previous request
    if (inProcess) {
      controller.abort();
      // initialize new abort controller,
      // because it cannot be reset to normal state
      controller = new AbortController();
    }

    // closure to remember actual controller state
    const _controller = controller;

    dispatch(SET_LOAD_CLIENTS_IN_PROCESS(true));

    const
        {
          filter,
          pagination
        } = getState().cards;

    fetchPost(
        isNew ? apiUrls.client.getNew.url : apiUrls.client.getAll.url,
        {
          ...filter,
          ...pagination,
        },
        controller.signal
    )
        .then((response) => {
          if (response.success) {
            isNew ? dispatch(SET_NEW_CLIENTS(response.list)) : dispatch(SET_CLIENTS(response.list));
            dispatch(SET_TOTAL_AMOUNT(response.total));
          }
        })
        .finally(() => {
          // if request was aborted -> new request is in process ->
          // no need to change inProcess
          if (_controller.signal.aborted) {
            logger("Aborted");
          } else {
            dispatch(SET_LOAD_CLIENTS_IN_PROCESS(false));
          }
        });
  };
};

export const loadNewClients = () => {

  return (dispatch, getState) => {
    // const inProcess = getState().cards.inProcess;
    // // if still in process - abort previous request
    // if (inProcess) {
    //   controller.abort();
    //   // initialize new abort controller,
    //   // because it cannot be reset to normal state
    //   controller = new AbortController();
    // }

    // closure to remember actual controller state
    const _controller = controller;

    dispatch(SET_LOAD_CLIENTS_IN_PROCESS(true));

    const
      {
        filter,
        // pagination
      } = getState().cards;

    fetchPost(
      apiUrls.client.getNew.url,
      {
        ...filter,
        // ...pagination,
      },
      controller.signal
    )
      .then((response) => {
        if (response.success) {
          dispatch(SET_NEW_CLIENTS(response.list));
          dispatch(SET_NEW_TOTAL_AMOUNT(response.total));
          dispatch(SET_NEW_UNREAD_CLIENTS_COUNTER(countUnRead(response.list, getState().authentication)));
          dispatch(SET_LOAD_CLIENTS_IN_PROCESS(false));
        }
      })
  };
};
function countUnRead(newClients, authentication) {
  let counter = 0;
  if (!newClients)
    return counter;
  newClients.forEach(client => {
    if (client.establishmentID !== authentication.accountData.establishment  && client.new && !client.read)
      counter++;
  })
  return counter;
}

export const updateCardReadState = (cardId, accountId) => {
  return (dispatch, getState) => {
    fetchPost(
      apiUrls.client.updateReadState.url,
      {
        cardId,
        accountId
      }
    )
  }
}