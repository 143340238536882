import React from "react";
import {ACTION_TYPE as COMMON_ACTION_TYPE} from "../actions/common";
import {ACTION_TYPE, CLEAR_VISITORS_FILTER, SET_VISITORS_FILTER_LOGIN} from "../actions/visitors";
import {createVisitor} from "../../factory/redux/visitor";


const initialState = {
  inProcess: false,
  showFilter: false,
  filter: {
    hideClean: false,
    hideOuter: false,
    hideLocal: false,
    hideInfo: false,

    // comment types
    showOnlyType: [],

    // period for CCTV and Shelter
    period: null,
    // range for Moderator and Admin
    range: {
      from: null,
      to: null,
    },
    establishment: 0,

    familyNameOrPassport: "",
    login: "",
  },

  list: [
    // {
    //   name: "",
    //   familyName: "",
    //   // ...
    // }
  ],
  pinned: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case COMMON_ACTION_TYPE.CLEAR_STORE:
      return {
        ...initialState,
      };
    case ACTION_TYPE.ADD_VISITOR: {
      const visitorData = action.payload;

      return {
        ...state,
        list: [
          createVisitor(visitorData),
          ...state.list,
        ],
      };
    }
    case ACTION_TYPE.DELETE_VISITOR: {
      const
          currentList = state.list,
          deletedVisitorData = createVisitor(action.payload);

      return {
        ...state,
        list: currentList.filter(
            (existingVisitorData) =>
                (existingVisitorData.id !== deletedVisitorData.id)),
      };
    }
    case ACTION_TYPE.UPDATE_VISITOR: {

      const
          currentList = state.list,
          newVisitorData = createVisitor(action.payload);

      return {
        ...state,
        list: currentList.map((existingVisitorData) => {
          if(existingVisitorData.id === newVisitorData.id){
            return newVisitorData;
          } else {
            return existingVisitorData;
          }
        }),
      };
    }
    case ACTION_TYPE.SET_LOAD_VISITORS_IN_PROCESS:
      return {
        ...state,
        inProcess: action.payload,
      };
    case ACTION_TYPE.SET_VISITORS:
      return {
        ...state,
        list: action.payload.map((visitorData) => createVisitor(visitorData)),
      };
    case ACTION_TYPE.TOGGLE_VISITORS_FILTER:
      return {
        ...state,
        showFilter: !state.showFilter,
      };
    case ACTION_TYPE.CLEAR_FILTER:
      return {
        ...state,
        filter: {
          ...initialState.filter,
        },
      };
    case ACTION_TYPE.SET_FILTER_HIDE_CLEAN:
      return {
        ...state,
        filter: {
          ...state.filter,
          hideClean: action.payload,
        },
      };
    case ACTION_TYPE.SET_FILTER_HIDE_OUTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          hideOuter: action.payload,
        },
      };
    case ACTION_TYPE.SET_FILTER_HIDE_LOCAL:
      return {
        ...state,
        filter: {
          ...state.filter,
          hideLocal: action.payload,
        },
      };
    case ACTION_TYPE.SET_VISITORS_FILTER_HIDE_UPDATED:
      return {
          ...state,
        filter: {
            ...state.filter,
          hideInfo: action.payload,
        },
      };
    case ACTION_TYPE.SET_FILTER_SHOW_ONLY_TYPE:
      let inArray = state.filter.showOnlyType.indexOf(action.payload) > -1;

      let newShowOnlyType;
      if (inArray) {
        newShowOnlyType = state.filter.showOnlyType
            .filter(value => value !== action.payload)
      } else {
        newShowOnlyType = [...state.filter.showOnlyType, action.payload];
      }

      return {
        ...state,
        filter: {
          ...state.filter,
          showOnlyType: newShowOnlyType,
        },
      };
    case ACTION_TYPE.SET_FILTER_FAMILY_NAME_OR_PASSPORT:
      return {
        ...state,
        filter: {
          ...state.filter,
          familyNameOrPassport: action.payload || "",
        },
      };
    case ACTION_TYPE.SET_VISITORS_FILTER_LOGIN:
      return {
        ...state,
        filter: {
          ...state.filter,
          login: action.payload || "",
        },
      };
    case ACTION_TYPE.SET_FILTER_PERIOD:
      return {
        ...state,
        filter: {
          ...state.filter,
          period: action.payload,
        },
      };
    case ACTION_TYPE.SET_FILTER_RANGE_FROM:
      return {
        ...state,
        filter: {
          ...state.filter,
          range: {
            ...state.filter.range,
            from: action.payload,
          },
        },
      };
    case ACTION_TYPE.SET_FILTER_RANGE_TO:
      return {
        ...state,
        filter: {
          ...state.filter,
          range: {
            ...state.filter.range,
            to: action.payload,
          },
        },
      };
    case ACTION_TYPE.SET_FILTER_ESTABLISHMENT:
      return {
        ...state,
        filter: {
          ...state.filter,
          establishment: action.payload,
        },
      };
    case ACTION_TYPE.PIN_VISITOR:
      let newPinned;
      if (state.pinned.indexOf(action.payload) > -1) {
        newPinned = state.pinned.filter(value => value !== action.payload);
      } else {
        newPinned = [action.payload, ...state.pinned];
      }
      return {
        ...state,
        pinned: newPinned,
      };
    default:
      return state;
  }
}
