import {ACTION_TYPE as COMMON_ACTION_TYPE} from "../actions/common";
import {ACTION_TYPE} from "../actions/options";


const initialState = {
  inProcess: false,
  commentTypeList: [],
  commentDefaultTextList: [],
  countryList: [],
  // establishmentsLimit: 0,
  organizationName: null,
  rolesList: [],
  globalDataProcessError: false,
  appUpdateReady: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case COMMON_ACTION_TYPE.CLEAR_STORE:
      return {
        ...initialState,
      };
    case ACTION_TYPE.SET_OPTIONS:
      return {
        ...state,
        commentTypeList: action.payload.commentTypeList,
        commentDefaultTextList: action.payload.commentDefaultTextList,
        countryList: action.payload.countryDataList,
        // establishmentsLimit: action.payload.establishmentsLimit,
        organizationName: action.payload.organizationName,
        roles: action.payload.rolesList,
        globalDataProcessError: action.payload.globalDataProcessError,
        appUpdateReady: action.payload.appUpdateReady,
      };
    case ACTION_TYPE.SET_OPTIONS_IN_PROCESS:
      return {
        ...state,
        inProcess: action.payload,
      };
    default:
      return state;
  }
}