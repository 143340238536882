import {ACTION_TYPE as COMMON_ACTION_TYPE} from "../actions/common";
import {ACTION_TYPE} from "../actions/user";
import {createUserRedux} from "../../factory/redux/user";


const initialState = {
  inProcess: false,
  user: createUserRedux({}),
  resetpass: false,
  error: '',
  delete_modal: "",
  notify: false,
  message: "",
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPE.DROP_USER_DATA:
    case COMMON_ACTION_TYPE.CLEAR_STORE:
      return {
        ...initialState,
      };
    case ACTION_TYPE.SET_USER_DATA_IN_PROCESS:
      return {
        ...state,
        inProcess: action.payload,
      };
    case ACTION_TYPE.SET_USER_DATA:
      return {
        ...state,
        user: action.payload.user,
      };
    case ACTION_TYPE.SET_USER_ID:
      return {
        ...state,
        user: {
          ...state.user,
          id: action.payload
        },
      };
    case ACTION_TYPE.SET_USER_FAMILY:
      return {
        ...state,
        user: {
          ...state.user,
          family: {
            ...state.user.family,
            value: action.payload,
          },
        },
      };
    case ACTION_TYPE.SET_USER_NAME:
      return {
        ...state,
        user: {
          ...state.user,
          name: {
            ...state.user.name,
            value: action.payload,
          },
        },
      };
    case ACTION_TYPE.SET_USER_PHONE:
      return {
        ...state,
        user: {
          ...state.user,
          phone: {
            ...state.user.phone,
            value: action.payload,
          },
        },
      };
    case ACTION_TYPE.SET_USER_LOGIN:
      return {
        ...state,
        user: {
          ...state.user,
          login: {
            ...state.user.login,
            value: action.payload,
            isValid: null,
          },
        },
      };
    case ACTION_TYPE.SET_USER_LOGIN_IS_VALID:
      return {
        ...state,
        user: {
          ...state.user,
          login: {
            ...state.user.login,
            isValid: action.payload,
          },
        },
      };
    case ACTION_TYPE.SET_USER_PASS:
      return {
        ...state,
        user: {
          ...state.user,
          password: {
            ...state.user.password,
            value: action.payload,
            isValid: null,
          },
        },
      };
    case ACTION_TYPE.SET_USER_PASS_IS_VALID:
      return {
        ...state,
        user: {
          ...state.user,
          password: {
            ...state.user.password,
            isValid: action.payload,
          },
        },
      };
    case ACTION_TYPE.SET_USER_PASS_CONFIRM:
      return {
        ...state,
        user: {
          ...state.user,
          passconfirm: {
            ...state.user.passconfirm,
            value: action.payload,
            isValid: null,
          },
        },
      };
    case ACTION_TYPE.SET_USER_PASS_CONFIRM_IS_VALID:
      return {
        ...state,
        user: {
          ...state.user,
          passconfirm: {
            ...state.user.passconfirm,
            isValid: action.payload,
          },
        },
      };
    case ACTION_TYPE.SET_USER_ROLES:
      return {
        ...state,
        user: {
          ...state.user,
          roles: {
            ...state.user.roles,
            value: action.payload,
          },
        },
      };
    case ACTION_TYPE.SET_USER_ESTABLISHMENT:
      return {
        ...state,
        user: {
          ...state.user,
          establishment: {
            ...state.user.establishment,
            value: state.user.establishment.value ? state.user.establishment.value + ', ' + action.payload : action.payload,
            isValid: null,
          },
        },
      };
    case ACTION_TYPE.SET_USER_ESTABLISHMENT_IS_VALID:
      return {
        ...state,
        user: {
          ...state.user,
          establishment: {
            ...state.user.establishment,
            isValid: action.payload,
          },
        },
      };
    case ACTION_TYPE.UNSET_USER_ESTABLISHMENT:
      return {
        ...state,
        user: {
          ...state.user,
          establishment: {
            ...state.user.establishment,
            value: action.payload,
            isValid: null,
          },
        },
      };
    case ACTION_TYPE.SET_USER_STATE:
      return {
        ...state,
        user: {
          ...state.user,
          state: action.payload
        },
      };
    case ACTION_TYPE.SET_USER_ERROR:
      return {
        ...state,
        error: action.payload

      };
    case ACTION_TYPE.OPEN_RESET_MODAL:
      return {
        ...state,
        resetpass: action.payload

      };

    case ACTION_TYPE.DELETE_USER:
      return {
        ...state,
        delete_modal: action.payload

      };
    case ACTION_TYPE.SET_USER_SAVED:
      return {
        ...state,
        notify: action.payload
      };
    case ACTION_TYPE.SET_USER_NOTIFY:
      return {
        ...state,
        message: action.payload
      };
    case ACTION_TYPE.SET_USER_DELETED:
      return {
        ...state,
        user: {
          ...state.user,
          deleted: action.payload
        },
      };
    default:
      return state;
  }
}
