import {loadUsers, SET_USERS} from "../actions/users";
import {ACTION_TYPE as AUTHENTICATION_ACTION_TYPE} from "../actions/authentication";
import {roles} from "../../constants/roles";
import {checkAccessRights} from "../../utils/authentication";

function usersMiddleware() {

  return ({dispatch, getState}) => (next) => (action) => {

    const authentication = getState().authentication;

    switch (action.type) {

      case AUTHENTICATION_ACTION_TYPE.SET_IS_AUTHENTICATED:
        if (action.payload) {

          // load users for administrator
          if(checkAccessRights(
              authentication.accountData.roles,
              [roles.admin]
          )){

            dispatch(loadUsers());

          }


        } else {
          dispatch(SET_USERS());
        }
        return next(action);
      default:
        return next(action);
    }
  }
}

export default usersMiddleware();