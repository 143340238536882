import {ACTION_TYPE} from "../actions/licence";


const initialState = {
  showModal: false,
  willTerminate: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {

    case ACTION_TYPE.SET_SHOW_LICENCE_MODAL: {
      return {
        ...state,
        showModal: action.payload,
      }
    }

    case ACTION_TYPE.SET_SHOW_LICENCE_WILL_TERMINATE: {
      return {
        ...state,
        willTerminate: action.payload,
      }
    }

    default:
      return state;
  }
}