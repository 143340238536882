import {createAction} from "../../factory/redux/action";
import {fetchPost} from "../../utils/request";
import {apiUrls} from "../../constants/urls";
import {isFunc} from "../../utils/function";
import {isEmpty} from "../../utils/string";
import {createComment} from "../../factory/redux/comment";


export const ACTION_TYPE = Object.freeze({
  DROP_COMMENT_DATA: "DROP_COMMENT_DATA",

  SET_COMMENT_IN_PROCESS: "SET_COMMENT_IN_PROCESS",

  SET_COMMENT_SHOW_ADD_MODAL: "SET_COMMENT_SHOW_ADD_MODAL",
  SET_COMMENT_ADD: "SET_COMMENT_ADD",

  SET_COMMENT_EDIT_SHOW_MODAL: "SET_COMMENT_EDIT_SHOW_MODAL",
  SET_COMMENT_EDIT: "SET_COMMENT_EDIT",

  SET_COMMENT_DELETE_SHOW_MODAL: "SET_COMMENT_DELETE_SHOW_MODAL",
  SET_COMMENT_DELETE: "SET_COMMENT_DELETE",
});


export const DROP_COMMENT_DATA = () => createAction(
    ACTION_TYPE.DROP_COMMENT_DATA,
    null,
);

export const SET_COMMENT_IN_PROCESS = (payload) => createAction(
    ACTION_TYPE.SET_COMMENT_IN_PROCESS,
    payload,
);

// ADD

export const SET_COMMENT_SHOW_ADD_MODAL = (payload) => createAction(
    ACTION_TYPE.SET_COMMENT_SHOW_ADD_MODAL,
    payload,
);

export const SET_COMMENT_ADD = (payload) => createAction(
    ACTION_TYPE.SET_COMMENT_ADD,
    payload,
);

/**
 * Validate comment
 * @returns {Function}
 */
export function validateCommentAdd() {

  return (dispatch, getState) => {

    const
        comment = getState().comment.add.comment,
        commentTypesList = getState().options.commentTypeList;

    if (comment) {
      const
          typeIsValid = Boolean(
              !isEmpty(comment.type.value) &&
              commentTypesList.find((commentType) =>
                  (commentType.type === comment.type.value) && commentType.enabled
              )
          ),
          textIsValid = !isEmpty(comment.text.value);

      const isValid = typeIsValid && textIsValid;

      dispatch(
          SET_COMMENT_ADD(
              createComment({
                id: comment.id,
                cardId: comment.cardId,
                type: comment.type.value,
                typeIsValid,
                text: comment.text.value,
                textIsValid,
                isValid,
              })
          )
      );

      return isValid;
    } else {
      return false;
    }
  }
}

/**
 * Add comment to a card
 * @param cardId - id of card to attach comment to
 * @param type - comment type
 * @param text - comment text
 * @param callback - callback to update data on success
 * @returns {Function}
 */
export function commentAdd(
    cardId,
    type,
    text,
    callback
) {

  return (dispatch, getState) => {

    const
        isValid = dispatch(validateCommentAdd());

    if (isValid) {
      dispatch(SET_COMMENT_IN_PROCESS(true));

      return fetchPost(
          apiUrls.comment.add.url,
          {
            commentData: {
              cardId,
              type,
              text,
            },
          }
      )
          .then((response) => {
            if (response.success) {
              if (isFunc(callback)) {
                callback();
              }
            }
          })
          .finally(() => {
            dispatch(SET_COMMENT_IN_PROCESS(false));
          });
    } else {
      // return Promise.reject(new Error("Comment is not valid"));
    }
  }

}


// EDIT

export const SET_COMMENT_EDIT_SHOW_MODAL = (payload) => createAction(
    ACTION_TYPE.SET_COMMENT_EDIT_SHOW_MODAL,
    payload,
);
export const SET_COMMENT_EDIT = (payload) => createAction(
    ACTION_TYPE.SET_COMMENT_EDIT,
    payload,
);

/**
 * Validate comment
 * @returns {Function}
 */
export function validateCommentEdit() {

  return (dispatch, getState) => {

    const
        comment = getState().comment.edit.comment,
        commentTypesList = getState().options.commentTypeList;

    if (comment) {
      const
          typeIsValid = Boolean(
              !isEmpty(comment.type.value) &&
              commentTypesList.find((commentType) =>
                  (commentType.type === comment.type.value) && commentType.enabled
              )
          ),
          textIsValid = !isEmpty(comment.text.value);

      const isValid = typeIsValid && textIsValid;

      dispatch(
          SET_COMMENT_EDIT(
              createComment({
                id: comment.id,
                type: comment.type.value,
                typeIsValid: typeIsValid,
                text: comment.text.value,
                textIsValid: textIsValid,
                isValid: isValid,
              })
          )
      );

      return isValid;
    } else {
      return false;
    }
  }
}

/**
 * Edit comment
 * @param id - id of comment
 * @param type - type of comment
 * @param text - text of comment
 * @param callback - callback to update data on success
 * @returns {function(*, *): (Q.Promise<T> | Promise<any> | *)}
 */
export function commentEdit(
    id,
    type,
    text,
    callback
) {
  return (dispatch, getState) => {

    const
        isValid = dispatch(validateCommentEdit());

    if (isValid) {
      dispatch(SET_COMMENT_IN_PROCESS(true));

      return fetchPost(
          apiUrls.comment.update.url,
          {
            commentData: {
              id,
              type,
              text,
            },
          }
      )
          .then((response) => {
            if (response.success) {
              if (isFunc(callback)) {
                callback();
              }
            }
          })
          .finally(() => {
            dispatch(SET_COMMENT_IN_PROCESS(false));
          });
    } else {
      // return Promise.reject(new Error("Comment is not valid"));
    }
  }
}

// DELETE

export const SET_COMMENT_DELETE_SHOW_MODAL = (payload) => createAction(
    ACTION_TYPE.SET_COMMENT_DELETE_SHOW_MODAL,
    payload,
);
export const SET_COMMENT_DELETE = (payload) => createAction(
    ACTION_TYPE.SET_COMMENT_DELETE,
    payload,
);


/**
 * Delete specific comment
 * @param commentId - id of comment to delete
 * @param callback - callback to update data on success
 * @returns {function(*, *): (Q.Promise<T> | Promise<any> | *)}
 */
export function commentDelete(commentId, callback) {
  return (dispatch, getState) => {

    dispatch(SET_COMMENT_IN_PROCESS(true));

    return fetchPost(
        apiUrls.comment.delete.url,
        {
          commentId,
        }
    )
        .then((response) => {
          if (response.success) {
            // dispatch(refreshCardEdit());

            // if(response.cardWasDeleted) {
            //
            // }

            if (isFunc(callback)) {
              callback(response);
            }
          }
        })
        .finally(() => {
          dispatch(SET_COMMENT_IN_PROCESS(false));
        });
  }
}



