import React, {useEffect} from 'react';
import './App.scss';
import {Loading} from "../../components/loading/Loading";
import {Switch} from "react-router-dom";
import {Route} from "../../components/route/Route";
import {appUrls} from "../../constants/urls";
import Loadable from 'react-loadable';
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {checkAuthentication, signOut} from "../../redux/actions/authentication";
import {setRequestDispatch, setRequestHistory} from "../../utils/request";
import { GlobalDebug } from "../../utils/remove-console";

const components = [
  {
    url: appUrls.landing.url,
    exact: true,
    roles: appUrls.landing.roles,
    element: Loadable({
      loader: () => import('../../pages/landing/LandingPage'),
      loading: Loading,
    }),
  },
  {
    url: appUrls.authentication.url,
    exact: true,
    roles: appUrls.authentication.roles,
    element: Loadable({
      loader: () => import('../../pages/authentication/AuthenticationPage'),
      loading: Loading,
    }),
  },
  {
    url: appUrls.checkGuest.url,
    exact: true,
    roles: appUrls.checkGuest.roles,
    element: Loadable({
      loader: () => import('../../pages/checkGuest/CheckGuestPage'),
      loading: Loading,
    }),
  },
  {
    url: appUrls.cabinet.url,
    exact: true,
    roles: appUrls.cabinet.roles,
    element: Loadable({
      loader: () => import('../../pages/cabinet/CabinetPage'),
      loading: Loading,
    }),
  },
  {
    url: appUrls.admin.url,
    exact: true,
    roles: appUrls.users.roles,
    element: Loadable({
      loader: () => import('../../pages/admin/AdminCabinet'),
      loading: Loading,
    }),
  },
  {
    url: appUrls.unauthorized.url,
    exact: true,
    roles: appUrls.unauthorized.roles,
    element: Loadable({
      loader: () => import('../../pages/errorPages/unauthorized/UnauthorizedPage'),
      loading: Loading,
    }),
  },
];

export default function App({...props}) {

  const
    dispatch = useDispatch(),
    history = useHistory(),
    authentication = useSelector((state) => state.authentication);

  // behaves like componentDidMount
  useEffect(() => {
    setRequestDispatch(dispatch);
    setRequestHistory(history);
    dispatch(checkAuthentication());
    GlobalDebug(false);

    // window.addEventListener('beforeunload', function() {
    //   dispatch(signOut());
    // })
  }, []);



  return (
      <Switch>

        {
          components.map((component, index) => (
            <Route
              key={index}
              component={component.element}
              exact={component.exact}
              path={component.url}
              roles={component.roles}
            />
          ))
        }

        <Route component={
          Loadable({
            loader: () => import('../../pages/errorPages/notFound/NotFoundPage'),
            loading: Loading,
          })
        }
          // exact={component.exact}
          // path={component.url}
               roles={appUrls.notFound.roles}
        />
      </Switch>
  );
}
App.propTypes = {};
App.defaultProps = {};
