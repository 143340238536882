"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Check if the parameter is String
 * @param string
 * @returns {boolean}
 */
function isStr(string) {
    return (typeof string === 'string' || string instanceof String);
}
exports.isStr = isStr;
/**
 * Replace all substrings in provided string
 * @param target - string to make replacement in
 * @param search - substring to replace
 * @param replacement - substring to replace with
 * @returns {string} - result of replacement
 */
function replaceAll(target, search, replacement) {
    return target.split(search).join(replacement);
}
exports.replaceAll = replaceAll;
/**
 * Join array of class name strings
 * @param args - class name strings
 * @returns {string} - joined string
 */
function joinClassNames() {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    return joinStrings(args, " ");
}
exports.joinClassNames = joinClassNames;
/**
 * Join array of strings with provided joiner
 * @param strings - array of strings to join
 * @param joiner - string to join with
 * @returns {string} - joined strings
 */
function joinStrings(strings, joiner) {
    if (strings === void 0) { strings = []; }
    if (joiner === void 0) { joiner = ""; }
    return strings.join(joiner);
}
exports.joinStrings = joinStrings;
/**
 * Check if string is null, undefined or empty
 * @param string
 * @returns {boolean}
 */
function isEmpty(string) {
    return (!string || 0 === string.length);
}
exports.isEmpty = isEmpty;
/**
 * Check if string is null, undefined, empty or whitespace
 * @param string
 * @returns {boolean}
 */
function isEmptyOrWhitespace(string) {
    if (!isEmpty(string)) {
        return isEmpty(string.trim());
    }
    else {
        return true;
    }
    return (!string || 0 === string.length);
}
exports.isEmptyOrWhitespace = isEmptyOrWhitespace;
/**
 * Shorten string to a specific length
 * @param string - string to shorten
 * @param maxLength - maximum length of the string
 */
function shortenString(string, maxLength) {
    if (maxLength === void 0) { maxLength = 20; }
    if (isStr(string)) {
        if (string.length <= maxLength) {
            return string;
        }
        else {
            return string.substring(0, maxLength);
        }
    }
    return string;
}
exports.shortenString = shortenString;
/**
 * Adjust personal names to fit common template
 * @param string - string to adjust
 */
function adjustPersonalName(string) {
    var newString = capitalizeFirstLetter(string);
    newString = replaceUnwantedLetters(newString);
    return newString;
}
exports.adjustPersonalName = adjustPersonalName;
/**
 * Capitalize first letter of the word
 * @param string
 */
function capitalizeFirstLetter(string) {
    if (!isStr(string) || isEmpty(string)) {
        return string;
    }
    return string[0].toUpperCase() +
        string.slice(1);
}
exports.capitalizeFirstLetter = capitalizeFirstLetter;
/**
 * Replace symbols like "ё"
 * @param string
 * @returns {*}
 */
function replaceUnwantedLetters(string) {
    if (!isStr(string) || isEmpty(string)) {
        return string;
    }
    var newString = replaceAll(string, "Ё", "Е");
    newString = replaceAll(newString, "ё", "е");
    return newString;
}
exports.replaceUnwantedLetters = replaceUnwantedLetters;
