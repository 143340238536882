import {ACTION_TYPE as COMMON_ACTION_TYPE} from "../actions/common";
import {ACTION_TYPE} from "../actions/serverInfo";
import {createServerInfoRedux} from "../../factory/redux/serverInfo";

const initialState = {
  inProcess: false,

  data: createServerInfoRedux({}),

};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case COMMON_ACTION_TYPE.CLEAR_STORE:
    case ACTION_TYPE.CLEAR_SERVER_INFO:
      return {
        ...initialState,
      };
    case ACTION_TYPE.SET_SERVER_INFO:
      return {
        ...state,
        data: createServerInfoRedux(action.payload),
      };
    case ACTION_TYPE.SET_SERVER_INFO_IN_PROCESS:
      return {
        ...state,
        inProcess: action.payload,
      };

    case ACTION_TYPE.SET_SERVER_INFO_FIELD_VALUE: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.field]: {
            ...state.data[action.payload.field],
            value: action.payload.value,
            isValid: null,
          },
        },
      };
    }

    case ACTION_TYPE.SET_SERVER_INFO_FIELD_IS_VALID: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.field]: {
            ...state.data[action.payload.field],
            isValid: action.payload.isValid,
          },
        },
      };
    }

    default:
      return state;
  }
}