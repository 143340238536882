import {NOTIFICATION_CONFIG} from "../../notification/notification";

export function createNotification({
                                     config=NOTIFICATION_CONFIG.WARNING_YELLOW,
                                     title,
                                     message,
                                     actions
                                   }) {

  return {
    config,
    title,
    message,
    actions
  };
}