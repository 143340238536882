import {ACTION_TYPE as COMMON_ACTION_TYPE} from "../actions/common";
import {ACTION_TYPE} from "../actions/cardCreate";
import {
  createPersonalData
} from "../../factory/redux/personalData";
import {
  createDocumentData
} from "../../factory/redux/documentData";
import {
  createComment
} from "../../factory/redux/comment";

const initialState = {
  inProcess: false,

  personalData: {

    ...createPersonalData({
    }),

    documentDataList: [
      // initial document must always present
      createDocumentData({}),
    ],
  },

  comments: [
    createComment({}),
  ],
  commentsIsValid: null,
  photos: [
    // {
    //   file: ...,
    //   name: "",
    //   data: ...,
    // }
  ],
  photoInProcess: false,

  error: null,
};

function setDocumentDataField(
    state,
    documentDataIndex,
    fieldName,
    value,
    isValid = null,
    errorMessage = "",
) {
  return {
    ...state,
    personalData: {
      ...state.personalData,
      documentDataList: [
        ...state.personalData.documentDataList.map((documentData, index) => {
          if (index === documentDataIndex) {
            return {
              ...documentData,
              [fieldName]: {
                ...documentData[fieldName],
                value,
                isValid,
                errorMessage,
              }
            };
          } else {
            return documentData;
          }
        })
      ],
    }
  }
}

function setCommentField(
    state,
    commentIndex,
    fieldName,
    value,
    isValid
) {
  return {
    ...state,
    comments: [
      ...state.comments.map((comment, index) => {
        if (index === commentIndex) {

          return {
            ...comment,
            [fieldName]: {
              ...comment[fieldName],
              value,
              isValid,
            },
          };
        } else {
          return comment;
        }
      })
    ],
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPE.DROP_CLIENT_CREATE_DATA:
    case COMMON_ACTION_TYPE.CLEAR_STORE:
      return {
        ...initialState,
      };
    case ACTION_TYPE.SET_CLIENT_CREATE_IN_PROCESS:
      return {
        ...state,
        inProcess: action.payload,
      };

    case ACTION_TYPE.SET_CLIENT_CREATE_FAMILY_NAME:
      return {
        ...state,
        personalData: {
          ...state.personalData,
          familyName: {
            ...state.personalData.familyName,
            ...action.payload,
          },
        }
      };
    case ACTION_TYPE.SET_CLIENT_CREATE_NAME:
      return {
        ...state,
        personalData: {
          ...state.personalData,
          name: {
            ...state.personalData.name,
            ...action.payload,
          },
        }
      };
    case ACTION_TYPE.SET_CLIENT_CREATE_PATRONYMIC:
      return {
        ...state,
        personalData: {
          ...state.personalData,
          patronymic: {
            ...state.personalData.patronymic,
            ...action.payload,
          },
        }
      };
    case ACTION_TYPE.SET_CLIENT_CREATE_DATE_OF_BIRTH:
      return {
        ...state,
        personalData: {
          ...state.personalData,
          dateOfBirth: {
            ...state.personalData.dateOfBirth,
            ...action.payload,
          },
        }
      };
    case ACTION_TYPE.SET_CLIENT_CREATE_KALOEV_ID:
      return {
        ...state,
        personalData: {
          ...state.personalData,
          kaloevId: {
            ...state.personalData.kaloevId,
            ...action.payload,
          },
        }
      };
    case ACTION_TYPE.SET_CLIENT_CREATE_EXISTS:
      return {
        ...state,
        personalData: {
          ...state.personalData,
          exists: {
            ...state.personalData.exists,
            value: action.payload.value,
            cardId: action.payload.cardId,
          },
        }
      };
    case ACTION_TYPE.SET_CLIENT_CREATE_SHOW_EXISTS_MODAL:
      return {
        ...state,
        personalData: {
          ...state.personalData,
          exists: {
            ...state.personalData.exists,
            showModal: action.payload,
          },
        }
      };

      // document data
      // case ACTION_TYPE.ADD_CLIENT_CREATE_DOCUMENT:
      //   return {
      //       ...state,
      //     documentDataList: [
      //         ...state.documentDataList,
      //         createDocumentData({}),
      //     ],
      //   };
      // case ACTION_TYPE.REMOVE_CLIENT_CREATE_DOCUMENT:
      //   return {
      //       ...state,
      //     documentDataList: [
      //         ...state.documentDataList.filter(
      //             (documentData, index) => index !== action.payload
      //         ),
      //     ],
      //   };

    case ACTION_TYPE.SET_CLIENT_CREATE_DOCUMENT_FIELD:
      return setDocumentDataField(
          state,
          action.payload.documentDataIndex,
          action.payload.fieldName,
          action.payload.value,
          action.payload.isValid
      );

      // case ACTION_TYPE.SET_CLIENT_CREATE_DOCUMENT_COUNTRY:
      //   return {
      //     ...state,
      //     personalData: {
      //       documentDataList: [
      //         ...state.documentDataList.map((documentData, index) => {
      //           if (index === action.payload.index) {
      //             return {
      //               ...documentData,
      //               country: {
      //                 ...documentData.country,
      //                 value: action.payload.value,
      //                 isValid: action.payload.isValid,
      //               },
      //             };
      //           } else {
      //             return documentData;
      //           }
      //         })
      //       ],
      //     },
      //
      //     // documentData: {
      //     //   ...state.documentData,
      //     //   country: {
      //     //     ...state.documentData.country,
      //     //     value: action.payload.value,
      //     //     isValid: action.payload.isValid,
      //     //   },
      //     // }
      //   };
      // case ACTION_TYPE.SET_CLIENT_CREATE_DOCUMENT_NUMBER:
      //   return {
      //     ...state,
      //
      //     documentDataList: [
      //       ...state.documentDataList.map((documentData, index) => {
      //         if (index === action.payload.index) {
      //           return {
      //             ...documentData,
      //             number: {
      //               ...documentData.number,
      //               value: action.payload.value,
      //               isValid: action.payload.isValid,
      //             },
      //           };
      //         } else {
      //           return documentData;
      //         }
      //       })
      //     ],
      //
      //     // documentData: {
      //     //   ...state.documentData,
      //     //   number: {
      //     //     ...state.documentData.number,
      //     //     ...action.payload,
      //     //   },
      //     // }
      //   };
      // case ACTION_TYPE.SET_CLIENT_CREATE_DOCUMENT_ISSUE_DATE:
      //   return {
      //     ...state,
      //
      //     documentDataList: [
      //       ...state.documentDataList.map((documentData, index) => {
      //         if (index === action.payload.index) {
      //           return {
      //             ...documentData,
      //             issueDate: {
      //               ...documentData.issueDate,
      //               value: action.payload.value,
      //               isValid: action.payload.isValid,
      //             },
      //           };
      //         } else {
      //           return documentData;
      //         }
      //       })
      //     ],
      //
      //     // documentData: {
      //     //   ...state.documentData,
      //     //   issueDate: {
      //     //     ...state.documentData.issueDate,
      //     //     ...action.payload,
      //     //   },
      //     // }
      //   };
      // case ACTION_TYPE.SET_CLIENT_CREATE_DOCUMENT_EXPIRE_DATE:
      //   return {
      //     ...state,
      //
      //     documentDataList: [
      //       ...state.documentDataList.map((documentData, index) => {
      //         if (index === action.payload.index) {
      //           return {
      //             ...documentData,
      //             expireDate: {
      //               ...documentData.expireDate,
      //               value: action.payload.value,
      //               isValid: action.payload.isValid,
      //             },
      //           };
      //         } else {
      //           return documentData;
      //         }
      //       })
      //     ],
      //
      //     // documentData: {
      //     //   ...state.documentData,
      //     //   expireDate: {
      //     //     ...state.documentData.expireDate,
      //     //     ...action.payload,
      //     //   },
      //     // }
      //   };
      // case ACTION_TYPE.SET_CLIENT_CREATE_DOCUMENT_PERSONAL_NUMBER:
      //   return {
      //     ...state,
      //
      //     documentDataList: [
      //       ...state.documentDataList.map((documentData, index) => {
      //         if (index === action.payload.index) {
      //           return {
      //             ...documentData,
      //             personalId: {
      //               ...documentData.personalId,
      //               value: action.payload.value,
      //               isValid: action.payload.isValid,
      //             },
      //           };
      //         } else {
      //           return documentData;
      //         }
      //       })
      //     ],
      //
      //     // documentData: {
      //     //   ...state.documentData,
      //     //   personalId: {
      //     //     ...state.documentData.personalId,
      //     //     ...action.payload,
      //     //   },
      //     // }
      //   };

    case ACTION_TYPE.ADD_CLIENT_COMMENT:
      return {
        ...state,
        comments: [
          ...state.comments,
          createComment({
            type: action.payload,
            text: "",
            isValid: null,
          }),
        ],
        commentsIsValid: null,
      };

      // comments
    case ACTION_TYPE.SET_COMMENT_FIELD:{
      const {
        commentIndex,
        fieldName,
        value,
        isValid
      } = action.payload;

      return setCommentField(
          state,
          commentIndex,
          fieldName,
          value,
          isValid
      );
    }


    case ACTION_TYPE.SET_COMMENTS_IS_VALID: {
      return {
        ...state,
        commentsIsValid: action.payload,
      };
    }
    case ACTION_TYPE.SET_COMMENT: {
      // find comment and set its data
      const newCommentsArray = state.comments
          .map((comment, index) =>
              ({
                ...comment,
                type: (index === action.payload.index)
                    ? action.payload.type
                    : comment.type.value,
                text: (index === action.payload.index)
                    ? action.payload.text
                    : comment.text.value,
                isValid: (index === action.payload.index)
                    ? action.payload.isValid
                    : comment.isValid,
              })
          );

      return {
        ...state,
        comments: newCommentsArray,
      };
    }
    case ACTION_TYPE.DELETE_COMMENT: {
      if(state.comments.length > 1) {
        return {
          ...state,
          comments: [
            ...state.comments.filter(
                (value, index) => (index !== action.payload)
            )
          ],
        }
      } else {
        return {
            ...state,
        }
      }
    }

      // photos
    case ACTION_TYPE.ADD_CLIENT_CREATE_PHOTO: {
      const photo = {
        // file: action.payload.file,
        name: action.payload.file.name,
        data: action.payload.data,
      };

      return {
        ...state,
        photos: [
          ...state.photos,
          photo,
        ]
      }
    }

    case ACTION_TYPE.REMOVE_CLIENT_CREATE_PHOTO : {
      const newPhotos = [];
      if (state.photos[action.payload]) {
        newPhotos.push(
            ...state.photos.filter((photo, index) => index !== action.payload)
        );
      } else {
        newPhotos.push(
            ...state.photos
        );
      }

      return {
        ...state,
        photos: newPhotos
      }
    }

    case ACTION_TYPE.SET_CLIENT_CREATE_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
}