import SockJS from 'sockjs-client'
import { Stomp } from '@stomp/stompjs'

let stompClient = null
const handlers = []

export function connect() {
    const socket = new SockJS("/owl-websocket");
    stompClient = Stomp.over(socket)
    stompClient.debug = function(str) {};
    stompClient.connect({}, frame => {
        stompClient.subscribe('/topic/activity', message => {
            handlers.forEach(handler => handler(JSON.parse(message.body)))
        })
    })
    stompClient.onclose = function() {
        setTimeout(connect, 1000);
    }
}

export function addHandler(handler) {
    handlers.push(handler)
}

export function disconnect() {
    if (stompClient !== null) {
        stompClient.disconnect()
    }
}

export function sendMessage(message) {
    stompClient.send('/app/newNotification', {}, JSON.stringify(message))
}