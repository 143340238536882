import {fetchPost} from "../../utils/request";
import {apiUrls} from "../../constants/urls";
import {isEmpty} from "../../utils/string";
import {isFunc} from "../../utils/function";
import {dateToString, isValidDate} from "../../utils/date";
import {ResponseError} from "../../error/api/ResponseError";
import {
  SET_CLIENT_CREATE_EXISTS,
  SET_CLIENT_CREATE_SHOW_EXISTS_MODAL
} from "./cardCreate";
import {logger} from "../../utils/logger";

export const ACTION_TYPE = Object.freeze({
  SET_CLIENT_EDIT_IN_PROCESS: "SET_CLIENT_EDIT_IN_PROCESS",
  DROP_CLIENT_EDIT_DATA: "DROP_CLIENT_EDIT_DATA",

  SET_CARD_ID: "SET_CARD_ID",

  SET_CLIENT_EDIT_CLIENT_DATE_OF_BIRTH: "SET_CLIENT_EDIT_CLIENT_DATE_OF_BIRTH",
  SET_CLIENT_EDIT_ESTABLISHMENT_ID: "SET_CLIENT_EDIT_ESTABLISHMENT_ID",
  SET_CLIENT_EDIT_CLIENT_LIST: "SET_CLIENT_EDIT_CLIENT_LIST",

  ADD_PERSONAL_DATA: "ADD_PERSONAL_DATA",
  REMOVE_PERSONAL_DATA: "REMOVE_PERSONAL_DATA",

  SET_CLIENT_EDIT_PERSONAL_FIELD: "SET_CLIENT_EDIT_PERSONAL_FIELD",
  SET_CLIENT_EDIT_PERSONAL_FIELD_VALID: "SET_CLIENT_EDIT_PERSONAL_FIELD_VALID",

  SET_CLIENT_EDIT_EXISTS: "SET_CLIENT_EDIT_EXISTS",
  SET_CLIENT_EDIT_SHOW_EXISTS_MODAL: "SET_CLIENT_EDIT_SHOW_EXISTS_MODAL",

  // ADD_DOCUMENT_DATA: "ADD_DOCUMENT_DATA",
  // REMOVE_DOCUMENT_DATA: "REMOVE_DOCUMENT_DATA",

  SET_CLIENT_EDIT_DOCUMENT_FIELD: "SET_CLIENT_EDIT_DOCUMENT_FIELD",
  SET_CLIENT_EDIT_DOCUMENT_FIELD_VALID: "SET_CLIENT_EDIT_DOCUMENT_FIELD_VALID",

  SET_CLIENT_EDIT_COMMENTS: "SET_CLIENT_EDIT_COMMENTS",

  // SET_CLIENT_EDIT_PHOTOS: "SET_CLIENT_EDIT_PHOTOS",
  ADD_CLIENT_EDIT_PHOTO: "ADD_CLIENT_EDIT_PHOTO",
  CLEAR_CLIENT_EDIT_PHOTO: "CLEAR_CLIENT_EDIT_PHOTO",
  SET_CLIENT_EDIT_PHOTO_DELETE: "SET_CLIENT_EDIT_PHOTO_DELETE",
  SET_CLIENT_EDIT_PHOTO_DELETE_SHOW_MODAL: "SET_CLIENT_EDIT_PHOTO_DELETE_SHOW_MODAL",
  SET_CLIENT_EDIT_ADD_PHOTO: "SET_CLIENT_EDIT_ADD_PHOTO",
  SET_CLIENT_EDIT_ADD_PHOTO_SHOW_MODAL: "SET_CLIENT_EDIT_ADD_PHOTO_SHOW_MODAL",

  SET_CLIENT_EDIT_SHOW_COMMENT_DELETE_MODAL: "SET_CLIENT_EDIT_SHOW_COMMENT_DELETE_MODAL",
  SET_CLIENT_EDIT_COMMENT_DELETE: "SET_CLIENT_EDIT_COMMENT_DELETE",

});

const SET_CLIENT_EDIT_IN_PROCESS = (payload) => ({
  type: ACTION_TYPE.SET_CLIENT_EDIT_IN_PROCESS,
  payload,
});

const DROP_CLIENT_EDIT_DATA = () => ({
  type: ACTION_TYPE.DROP_CLIENT_EDIT_DATA,
  payload: null,
});

const SET_CARD_ID = (payload) => ({
  type: ACTION_TYPE.SET_CARD_ID,
  payload,
});

const SET_CLIENT_EDIT_CLIENT_DATE_OF_BIRTH = ({
                                                dateOfBirth,
                                                dateOfBirthLocked
                                              }) => ({
  type: ACTION_TYPE.SET_CLIENT_EDIT_CLIENT_DATE_OF_BIRTH,
  payload: {
    dateOfBirth,
    dateOfBirthLocked
  },
});
const SET_CLIENT_EDIT_ESTABLISHMENT_ID = (payload) => ({
  type: ACTION_TYPE.SET_CLIENT_EDIT_ESTABLISHMENT_ID,
  payload,
});
const SET_CLIENT_EDIT_CLIENT_LIST = (payload) => ({
  type: ACTION_TYPE.SET_CLIENT_EDIT_CLIENT_LIST,
  payload,
});

// personal data

export const ADD_PERSONAL_DATA = () => ({
  type: ACTION_TYPE.ADD_PERSONAL_DATA,
  payload: null,
});

export const REMOVE_PERSONAL_DATA = (personalDataIndex, personalDataId) => ({
  type: ACTION_TYPE.REMOVE_PERSONAL_DATA,
  payload: {
    personalDataIndex,
    personalDataId,
  },
});

export const SET_CLIENT_EDIT_PERSONAL_FIELD = (fieldName, personalDataIndex, value) => ({
  type: ACTION_TYPE.SET_CLIENT_EDIT_PERSONAL_FIELD,
  payload: {
    fieldName,
    personalDataIndex,
    value,
  },
});

export const SET_CLIENT_EDIT_PERSONAL_FIELD_VALID = (fieldName, personalDataIndex, isValid) => ({
  type: ACTION_TYPE.SET_CLIENT_EDIT_PERSONAL_FIELD_VALID,
  payload: {
    fieldName,
    personalDataIndex,
    isValid,
  },
});


/**
 * Set if the client with provided parameters already exists in system
 * @param index - index of existing client name data
 * @param value - existence value
 * @param cardId - id of existing client
 * @returns {{type: string, payload: {value: *, cardId: *}}}
 * @constructor
 */
export const SET_CLIENT_EDIT_EXISTS = (
    index,
    value,
    cardId,
) => ({
  type: ACTION_TYPE.SET_CLIENT_EDIT_EXISTS,
  payload: {
    index,
    value,
    cardId,
  },
});
/**
 * Set ClientExistsModal display value
 * @param index - index of existing client name data
 * @param value - true - display
 * @returns {{type: string, payload: *}}
 * @constructor
 */
export const SET_CLIENT_EDIT_SHOW_EXISTS_MODAL = (
    index,
    value,
) => ({
  type: ACTION_TYPE.SET_CLIENT_EDIT_SHOW_EXISTS_MODAL,
  payload: {
    index,
    value,
  },
});


// document data
// export const ADD_DOCUMENT_DATA =
//     (personalDataIndex) => ({
//       type: ACTION_TYPE.ADD_DOCUMENT_DATA,
//       payload: {
//         personalDataIndex,
//       },
//     });
//
// export const REMOVE_DOCUMENT_DATA =
//     (personalDataIndex, documentDataIndex, documentDataId) => ({
//       type: ACTION_TYPE.REMOVE_DOCUMENT_DATA,
//       payload: {
//         personalDataIndex,
//         documentDataIndex,
//         documentDataId,
//       },
//     });

// document data fields
export const SET_CLIENT_EDIT_DOCUMENT_FIELD =
    (fieldName, personalDataIndex, index, value) => ({
      type: ACTION_TYPE.SET_CLIENT_EDIT_DOCUMENT_FIELD,
      payload: {
        fieldName,
        personalDataIndex,
        index,
        value,
      },
    });

export const SET_CLIENT_EDIT_DOCUMENT_FIELD_VALID =
    (fieldName, personalDataIndex, index, isValid) => ({
      type: ACTION_TYPE.SET_CLIENT_EDIT_DOCUMENT_FIELD_VALID,
      payload: {
        fieldName,
        personalDataIndex,
        index,
        isValid,
      },
    });

// comments
// set comments list
const SET_CLIENT_EDIT_COMMENTS = (payload) => ({
      type: ACTION_TYPE.SET_CLIENT_EDIT_COMMENTS,
      payload,
    });

// show comment delete modal
export const SET_CLIENT_EDIT_SHOW_COMMENT_DELETE_MODAL = (payload) => ({
  type: ACTION_TYPE.SET_CLIENT_EDIT_SHOW_COMMENT_DELETE_MODAL,
  payload,
});
export const SET_CLIENT_EDIT_COMMENT_DELETE = (payload) => ({
  type: ACTION_TYPE.SET_CLIENT_EDIT_COMMENT_DELETE,
  payload,
});

// photos
// const SET_CLIENT_EDIT_PHOTOS = (payload) => ({
//   type: ACTION_TYPE.SET_CLIENT_EDIT_PHOTOS,
//   payload,
// });
const ADD_CLIENT_EDIT_PHOTO = (payload) => ({
  type: ACTION_TYPE.ADD_CLIENT_EDIT_PHOTO,
  payload,
});
const CLEAR_CLIENT_EDIT_PHOTO = (payload) => ({
  type: ACTION_TYPE.CLEAR_CLIENT_EDIT_PHOTO,
  payload,
});
export const SET_CLIENT_EDIT_PHOTO_DELETE = (payload) => ({
  type: ACTION_TYPE.SET_CLIENT_EDIT_PHOTO_DELETE,
  payload,
});
export const SET_CLIENT_EDIT_PHOTO_DELETE_SHOW_MODAL = (payload) => ({
  type: ACTION_TYPE.SET_CLIENT_EDIT_PHOTO_DELETE_SHOW_MODAL,
  payload,
});
// select photo for uploading
export const SET_CLIENT_EDIT_ADD_PHOTO = (file, data) => ({
  type: ACTION_TYPE.SET_CLIENT_EDIT_ADD_PHOTO,
  payload: {
    file,
    data,
  }
});
export const SET_CLIENT_EDIT_ADD_PHOTO_SHOW_MODAL = (payload) => ({
  type: ACTION_TYPE.SET_CLIENT_EDIT_ADD_PHOTO_SHOW_MODAL,
  payload,
});

/**
 * set card id for editing and load data
 * @param cardId - id of card
 * @returns {Function}
 */
export function setCardEditCardId(cardId) {
  return (dispatch, getState) => {
    dispatch(DROP_CLIENT_EDIT_DATA());
    dispatch(SET_CARD_ID(cardId));

    return dispatch(refreshCardEdit());
  }
}

/**
 * reload card edit data
 * @returns {Function}
 */
export function refreshCardEdit() {
  return (dispatch, getState) => {
    // extract card id
    const cardId = getState().cardEdit.cardId;

    // load data
    return dispatch(loadCardEditFullData(cardId));
  }
}

export function refreshCommentCardEdit() {
  return (dispatch, getState) => {
    // extract card id
    const cardId = getState().cardEdit.cardId;

    // load data
    return dispatch(loadCardEditComments(cardId));
  }
}

export function refreshPhotoCardEdit() {
  return (dispatch, getState) => {
    // extract card id
    const cardId = getState().cardEdit.cardId;

    // load data
    // return dispatch(loadCardEditFullData(cardId));
    return dispatch(loadPhoto(cardId));
  }
}

// setup AbortController
let controller = new AbortController();

/**
 * Load all data about client
 * @param clientId
 * @returns {Function}
 */
function loadCardEditFullData(clientId) {

  return (dispatch, getState) => {

    const inProcess = getState().cardEdit.inProcess;
    // if still in process - abort previous request
    if (inProcess) {
      controller.abort();
      // initialize new abort controller,
      // because it cannot be reset to normal state
      controller = new AbortController();
    }

    // closure to remember actual controller state
    const _controller = controller;

    dispatch(SET_CLIENT_EDIT_IN_PROCESS(true));

    return fetchPost(
        apiUrls.client.get.url,
        {
          clientId,
        },
        controller.signal
    )
        .then((response) => {
          if (response.success) {

            const cardData = response.data;
            const {photoList, localCommentList, globalCommentList} = cardData;

            dispatch(SET_CLIENT_EDIT_CLIENT_DATE_OF_BIRTH({
              dateOfBirth: cardData.dateOfBirth,
              dateOfBirthLocked: cardData.dateOfBirthLocked,
            }));

            dispatch(SET_CLIENT_EDIT_ESTABLISHMENT_ID(parseInt(cardData.establishmentID)));
            dispatch(SET_CLIENT_EDIT_CLIENT_LIST(cardData.personalDataList));

            if (getState().authentication.accountData.roles === 4) {
              dispatch(SET_CLIENT_EDIT_COMMENTS(localCommentList.concat(globalCommentList)));
            } else {
              dispatch(SET_CLIENT_EDIT_COMMENTS(localCommentList));
            }

            dispatch(loadClientEditPhotos(photoList.map(photo => photo.id)));

          } else {
            return Promise.reject(new Error("Card error"));
          }
        })
        .finally(() => {
          // if request was aborted -> new request is in process ->
          // no need to change inProcess
          if (_controller.signal.aborted) {
            logger("Aborted");
          } else {
            dispatch(SET_CLIENT_EDIT_IN_PROCESS(false));
          }
        });
  }
}
function loadCardEditComments(clientId) {

  return (dispatch, getState) => {

    const inProcess = getState().cardEdit.inProcess;
    // if still in process - abort previous request
    if (inProcess) {
      controller.abort();
      // initialize new abort controller,
      // because it cannot be reset to normal state
      controller = new AbortController();
    }

    // closure to remember actual controller state
    const _controller = controller;

    dispatch(SET_CLIENT_EDIT_IN_PROCESS(true));

    return fetchPost(
      apiUrls.client.get.url,
      {
        clientId,
      },
      controller.signal
    )
      .then((response) => {
        if (response.success) {
          const cardData = response.data;
          const {photoList, localCommentList, globalCommentList} = cardData;
          if (getState().authentication.accountData.roles === 4) {
            dispatch(SET_CLIENT_EDIT_COMMENTS(localCommentList.concat(globalCommentList)));
          } else {
            dispatch(SET_CLIENT_EDIT_COMMENTS(localCommentList));
          }
          // dispatch(SET_CLIENT_EDIT_COMMENTS(localCommentList));
          // if (getState().authentication.accountData.roles === 4) {
          //   dispatch(SET_CLIENT_EDIT_COMMENTS(globalCommentList));
          // }
        } else {
          return Promise.reject(new Error("Card error"));
        }
      })
      .finally(() => {
        // if request was aborted -> new request is in process ->
        // no need to change inProcess
        if (_controller.signal.aborted) {
          logger("Aborted");
        } else {
          dispatch(SET_CLIENT_EDIT_IN_PROCESS(false));
        }
      });
  }
}

function loadPhoto(clientId) {

  return (dispatch, getState) => {

    const inProcess = getState().cardEdit.inProcess;
    // if still in process - abort previous request
    if (inProcess) {
      controller.abort();
      // initialize new abort controller,
      // because it cannot be reset to normal state
      controller = new AbortController();
    }

    // closure to remember actual controller state
    const _controller = controller;

    dispatch(SET_CLIENT_EDIT_IN_PROCESS(true));

    return fetchPost(
      apiUrls.client.get.url,
      {
        clientId,
      },
      controller.signal
    )
      .then((response) => {
        if (response.success) {

          const cardData = response.data;
          const {photoList, localCommentList} = cardData;

          // dispatch(loadClientEditPhotos(photoList.map(photo => photo.id)));
          dispatch(CLEAR_CLIENT_EDIT_PHOTO());
          dispatch(loadClientEditPhoto(photoList.map(photo => photo.id)));
        } else {
          return Promise.reject(new Error("Card error"));
        }
      })
      .finally(() => {
        // if request was aborted -> new request is in process ->
        // no need to change inProcess
        if (_controller.signal.aborted) {
          logger("Aborted");
        } else {
          dispatch(SET_CLIENT_EDIT_IN_PROCESS(false));
        }
      });
  }
}
/**
 * Validate inputs
 * @returns {function(*=, *): boolean}
 */
export function validateCardEditDataInputs() {

  return (dispatch, getState) => {

    let result = true;

    const clientEdit = getState().cardEdit;

    function validateInput(inputValue, validSetter) {
      let result = !isEmpty(inputValue);

      if (isFunc(validSetter)) validSetter(result);

      return result;
    }

    function validateDateInput(inputValue, validSetter) {
      let result = isValidDate(inputValue) || isEmpty(inputValue);

      if (isFunc(validSetter)) validSetter(result);

      return result;
    }

    clientEdit.personalDataList.forEach((personalData, personalDataIndex) => {
      result &= validateInput(
          personalData.name.value,
          (isValid) => dispatch(
              SET_CLIENT_EDIT_PERSONAL_FIELD_VALID(
                  "name",
                  personalDataIndex,
                  isValid
              )
          )
      );
      result &= validateInput(
          personalData.familyName.value,
          (isValid) => dispatch(
              SET_CLIENT_EDIT_PERSONAL_FIELD_VALID(
                  "familyName",
                  personalDataIndex,
                  isValid
              )
          )
      );
      // result &= validatePersonalDataInput(personalData.patronymic.value);
      result &= validateDateInput(
          personalData.dateOfBirth.value,
          (isValid) => dispatch(
              SET_CLIENT_EDIT_PERSONAL_FIELD_VALID(
                  "dateOfBirth",
                  personalDataIndex,
                  isValid
              )
          )
      );
      // result &= validatePersonalDataInput(personalData.kaloevId.value);

      personalData.documentDataList.forEach((documentData, documentDataIndex) => {
        // result &= validateInput(
        //     documentData.country.value,
        //     (isValid) => dispatch(
        //         SET_CLIENT_EDIT_DOCUMENT_FIELD_VALID(
        //             "country",
        //             personalDataIndex,
        //             documentDataIndex,
        //             isValid
        //         )
        //     )
        // );
        // result &= validateInput(documentData.type.value,);
        // result &= validateInput(documentData.number.value,);
        // result &= validateInput(documentData.issueDate.value,);
        // result &= validateInput(documentData.expireDate.value,);
        // result &= validateInput(documentData.personalId.value,);
      });
    });

    return Boolean(result);

  }
}

/**
 * Check if client with provided parameters already exists in system
 * @returns {Function}
 */
export function checkCardEditClientExistence(index) {

  return (dispatch, getState) => {

    const
        clientEditState = getState().cardEdit;

    const
        cardId = clientEditState.cardId,
        personalData = clientEditState.personalDataList[index];

    let parametersAreFilled =
        !isEmpty(personalData.name.value) &&
        !isEmpty(personalData.familyName.value) &&
        // !isEmpty(personalData.patronymic.value) &&
        !isEmpty(personalData.dateOfBirth.value);

    if (parametersAreFilled) {
      // dispatch(SET_CLIENT_EDIT_IN_PROCESS(true));

      let parameters = {
        cardId,
        personalData: {
          familyName: personalData.familyName.value.charAt(0) + personalData.familyName.value.slice(1).toLowerCase(),
          name: personalData.name.value.charAt(0) + personalData.name.value.slice(1).toLowerCase(),
          patronymic: personalData.patronymic.value,
          dateOfBirth: dateToString(
              personalData.dateOfBirth.value
          ),
        }
      };

      return fetchPost(
          apiUrls.client.checkExistence.url,
          parameters
      )
          .then(response => {
            if (response.success) {
              dispatch(
                  SET_CLIENT_EDIT_EXISTS(
                      index,
                      response.exists,
                      response.cardId
                  )
              );

              if (response.exists) {
                dispatch(SET_CLIENT_EDIT_SHOW_EXISTS_MODAL(index, true));
              }
            }
          })
          .finally(() => {
            // dispatch(SET_CLIENT_EDIT_IN_PROCESS(false));
          });

    } else {
      // return Promise.reject("");
    }
  }
}

export function saveCardEdit() {


  return (dispatch, getState) => {

    const {cardEdit} = getState();

    dispatch(SET_CLIENT_EDIT_IN_PROCESS(true));

    return fetchPost(
        apiUrls.client.update.url,
        {
          cardId: cardEdit.cardId,
          personalDataList: [
            ...cardEdit.personalDataList
                // .filter((personalData) => !personalData.receivedFromDevice)
                .map((personalData) => ({
                  id: personalData.id,
                  familyName: personalData.familyName.value.charAt(0) + personalData.familyName.value.slice(1).toLowerCase(),
                  name: personalData.name.value.charAt(0) + personalData.name.value.slice(1).toLowerCase(),
                  patronymic: personalData.patronymic.value,
                  dateOfBirth: dateToString(personalData.dateOfBirth.value),
                  kaloevId: personalData.kaloevId.value,

                  documentDataList: [
                    ...personalData.documentDataList
                        // .filter((documentData) => !documentData.receivedFromDevice)
                        .map((documentData) => ({
                          id: documentData.id,
                          country: documentData.country.value,
                          type: documentData.type.value,
                          number: documentData.number.value,
                          issueDate: dateToString(documentData.issueDate.value),
                          expireDate: dateToString(documentData.expireDate.value),
                          personalId: documentData.personalId.value,
                        })),
                  ],
                }))
          ],

          personalDataRemoveList: [
            ...cardEdit.personalDataRemoveList,
          ],
          documentDataRemoveList: [
            ...cardEdit.documentDataRemoveList,
          ],

        }
    )
        .then((response) => {

          if (response.success) {
            // dispatch(refreshCardEdit());
          } else {
            // throw new ResponseError(response.error);
          }

          return response;
        })
        .catch((e) => {
          if(e instanceof ResponseError) {
            debugger;
          }
        })
        .finally(() => {
          dispatch(SET_CLIENT_EDIT_IN_PROCESS(false));
        });
  }
}


/**
 * Load photos by provided id array
 * @param photoIdArray - array of photo ids
 * @returns {Function}
 */
function loadClientEditPhotos(photoIdArray = []) {
  return async (dispatch, getState) => {

    for (const photoId of photoIdArray) {

      const getPhotoResponse = await photoLoad(photoId);

      if (getPhotoResponse.success) {
        dispatch(ADD_CLIENT_EDIT_PHOTO(getPhotoResponse.photoData));
      }
    }

  }
}
function loadClientEditPhoto(photoIdArray ) {
  return async (dispatch, getState) => {

    for (const photoId of photoIdArray) {

      const getPhotoResponse = await photoLoad(photoId);

      if (getPhotoResponse.success) {
        dispatch(ADD_CLIENT_EDIT_PHOTO(getPhotoResponse.photoData));
      }
    }

  }
}

/**
 * Load photo by id
 * @param photoId - id of photo
 * @returns {{url, signal, method, params, isJson}}
 */
function photoLoad(photoId) {
  return fetchPost(
      apiUrls.photo.getPhoto.url,
      {
        photoId: photoId,
      }
  )
}

/**
 * Delete photo by id
 * @param photoId - id of photo
 * @returns {Function}
 */
export function photoDelete(photoId) {
  return (dispatch, getState) => {
    // dispatch(SET_CLIENT_EDIT_IN_PROCESS(true));

    fetchPost(
        apiUrls.photo.delete.url,
        {
          photoId,
        }
    )
        .then((response) => {
          if (response.success) {
            dispatch(refreshCardEdit());
          }
        })
        .finally(() => {
          // dispatch(SET_CLIENT_EDIT_IN_PROCESS(false));
        })
  }
}

/**
 * Upload and attach photo to a card
 * @param photo - photo
 * @param cardId - id of card
 * @returns {Function}
 */
export function photoUpload(photo, cardId) {
  return (dispatch, getState) => {
    // dispatch(SET_CLIENT_EDIT_IN_PROCESS(true));

    fetchPost(
        apiUrls.photo.upload.url,
        {
          photoData: {
            name: photo.name,
            data: photo.data,
          },

          cardId,
        }
    )
        .then((response) => {
          if (response.success) {
            dispatch(refreshPhotoCardEdit());
            // dispatch(refreshCardEdit());
          }
        })
        .finally(() => {
          // dispatch(SET_CLIENT_EDIT_IN_PROCESS(false));
        })

  }
}
