export function createEstablishmentRedux({
                                      id = -1,
                                      state = 0,
                                      deviceId = "",
                                      name = "",
                                      address = "",
                                    }) {
  return {
    id,
    state,

    deviceId: {
      value: deviceId,
      isValid: null,
    },
    name: {
      value: name,
      isValid: null,
    },
    address: {
      value: address,
      isValid: null,
    },
  };
}