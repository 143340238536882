import {ACTION_TYPE as COMMON_ACTION_TYPE} from "../actions/common";
import {ACTION_TYPE} from "../actions/cardMerge";


const initialState = {
  inProcess: false,
  showModal: false,
  error: {
    showError: false,
  },

  mergeFromData: null,
  mergeFromPhoto: null,
  mergeToData: null,
  mergeToPhoto: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPE.DROP_CLIENT_MERGE_DATA:
    case COMMON_ACTION_TYPE.CLEAR_STORE:
      return {
        ...initialState,
      };

    case ACTION_TYPE.SET_CARD_MERGE_IN_PROCESS: {
      return {
        ...state,
        inProcess: action.payload,
      }
    }

    case ACTION_TYPE.SET_CARD_MERGE_SHOW_ERROR: {
      return {
        ...state,
        error: {
          ...state.error,
          showError: action.payload,
        }
      }
    }

    case ACTION_TYPE.SET_CARD_MERGE_SHOW_MODAL: {
      return {
        ...state,
        showModal: action.payload,
      }
    }

    case ACTION_TYPE.SET_CARD_FROM_DATA: {
      return {
        ...state,
        mergeFromData: action.payload,
      };

    }

    case ACTION_TYPE.SET_CARD_FROM_PHOTO: {
      return {
        ...state,
        mergeFromPhoto: action.payload,
      };

    }

    case ACTION_TYPE.SET_CARD_TO_DATA: {
      return {
        ...state,
        mergeToData: action.payload,
      };

    }

    case ACTION_TYPE.SET_CARD_TO_PHOTO: {
      return {
        ...state,
        mergeToPhoto: action.payload,
      };

    }

    default: {
      return state;
    }
  }
}