import {fetchPost} from "../../utils/request";
import {apiUrls} from "../../constants/urls";
import {createAction} from "../../factory/redux/action";
import {logger} from "../../utils/logger";
import {dateToString} from "../../utils/date";

export const ACTION_TYPE = Object.freeze({
  SET_ARCHIVE_IN_PROCESS: "SET_ARCHIVE_IN_PROCESS",
  SET_ARCHIVE_LIST: "SET_ARCHIVE_LIST",

  // ADD_VISITOR: "ADD_VISITOR",
  // DELETE_VISITOR: "DELETE_VISITOR",
  // UPDATE_VISITOR: "UPDATE_VISITOR",
  // SET_VISITORS: "SET_VISITORS",

  TOGGLE_ARCHIVE_FILTER: "TOGGLE_VISITORS_FILTER",
  CLEAR_ARCHIVE_FILTER: "CLEAR_VISITORS_FILTER",

  SET_ARCHIVE_FILTER_VALUE: "SET_ARCHIVE_FILTER_VALUE",

  SET_ARCHIVE_PAGINATION_VALUE: "SET_ARCHIVE_PAGINATION_VALUE",

});




const SET_ARCHIVE_IN_PROCESS = (payload) => ({
  type: ACTION_TYPE.SET_ARCHIVE_IN_PROCESS,
  payload,
});

export const SET_ARCHIVE_LIST = (payload = []) => ({
  type: ACTION_TYPE.SET_ARCHIVE_LIST,
  payload,
});

// export const ADD_VISITOR = (payload) => ({
//   type: ACTION_TYPE.ADD_VISITOR,
//   payload,
// });
//
// export const UPDATE_VISITOR = (payload) => createAction(
//     ACTION_TYPE.UPDATE_VISITOR,
//     payload,
// );
//
// export const DELETE_VISITOR = (payload) => createAction(
//     ACTION_TYPE.DELETE_VISITOR,
//     payload,
// );




export const TOGGLE_ARCHIVE_FILTER = (payload) => ({
  type: ACTION_TYPE.TOGGLE_ARCHIVE_FILTER,
  payload,
});

export const CLEAR_ARCHIVE_FILTER = (payload) => ({
  type: ACTION_TYPE.CLEAR_ARCHIVE_FILTER,
  payload,
});

export const SET_ARCHIVE_FILTER_VALUE = (fieldName, value) => ({
  type: ACTION_TYPE.SET_ARCHIVE_FILTER_VALUE,
  payload: {
    name: fieldName,
    value,
  },
});

export const SET_ARCHIVE_PAGINATION_VALUE = (fieldName, value) => ({
  type: ACTION_TYPE.SET_ARCHIVE_PAGINATION_VALUE,
  payload: {
    name: fieldName,
    value,
  },
});

// setup AbortController
let controller = new AbortController();

export const loadVisitorsArchive = () => {

  return (dispatch, getState) => {
    // dispatch(SET_LOAD_VISITORS_IN_PROCESS(true));

    const
        filter = getState().visitorsArchive.filter,
        pagination = getState().visitorsArchive.pagination,
        inProcess = getState().visitorsArchive.inProcess;

    // if still in process - abort previous request
    if (inProcess) {
      controller.abort();
      // initialize new abort controller,
      // because it cannot be reset to normal state
      controller = new AbortController();
    }

    // closure to remember actual controller state
    const _controller = controller;

    dispatch(SET_ARCHIVE_IN_PROCESS(true));
    dispatch(SET_ARCHIVE_LIST([]));

    return fetchPost(
        apiUrls.visitor.getArchive.url,
        {
          // filter: {
          //     ...filter,
          //   rangeFrom: dateToString(filter.rangeFrom, null, true),
          //   rangeTo: dateToString(filter.rangeTo, null, true),
          // },
          ...pagination,
        },
        controller.signal
    )
        .then((response) => {
          if (response.success) {
            dispatch(SET_ARCHIVE_LIST(response.visitors));
            dispatch(SET_ARCHIVE_PAGINATION_VALUE("totalAmount", response.total));
          }
        })
        .finally(() => {
          if (_controller.signal.aborted) {
            logger("Aborted");
          } else {
            dispatch(SET_ARCHIVE_IN_PROCESS(false));
          }
        });
  };
};