import {ACTION_TYPE} from "../actions/notification";

function notificationMiddleware() {

  // chrome notifications object
  let
      chromeNotifications = null;

  // toolkit notifications
  let
    Notification = null;

  if("chrome" in window && window.chrome.notifications) {
    chromeNotifications = window.chrome.notifications;
  } else if ("Notification" in window) {
    Notification = window.Notification;

    if (
        Notification.permission !== "granted" &&
        Notification.permission !== "denied"
    ) {
      Notification.requestPermission();
    }

  }

    return (store) => (next) => (action) => {
      switch (action.type) {

        case ACTION_TYPE.SHOW_NOTIFICATION:

          if(chromeNotifications != null) {

            let
                notificationConfig = action.payload.config,
                title = action.payload.title,
                message = action.payload.message,
                actions = action.payload.actions;

            chromeNotifications.create(
                "TEST",
                {
                  type: "basic",
                  title,
                  message,
                  iconUrl: notificationConfig.icon,
                },
                // creationCallback
            );


          } else if (Notification != null) {
            if (Notification.permission === "granted") {

              let
                  notificationConfig = action.payload.config,
                  title = action.payload.title,
                  message = action.payload.message,
                  // actions require service worker configuration
                  actions = action.payload.actions;

              // If it's okay let's create a notification
              let notification = new Notification(
                  title,
                  {
                    ...notificationConfig,
                    // badge: notificationConfig.badge,
                    // tag: notificationConfig.tag,
                    // icon: notificationConfig.icon,
                    // image: notificationConfig.icon,
                    // renotify: notificationConfig.renotify,
                    // requireInteraction: notificationConfig.requireInteraction,

                    body: message,
                    actions,
                  }
              );
            }
          }



          break;
        default:
          return next(action);
      }
    }

}

export default notificationMiddleware();