import {ACTION_TYPE as COMMON_ACTION_TYPE} from "../actions/common";
import {ACTION_TYPE, SET_ESTABLISHMENT_FILTER_PHONE} from "../actions/establishment";
import {createEstablishmentRedux} from "../../factory/redux/establishment";

const initialState = {
  inProcess: false,
  currentEstablishment: null,
  list: [],
  establishment: createEstablishmentRedux({}),
  filter: {
    name: "",
    phoneNumber: "",
    state: 0,
  },
  showFilter: false,
  error: "",
  delete_modal: false,
  notify: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case COMMON_ACTION_TYPE.CLEAR_STORE:
    case ACTION_TYPE.CLEAR_ESTABLISHMENT:
      return {
        ...initialState,
      };
    case ACTION_TYPE.SET_ESTABLISHMENT_IN_PROCESS:
      return {
        ...state,
        inProcess: action.payload,
      };
    case ACTION_TYPE.SET_CURRENT_ESTABLISHMENT:
      return {
        ...state,
        currentEstablishment: action.payload,
      };
    case ACTION_TYPE.SET_ESTABLISHMENT:
      return {
        ...state,
        establishment: action.payload,
      };
    case ACTION_TYPE.SET_ESTABLISHMENT_LIST:
      return {
        ...state,
        list: action.payload,
      };
    case ACTION_TYPE.SET_ESTABLISHMENT_ID:
      return {
        ...state,
        establishment: {
          ...state.establishment,
          id: action.payload,
        },
      };
    case ACTION_TYPE.SET_ESTABLISHMENT_NAME:
      return {
        ...state,
        establishment: {
          ...state.establishment,
          name: {
            ...state.establishment.name,
            value: action.payload,
            isValid: null,
          },
        },
      };
    case ACTION_TYPE.SET_ESTABLISHMENT_NAME_IS_VALID:
      return {
        ...state,
        establishment: {
          ...state.establishment,
          name: {
            ...state.establishment.name,
            isValid: action.payload,
          },
        },
      };
    case ACTION_TYPE.SET_ESTABLISHMENT_DEVICE_ID:
      return {
        ...state,
        establishment: {
          ...state.establishment,
          deviceId: {
            ...state.establishment.deviceId,
            value: action.payload,
            isValid: null,
          }
        },
      };
    case ACTION_TYPE.SET_ESTABLISHMENT_DEVICE_ID_IS_VALID:
      return {
        ...state,
        establishment: {
          ...state.establishment,
          deviceId: {
            ...state.establishment.deviceId,
            isValid: action.payload,
          }
        },
      };
    case ACTION_TYPE.SET_ESTABLISHMENT_ADDRESS:
      return {
        ...state,
        establishment: {
          ...state.establishment,
          address: {
            ...state.establishment.address,
            value: action.payload,
            isValid: null,
          },
        },
      };
    case ACTION_TYPE.SET_ESTABLISHMENT_ADDRESS_IS_VALID:
      return {
        ...state,
        establishment: {
          ...state.establishment,
          address: {
            ...state.establishment.address,
            isValid: action.payload,
          },
        },
      };
    case ACTION_TYPE.SET_ESTABLISHMENT_STATE:
      return {
        ...state,
        establishment: {
          ...state.establishment,
          state: action.payload,
        },
      };
    case ACTION_TYPE.SET_ESTABLISHMENT_FILTER_STATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          state: action.payload
        },
      };
    case ACTION_TYPE.SET_ESTABLISHMENT_FILTER_NAME:
      return {
        ...state,
        filter: {
          ...state.filter,
          name: action.payload,
        },
      };
    case ACTION_TYPE.SET_ESTABLISHMENT_FILTER_PHONE:
      return {
        ...state,
        filter: {
          ...state.filter,
          phoneNumber: action.payload,
        },
      };
    case ACTION_TYPE.CLEAR_ESTABLISHMENT_FILTER:
      return {
        ...state,
        filter: {
          ...initialState.filter,
        },
      };
    case ACTION_TYPE.TOGGLE_ESTABLISHMENT_FILTER:
      return {
        ...state,
        showFilter: !state.showFilter,
      };
    case ACTION_TYPE.SET_ESTABLISHMENT_ERROR:
      return {
        ...state,
        error: action.payload
      };
    case ACTION_TYPE.DELETE_ESTABLISHMENT:
      return {
        ...state,
        delete_modal: action.payload
      };
    case ACTION_TYPE.SET_ESTABLISHMENT_NOTIFY:
      return {
        ...state,
        notify: action.payload,
      };
    default:
      return state;
  }
}
