import {formatDate} from "./date";

export function buildCardData(card, photos, partialMatch = false) {
  // put all clients in separate array
  let personalDataList = [...card.personalDataList];

  // extract first element, which will serve as main client data
  const mainClient = personalDataList.shift();

  let mainClientData = buildClientData(mainClient);

  let cardData = {
    id: card.id,
    dateOfBirth: card.dateOfBirth,
    dateOfBirthLocked: card.dateOfBirthLocked,
    partialMatch,
    main: mainClientData,
    additional: [],
    localComments: [...card.localCommentList],
    globalComments: [...card.globalCommentList],
    globalCommentsTimestamp: card.globalCommentsTimestamp,
  };

  personalDataList.forEach(
      (client) =>
          cardData.additional.push(buildClientData(client))
  );

  return cardData;
}

function buildClientData(client) {

  let documentDataList = [];
  documentDataList.push(...client.documentDataList);


  const clientData = {
    personalData: {
      id: client.id,
      familyName: client.familyName,
      name: client.name,
      patronymic: client.patronymic,
      dateOfBirth: client.dateOfBirth,
      kaloevId: client.kaloevId,
      receivedFromDevice: client.receivedFromDevice,
    },
    documentDataList: documentDataList,
  };

  return clientData;
}