import {fetchGet} from "../../utils/request";
import {apiUrls} from "../../constants/urls";

export const ACTION_TYPE = Object.freeze({
  SET_OPTIONS: "SET_OPTIONS",
  SET_OPTIONS_IN_PROCESS: "SET_OPTIONS_IN_PROCESS",
});

export const SET_OPTIONS = (payload) => ({
  type: ACTION_TYPE.SET_OPTIONS,
  payload
});

const SET_OPTIONS_IN_PROCESS = (payload) => ({
  type: ACTION_TYPE.SET_OPTIONS_IN_PROCESS,
  payload
});

export function loadOptions() {

  return function (dispatch, getState) {

    dispatch(SET_OPTIONS_IN_PROCESS(true));

    return fetchGet(apiUrls.options.getAll.url)
        .then((response) => {
          if (response.success) {

            dispatch(SET_OPTIONS(response.data));

          }
        })
        .finally(() => {
          dispatch(SET_OPTIONS_IN_PROCESS(false));
        });
  }
}