import React from "react";
import {ACTION_TYPE as COMMON_ACTION_TYPE} from "../actions/common";
import {ACTION_TYPE} from "../actions/users";


const initialState = {
  inProcess: false,
  filter: {
    userRole: [],
    establishment: 0,
    userName: "",
  },
  showFilter: false,

  list: [
    // {
    //   name: "",
    //   familyName: "",
    //   // ...
    // }
  ],
  pagination: {
    totalAmount: 0,
    page: 0,
    amountOnPage: 10,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case COMMON_ACTION_TYPE.CLEAR_STORE:
      return {
        ...initialState,
      };
    case ACTION_TYPE.ADD_USER:
      return {
        ...state,
        list: [
          action.payload,
          ...state.list,
        ],
      };
    case ACTION_TYPE.SET_LOAD_USERS_IN_PROCESS:
      return {
        ...state,
        inProcess: action.payload,
      };
    case ACTION_TYPE.SET_USERS:
      return {
        ...state,
        list: action.payload,
      };
    case ACTION_TYPE.CLEAR_FILTER:
      return {
        ...state,
        filter: {
          ...initialState.filter,
        },
      };
    case ACTION_TYPE.TOGGLE_USERS_FILTER:
      return {
        ...state,
        showFilter: !state.showFilter,
      };
    case ACTION_TYPE.SET_USER_ROLE_FILTER:
      let inArray = state.filter.userRole.indexOf(action.payload) > -1;

      let role;
      if (inArray) {
        role = state.filter.userRole
            .filter(value => value !== action.payload)
      } else {
        role = [...state.filter.userRole, action.payload];
      }

      return {
        ...state,
        filter: {
          ...state.filter,
          userRole: role,
        },
      };
    case ACTION_TYPE.SET_USER_NAME_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          userName: action.payload || "",
        },
      };
    case ACTION_TYPE.SET_USER_ESTABLISHMENT_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          establishment: action.payload,
        },
      };
    default:
      return state;
  }
}
