import {parseDate} from "../../utils/date";


export const createDocumentData = ({
                                     id = null,
                                     country = "",
                                     countryIsValid = null,
                                     type = "",
                                     typeIsValid = null,
                                     number = "",
                                     numberIsValid = null,
                                     issueDate = null,
                                     issueDateIsValid = null,
                                     expireDate = null,
                                     expireDateIsValid = null,
                                     personalId = "",
                                     personalIdIsValid = null,
                                     receivedFromDevice = false,
                                     ...other
                                   }) => ({
  id,
  country: {
    value: country,
    isValid: countryIsValid,
    errorMessage: "",
  },
  type: {
    value: type,
    isValid: typeIsValid,
    errorMessage: "",
  },
  number: {
    value: number,
    isValid: numberIsValid,
    errorMessage: "",
  },
  issueDate: {
    value: parseDate(issueDate),
    isValid: issueDateIsValid,
    errorMessage: "",
  },
  expireDate: {
    value: parseDate(expireDate),
    isValid: expireDateIsValid,
    errorMessage: "",
  },
  personalId: {
    value: personalId,
    isValid: personalIdIsValid,
    errorMessage: "",
  },
  receivedFromDevice,

  // original values
  original: {
    id,
    country,
    countryIsValid,
    type,
    typeIsValid,
    number,
    numberIsValid,
    issueDate,
    issueDateIsValid,
    expireDate,
    expireDateIsValid,
    personalId,
    personalIdIsValid,
    receivedFromDevice,
    ...other,
  },
});

