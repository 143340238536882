export const createComment = ({
                                id = null,
                                cardId = null,
                                type = "",
                                typeIsValid = null,
                                text = "",
                                textIsValid = null,
                                isValid = null,
                                ...other
                              }) => ({
  id,
  cardId,
  type: {
    value: type,
    isValid: typeIsValid
  },
  text: {
    value: text,
    isValid: textIsValid
  },
  isValid,

  // original values
  original: {
    id,
    cardId,
    type,
    typeIsValid,
    text,
    textIsValid,
    isValid,
    ...other
  },
});