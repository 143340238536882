import React from 'react';
import {Redirect, Route as RouteOriginal} from 'react-router-dom';
import PropTypes from 'prop-types'
import {checkAccessRights} from "../../utils/authentication";
import {appUrls} from "../../constants/urls";
import {useSelector} from "react-redux";

export function Route({
                        component: Component,
                        exact,
                        path,
                        computedMatch,
                        roles,
                        ...props
                      }) {
  const authentication = useSelector((state) => state.authentication);


  if(checkAccessRights(authentication.accountData.roles, roles)) {
      return (
        <RouteOriginal
            exact={exact}
            path={path}
            render={props => {
              return (
                  <Component {...props} />
              );
            }}
        />

    );
  } else {
    if(!authentication.isAuthenticated){
      return <Redirect to={appUrls.authentication.url + "?redirectUrl=" + computedMatch.url}/>;
    } else {
      return <Redirect to={appUrls.unauthorized.url}/>;
    }
  }
}
Route.propTypes = {
  component: PropTypes.func.isRequired,
  exact: PropTypes.bool,

  path: PropTypes.string,
  roles: PropTypes.array.isRequired,

};