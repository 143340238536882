import {fetchGet, fetchPost} from "../../utils/request";
import {apiUrls} from "../../constants/urls";
import {createAction} from "../../factory/redux/action";
import {createEstablishmentRedux} from "../../factory/redux/establishment";
import {isEmpty, isEmptyOrWhitespace} from "../../utils/string";
import {isFunc} from "../../utils/function";

export const ACTION_TYPE = Object.freeze({
  CLEAR_ESTABLISHMENT: "CLEAR_ESTABLISHMENT",
  SET_ESTABLISHMENT_IN_PROCESS: "SET_ESTABLISHMENT_IN_PROCESS",
  SET_CURRENT_ESTABLISHMENT: "SET_CURRENT_ESTABLISHMENT",
  SET_ESTABLISHMENT_LIST: "SET_ESTABLISHMENT_LIST",

  SET_ESTABLISHMENT_ID: "SET_ESTABLISHMENT_ID",
  SET_ESTABLISHMENT_NAME: "SET_ESTABLISHMENT_NAME",
  SET_ESTABLISHMENT_NAME_IS_VALID: "SET_ESTABLISHMENT_NAME_IS_VALID",
  SET_ESTABLISHMENT_DEVICE_ID: "SET_ESTABLISHMENT_DEVICE_ID",
  SET_ESTABLISHMENT_DEVICE_ID_IS_VALID: "SET_ESTABLISHMENT_DEVICE_ID_IS_VALID",
  SET_ESTABLISHMENT_ADDRESS: "SET_ESTABLISHMENT_ADDRESS",
  SET_ESTABLISHMENT_ADDRESS_IS_VALID: "SET_ESTABLISHMENT_ADDRESS_IS_VALID",
  SET_ESTABLISHMENT_STATE: "SET_ESTABLISHMENT_STATE",
  DELETE_ESTABLISHMENT: "DELETE_ESTABLISHMENT",
  SET_ESTABLISHMENT: "SET_ESTABLISHMENT",
  SET_ESTABLISHMENT_ERROR: "SET_ESTABLISHMENT_ERROR",
  SET_ESTABLISHMENT_NOTIFY: "SET_ESTABLISHMENT_NOTIFY",

  SET_ESTABLISHMENT_FILTER_STATE: "SET_ESTABLISHMENT_FILTER_STATE",
  SET_ESTABLISHMENT_FILTER_NAME: "SET_ESTABLISHMENT_FILTER_NAME",
  SET_ESTABLISHMENT_FILTER_PHONE: "SET_ESTABLISHMENT_FILTER_PHONE",
  CLEAR_ESTABLISHMENT_FILTER: "CLEAR_ESTABLISHMENT_FILTER",
  TOGGLE_ESTABLISHMENT_FILTER: "TOGGLE_ESTABLISHMENT_FILTER",
});

export const SET_ESTABLISHMENT_IN_PROCESS = (payload) => ({
  type: ACTION_TYPE.SET_ESTABLISHMENT_IN_PROCESS,
  payload
});

export const CLEAR_ESTABLISHMENT = () => ({
  type: ACTION_TYPE.CLEAR_ESTABLISHMENT
});

export const SET_ESTABLISHMENT_LIST = (payload) => ({
  type: ACTION_TYPE.SET_ESTABLISHMENT_LIST,
  payload
});

export const SET_ESTABLISHMENT_FILTER_STATE = (payload) => ({
  type: ACTION_TYPE.SET_ESTABLISHMENT_FILTER_STATE,
  payload
});

export const SET_ESTABLISHMENT_FILTER_NAME = (payload) => ({
  type: ACTION_TYPE.SET_ESTABLISHMENT_FILTER_NAME,
  payload
});

export const SET_ESTABLISHMENT_FILTER_PHONE = (payload) => ({
  type: ACTION_TYPE.SET_ESTABLISHMENT_FILTER_PHONE,
  payload
});

export const CLEAR_ESTABLISHMENT_FILTER = (payload) => ({
  type: ACTION_TYPE.CLEAR_ESTABLISHMENT_FILTER,
  payload
});

export const TOGGLE_ESTABLISHMENT_FILTER = (payload) => createAction(
    ACTION_TYPE.TOGGLE_ESTABLISHMENT_FILTER,
    null
);

export const SET_ESTABLISHMENT = (payload) => ({
  type: ACTION_TYPE.SET_ESTABLISHMENT,
  payload
});

export const SET_ESTABLISHMENT_ID = (payload) => ({
  type: ACTION_TYPE.SET_ESTABLISHMENT_ID,
  payload
});

export const SET_ESTABLISHMENT_NAME = (payload) => ({
  type: ACTION_TYPE.SET_ESTABLISHMENT_NAME,
  payload
});

export const SET_ESTABLISHMENT_NAME_IS_VALID = (payload) => ({
  type: ACTION_TYPE.SET_ESTABLISHMENT_NAME_IS_VALID,
  payload
});

export const SET_ESTABLISHMENT_DEVICE_ID = (payload) => ({
  type: ACTION_TYPE.SET_ESTABLISHMENT_DEVICE_ID,
  payload
});

export const SET_ESTABLISHMENT_DEVICE_ID_IS_VALID = (payload) => ({
  type: ACTION_TYPE.SET_ESTABLISHMENT_DEVICE_ID_IS_VALID,
  payload
});

export const SET_ESTABLISHMENT_ADDRESS = (payload) => ({
  type: ACTION_TYPE.SET_ESTABLISHMENT_ADDRESS,
  payload
});

export const SET_ESTABLISHMENT_STATE = (payload) => ({
  type: ACTION_TYPE.SET_ESTABLISHMENT_STATE,
  payload
});

export const SET_ESTABLISHMENT_ERROR = (payload) => ({
  type: ACTION_TYPE.SET_ESTABLISHMENT_ERROR,
  payload,
})

export const DELETE_ESTABLISHMENT = (payload) => ({
  type: ACTION_TYPE.DELETE_ESTABLISHMENT,
  payload
})

export const SET_ESTABLISHMENT_NOTIFY = (payload) => ({
  type: ACTION_TYPE.SET_ESTABLISHMENT_NOTIFY,
  payload
})


export const loadEstablishments = () => {
  return (dispatch, getState) => {
    dispatch(SET_ESTABLISHMENT_IN_PROCESS(true));

    return fetchGet(apiUrls.establishment.getAll.url)
        .then(response => {
          if (response.success) {

            dispatch(SET_ESTABLISHMENT_LIST(response.establishmentList));

          }
        })
        .finally(() => {
          dispatch(SET_ESTABLISHMENT_IN_PROCESS(false));
        });
  }
};

export const loadEstablishment = (establishmentId) => {
  return (dispatch, getState) => {
    dispatch(SET_ESTABLISHMENT_IN_PROCESS(true));
    let parameters = {
      establishmentId: establishmentId,
    };
    return fetchPost(apiUrls.establishment.get.url, parameters)
        .then(response => {
          if (response.success) {
            dispatch(SET_ESTABLISHMENT(
                createEstablishmentRedux(response.establishment)
            ));
          }

          return response;
        })
        .finally(() => {
          dispatch(SET_ESTABLISHMENT_IN_PROCESS(false));
        });
  }
};

export function validateEstablishmentFields() {
  return function (dispatch, getState) {

    function validateInput(inputValue, validSetter) {
      let result = !isEmptyOrWhitespace(inputValue);

      if (isFunc(validSetter)) validSetter(result);

      return result;
    }

    let
        establishment = getState().establishment.establishment,
        isValid = true;

    // isValid &= validateInput(
    //     establishment.deviceId.value,
    //     (isValid) => dispatch(SET_ESTABLISHMENT_DEVICE_ID_IS_VALID(isValid))
    // );

    isValid &= validateInput(
        establishment.name.value,
        (isValid) => dispatch(SET_ESTABLISHMENT_NAME_IS_VALID(isValid))
    );

    return Boolean(isValid);
  }
}

export const createEstablishment = () => {
  return (dispatch, getState) => {
    dispatch(SET_ESTABLISHMENT_IN_PROCESS(true));

    let establishment = getState().establishment.establishment;

    let parameters = {
      establishmentId: establishment.id,
      name: establishment.name.value,
      address: establishment.address.value,
      deviceId: establishment.deviceId.value ?? '',
      state: establishment.state,
    };
    return fetchPost(apiUrls.establishment.add.url, parameters)
        .then(response => {
          // if (response.success) {
          //   dispatch(SET_ESTABLISHMENT_ID(response.establishmenttData.number));
          // }

          return response;
        })
        .finally(() => {
          dispatch(SET_ESTABLISHMENT_IN_PROCESS(false));
        });

  }
};

export const updateEstablishment = () => {
  return (dispatch, getState) => {
    dispatch(SET_ESTABLISHMENT_IN_PROCESS(true));
    let establishment = getState().establishment;

    let parameters = {
      establishmentId: establishment.establishment.id,
      name: establishment.establishment.name.value,
      address: establishment.establishment.address.value,
      deviceId: establishment.establishment.deviceId.value,
      state: establishment.establishment.state,
    };

    return fetchPost(apiUrls.establishment.update.url, parameters)
        .then(response => {
          if (response.success) {
            dispatch(SET_ESTABLISHMENT(
                createEstablishmentRedux(response.establishmentData)
            ));
          }

          return response;
        })
        .finally(() => {
          dispatch(SET_ESTABLISHMENT_IN_PROCESS(false));
        });
  }
};

export const deleteEstablishment = () => {
  return (dispatch, getState) => {
    dispatch(SET_ESTABLISHMENT_IN_PROCESS(true));
    let establishment = getState().establishment;

    let parameters = {
      establishmentId: establishment.establishment.id,
    };
    return fetchPost(apiUrls.establishment.delete.url, parameters)
        .then(response => {
          if (response.success) {
            dispatch(DELETE_ESTABLISHMENT(false));
          }
          return response;
        })
        .finally(() => {
          dispatch(SET_ESTABLISHMENT_IN_PROCESS(false));
        });
  }
};
