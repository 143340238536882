import {
  ACTION_TYPE,
  processMessage,
  WS_CONNECT,
  WS_CONNECTED,
  WS_DISCONNECTED,
  WS_ERROR
} from "../actions/webSocket";
import SockJS from 'sockjs-client'
import Stomp from 'stompjs'
import {IS_DEBUG} from "../../constants/debug";
import {DEVELOPMENT_SERVER_URL, websocketUrls} from "../../constants/urls";
import {createHeaders, createMessage} from "../../factory/websocket";
import {logger} from "../../utils/logger";
import {useSelector} from "react-redux";


function webSocketMiddleware() {

  let
    socket = null,
    client = null,

    timeout = null,
    allowReconnect = false;

  function _onOpen(store) {
    return function (event) {

      store.dispatch(WS_CONNECTED(event.target.url));


    }
  }


  function _onClose(store) {
    return function () {
      store.dispatch(WS_DISCONNECTED());

      // clear reconnect timeout
      if (timeout != null) {
        clearTimeout(timeout);
        timeout = null;
      }
    }

  }

  function _onError(store) {
    return function (error) {

      store.dispatch(WS_ERROR());


    }

  }

  const onMessage = (store, message) => {
    store.dispatch(processMessage(message));
  };

// the middleware part of this function
  return store => next => action => {
    switch (action.type) {
      case ACTION_TYPE.WS_CONNECT:

        allowReconnect = true;

        if (socket !== null) {
          socket.close();
        }

        let serverUrl = (IS_DEBUG ? DEVELOPMENT_SERVER_URL : "");

        socket = new SockJS(serverUrl + websocketUrls.location.url);
        socket
          .onclose = _onClose(store);
        socket
          .onopen = _onOpen(store);
        socket
          .onerror = _onError(store);

        client = Stomp.over(socket);

        client.connect(
          {},
          (frame) => {

            store.dispatch(WS_CONNECTED());

            // subscribe to channels
            client.subscribe(
              websocketUrls.notifications.card.url,
              (message) => {
                onMessage(store, JSON.parse(message.body));
              }
            );
            const establishmentId = store.getState().authentication.accountData.establishment;//useSelector(state => state.authentication.accountData.establishment)

            client.subscribe(
              websocketUrls.notifications.visitor.url + establishmentId,
              (message) => {
                onMessage(store, JSON.parse(message.body));
              }
            )

            // client.subscribe(
            //   websocketUrls.channel.license.warning.url,
            //   (message) => {
            //     onMessage(store, JSON.parse(message.body));
            //   }
            // );
            //
            // client.subscribe(
            //   websocketUrls.channel.card.created.url,
            //   (message) => {
            //     onMessage(store, JSON.parse(message.body));
            //   }
            // );
            //
            // client.subscribe(
            //   websocketUrls.channel.card.deleted.url,
            //   (message) => {
            //     onMessage(store, JSON.parse(message.body));
            //   }
            // );
            //
            // client.subscribe(
            //   websocketUrls.channel.card.updated.url,
            //   (message) => {
            //     onMessage(store, JSON.parse(message.body));
            //   }
            // );
            //
            // client.subscribe(
            //   websocketUrls.channel.options.updated.url,
            //   (message) => {
            //     onMessage(store, JSON.parse(message.body));
            //   }
            // );
            //
            // client.subscribe(
            //   websocketUrls.channel.visitor.created.url,
            //   (message) => {
            //     onMessage(store, JSON.parse(message.body));
            //   }
            // );
            //
            // client.subscribe(
            //   websocketUrls.channel.visitor.updated.url,
            //   (message) => {
            //     onMessage(store, JSON.parse(message.body));
            //   }
            // );
            //
            // client.subscribe(
            //   websocketUrls.channel.visitor.deleted.url,
            //   (message) => {
            //     onMessage(store, JSON.parse(message.body));
            //   }
            // );

          },
          _onError(store),
        );

        break;

      case ACTION_TYPE.WS_CONNECTED: {

        break;
      }

      case ACTION_TYPE.WS_ERROR: {

        if (allowReconnect) {
          timeout = setTimeout(() => {
            store.dispatch(WS_CONNECT());
          }, 5000);
        }

        break;
      }

      case ACTION_TYPE.WS_DISCONNECT:
        allowReconnect = false;
        // clear reconnect timeout
        if (timeout != null) {
          clearTimeout(timeout);
          timeout = null;
        }
        // disconnect client
        if (client !== null && client.connected) {
          client.disconnect();
        }
        client = null;
        if (socket !== null) {
          socket.close();
        }
        logger('websocket closed');
        break;

      case ACTION_TYPE.WS_SEND_MESSAGE:
        logger('sending a message', action);

        client.send(
          websocketUrls.cts.url,
          createHeaders(),
          createMessage()
        );
        break;

      default:
        return next(action);
    }
  };
}

export default webSocketMiddleware();