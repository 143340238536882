import {createAction} from "../../factory/redux/action";

export const ACTION_TYPE = Object.freeze({
  SET_SHOW_LICENCE_MODAL: "SET_SHOW_LICENCE_MODAL",
  SET_SHOW_LICENCE_WILL_TERMINATE: "SET_SHOW_LICENCE_WILL_TERMINATE",
});


export const SET_SHOW_LICENCE_MODAL = (showModal) => createAction(
    ACTION_TYPE.SET_SHOW_LICENCE_MODAL,
    showModal,
);


export const SET_SHOW_LICENCE_WILL_TERMINATE = (willTerminate) => createAction(
    ACTION_TYPE.SET_SHOW_LICENCE_WILL_TERMINATE,
    willTerminate,
);