import {fetchGet, fetchPost} from "../../utils/request";
import {apiUrls} from "../../constants/urls";
import {createAction} from "../../factory/redux/action";
import {isFunc} from "../../utils/function";
import {isEmpty} from "../../utils/string";
import {dateToString} from "../../utils/date";
import {loadOptions} from "./options";

export const ACTION_TYPE = Object.freeze({
  CLEAR_SERVER_INFO: "CLEAR_SERVER_INFO",
  SET_SERVER_INFO: "SET_SERVER_INFO",
  SET_SERVER_INFO_IN_PROCESS: "SET_SERVER_INFO_IN_PROCESS",
  SET_SERVER_INFO_FIELD_VALUE: "SET_SERVER_INFO_FIELD_VALUE",
  SET_SERVER_INFO_FIELD: "SET_SERVER_INFO_FIELD",
  SET_SERVER_INFO_FIELD_IS_VALID: "SET_SERVER_INFO_FIELD_IS_VALID",
});

export const CLEAR_SERVER_INFO = (payload) => createAction(
    ACTION_TYPE.CLEAR_SERVER_INFO,
    payload
);

export const SET_SERVER_INFO = (payload) => createAction(
    ACTION_TYPE.SET_SERVER_INFO,
    payload
);

export const SET_SERVER_INFO_IN_PROCESS = (payload) => createAction(
    ACTION_TYPE.SET_SERVER_INFO_IN_PROCESS,
    payload
);

export const SET_SERVER_INFO_FIELD_VALUE = (value, field) => createAction(
    ACTION_TYPE.SET_SERVER_INFO_FIELD_VALUE,
    {
      value,
      field,
    }
);

export const SET_SERVER_INFO_FIELD_IS_VALID = (isValid, field) => createAction(
    ACTION_TYPE.SET_SERVER_INFO_FIELD_IS_VALID,
    {
      isValid,
      field,
    }
);



export function loadServerInfo() {
  return function (dispatch, getState) {

    dispatch(SET_SERVER_INFO_IN_PROCESS(true));

    return fetchGet(apiUrls.options.getServerInfo.url)
        .then((response) => {

          if(response.success) {

            dispatch(SET_SERVER_INFO(response));
            dispatch(loadOptions());

          }

        })
        .finally(() => {
          dispatch(SET_SERVER_INFO_IN_PROCESS(false));
        });

  }
}

export function validateServerInfoFields() {

  return function(dispatch, getState) {

    function validateInput(inputValue, validSetter) {
      let result = !isEmpty(inputValue);

      if (isFunc(validSetter)) validSetter(result);

      return result;
    }

    const serverInfoData = getState().serverInfo.data;

    let isValid = true;

    isValid &= validateInput(
        serverInfoData.organizationName.value,
        (isValid) => dispatch(
            SET_SERVER_INFO_FIELD_IS_VALID(isValid, "organizationName")
        )
    );

    isValid &= validateInput(
        serverInfoData.organizationAddress.value,
        (isValid) => dispatch(
            SET_SERVER_INFO_FIELD_IS_VALID(isValid, "organizationAddress")
        )
    );

    return Boolean(isValid);

  }
}

export function updateServerInfo() {

  return function (dispatch, getState) {

    dispatch(SET_SERVER_INFO_IN_PROCESS(true));

    const serverInfoData = getState().serverInfo.data;

    let parameters = {
      organizationName: serverInfoData.organizationName.value,
      showNameToOthers: serverInfoData.showNameToOthers.value,
      organizationAddress: serverInfoData.organizationAddress.value,
    };

    return fetchPost(
        apiUrls.options.updateServerInfo.url,
        parameters,
    )
        .then((response) => {
          if(response.success) {
            dispatch(loadServerInfo());
          }

          return response;
        })
        .finally(()=> {
          dispatch(SET_SERVER_INFO_IN_PROCESS(false));
        })

  }
}