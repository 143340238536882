export const MESSAGE_TYPES = Object.freeze({
  CLIENT_CREATED: "CLIENT_CREATED",
  CLIENT_DELETED: "CLIENT_DELETED",
  CLIENT_UPDATED: "CLIENT_UPDATED",

  LICENCE_WARNING: "LICENCE_WARNING",

  OPTIONS_UPDATED: "OPTIONS_UPDATED",

  VISITOR_CREATED: "VISITOR_CREATED",
  VISITOR_UPDATED: "VISITOR_UPDATED",
  VISITOR_DELETED: "VISITOR_DELETED",

  NEW_CARD_CREATED: "NEW_CARD_CREATED",
  NEW_VISITOR_CREATED: "NEW_VISITOR_CREATED",
});