import Owl2 from "./image/Owl2.ico";
import TB from "./image/TB.svg";
import BL from "./image/BL.svg";
import CRITICAL_21 from "./image/CRITICAL_21.svg";
import CRITICAL_DOC from "./image/CRITICAL_DOC.svg";
import WARNING_RED from "./image/WARNING_RED.svg";
import WARNING_YELLOW from "./image/WARNING_YELLOW.svg";
import WARNING_GRAY from "./image/WARNING_GRAY.svg";
import WARNING_PINK from "./image/WARNING_PINK.svg";


export const NOTIFICATION_CONFIG = Object.freeze({
  // red with tax ban (TB)
  TAX_BAN: {
    // badge - image used to represent the notification when there isn't enough
    // space to display the notification itself
    badge: "/Owl2.ico",
    // type of notification for internal use
    type: "TAX_BAN",
    // icon location
    icon: TB,
    // representing an identifying tag for the notification
    tag: "tax-ban",
    // renotify - used to alert the user when a similar notification arrives
    renotify: true,
    // Indicates that a notification should remain active until the user clicks
    // or dismisses it, rather than closing automatically
    requireInteraction: true,
  },
  // red with BL
  BLACKLIST: {
    // badge - image used to represent the notification when there isn't enough
    // space to display the notification itself
    badge: Owl2,
    // type of notification for internal use
    type: "BLACKLIST",
    // icon location
    icon: BL,
    // representing an identifying tag for the notification
    tag: "blacklist",
    // renotify - used to alert the user when a similar notification arrives
    renotify: true,
    // Indicates that a notification should remain active until the user clicks
    // or dismisses it, rather than closing automatically
    requireInteraction: true,
  },
  // red with 21
  CRITICAL_21: {
    badge: Owl2,
    type: "CRITICAL_21",
    icon: CRITICAL_21,
    tag: "21",
    renotify: true,
    requireInteraction: true,
  },
  // red with doc
  CRITICAL_DOC: {
    badge: Owl2,
    type: "CRITICAL_DOC",
    icon: CRITICAL_DOC,
    tag: "doc",
    renotify: true,
    requireInteraction: true,
  },
  // red with !
  WARNING_RED: {
    badge: Owl2,
    type: "WARNING_RED",
    icon: WARNING_RED,
    tag: "Warning_red",
    renotify: true,
    requireInteraction: true,
  },
  // yellow with !
  WARNING_YELLOW: {
    badge: Owl2,
    type: "WARNING_YELLOW",
    icon: WARNING_YELLOW,
    tag: "warning_yellow",
    renotify: true,
    requireInteraction: true,
  },
  // greay with !
  WARNING_GRAY: {
    badge: Owl2,
    type: "WARNING_GRAY",
    icon: WARNING_GRAY,
    tag: "warning_grey",
    renotify: true,
  },
  // pink with !
  WARNING_PINK: {
    badge: Owl2,
    type: "WARNING_PINK",
    icon: WARNING_PINK,
    tag: "warning_pink",
    renotify: true,
  }
});

