/**
 * format integer number to a specific length
 * e.x. "1" to "001"
 * @param number - number to format
 * @param minLength - minimum length of required result
 */
export function formatInt(number, minLength=1) {
  let s = "" + number;

  while (s.length < minLength) s = "0" + s;

  return s;
}