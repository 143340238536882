import {fetchPost} from "../../utils/request";
import {apiUrls} from "../../constants/urls";
import {createUserRedux} from "../../factory/redux/user";
import {isEmptyOrWhitespace} from "../../utils/string";
import {isFunc} from "../../utils/function";

export const ACTION_TYPE = Object.freeze({
  SET_USER_ID: "SET_USER_ID",
  SET_USER_FAMILY: "SET_USER_FAMILY",
  SET_USER_NAME: "SET_USER_NAME",
  SET_USER_PHONE: "SET_USER_PHONE",
  SET_USER_LOGIN: "SET_USER_LOGIN",
  SET_USER_LOGIN_IS_VALID: "SET_USER_LOGIN_IS_VALID",
  SET_USER_PASS: "SET_USER_PASS",
  SET_USER_PASS_IS_VALID: "SET_USER_PASS_IS_VALID",
  SET_USER_PASS_CONFIRM: "SET_USER_PASS_CONFIRM",
  SET_USER_PASS_CONFIRM_IS_VALID: "SET_USER_PASS_CONFIRM_IS_VALID",
  SET_USER_ROLES: "SET_USER_ROLES",
  SET_USER_ESTABLISHMENT: "SET_USER_ESTABLISHMENT",
  SET_USER_ESTABLISHMENT_IS_VALID: "SET_USER_ESTABLISHMENT_IS_VALID",
  SET_USER_STATE: "SET_USER_STATE",
  UNSET_USER_ESTABLISHMENT: "UNSET_USER_ESTABLISHMENT",
  SET_USER_ERROR: "SET_USER_ERROR",
  DELETE_USER: "DELETE_USER",
  SET_USER_NOTIFY: "SET_USER_NOTIFY",
  SET_USER_DELETED: "SET_USER_DELETED",

  DROP_USER_DATA: "DROP_USER_DATA",
  SET_USER_DATA: "SET_USER_DATA",
  SET_USER_DATA_IN_PROCESS: "SET_USER_DATA_IN_PROCESS",
  SET_USER_SAVED: "SET_USER_SAVED",
  OPEN_RESET_MODAL: "OPEN_RESET_MODAL",
});

export const SET_USER_ID = (value) => ({
  type: ACTION_TYPE.SET_USER_ID,
  payload: value
});

export const SET_USER_FAMILY = (value) => ({
  type: ACTION_TYPE.SET_USER_FAMILY,
  payload: value
});

export const SET_USER_NAME = (value) => ({
  type: ACTION_TYPE.SET_USER_NAME,
  payload: value
});

export const SET_USER_PHONE = (value) => ({
  type: ACTION_TYPE.SET_USER_PHONE,
  payload: value
});

export const SET_USER_LOGIN = (value) => ({
  type: ACTION_TYPE.SET_USER_LOGIN,
  payload: value
});

export const SET_USER_LOGIN_IS_VALID = (value) => ({
  type: ACTION_TYPE.SET_USER_LOGIN_IS_VALID,
  payload: value
});

export const SET_USER_PASS = (value) => ({
  type: ACTION_TYPE.SET_USER_PASS,
  payload: value
});

export const SET_USER_PASS_IS_VALID = (value) => ({
  type: ACTION_TYPE.SET_USER_PASS_IS_VALID,
  payload: value
});

export const SET_USER_PASS_CONFIRM = (value) => ({
  type: ACTION_TYPE.SET_USER_PASS_CONFIRM,
  payload: value
});

export const SET_USER_PASS_CONFIRM_IS_VALID = (value) => ({
  type: ACTION_TYPE.SET_USER_PASS_CONFIRM_IS_VALID,
  payload: value
});

export const SET_USER_ROLES = (value) => ({
  type: ACTION_TYPE.SET_USER_ROLES,
  payload: value
});

export const SET_USER_ESTABLISHMENT = (value) => ({
  type: ACTION_TYPE.SET_USER_ESTABLISHMENT,
  payload: value
});

export const SET_USER_ESTABLISHMENT_IS_VALID = (value) => ({
  type: ACTION_TYPE.SET_USER_ESTABLISHMENT_IS_VALID,
  payload: value
});

export const UNSET_USER_ESTABLISHMENT = (value) => ({
  type: ACTION_TYPE.UNSET_USER_ESTABLISHMENT,
  payload: value
});

export const SET_USER_STATE = (value, isValid) => ({
  type: ACTION_TYPE.SET_USER_STATE,
  payload: value
});

export const SET_USER_ERROR = (payload) => ({
  type: ACTION_TYPE.SET_USER_ERROR,
  payload,
});

export const DROP_USER_DATA = () => ({
  type: ACTION_TYPE.DROP_USER_DATA,
  payload: null,
});

export const SET_USER_DATA = (payload) => ({
  type: ACTION_TYPE.SET_USER_DATA,
  payload
});

export const SET_USER_DATA_IN_PROCESS = (payload) => ({
  type: ACTION_TYPE.SET_USER_DATA_IN_PROCESS,
  payload
});

export const OPEN_RESET_MODAL = (payload) => ({
  type: ACTION_TYPE.OPEN_RESET_MODAL,
  payload
});

export const DELETE_USER = (payload) => ({
  type: ACTION_TYPE.DELETE_USER,
  payload
});

export const SET_USER_SAVED = (payload) => ({
  type: ACTION_TYPE.SET_USER_SAVED,
  payload
});

export const SET_USER_NOTIFY = (payload) => ({
  type: ACTION_TYPE.SET_USER_NOTIFY,
  payload
});

export const SET_USER_DELETED = (payload) => ({
  type: ACTION_TYPE.SET_USER_DELETED,
  payload
});


export function loadUserData(userId) {

  return (dispatch, getState) => {

    dispatch(SET_USER_DATA_IN_PROCESS(true));
    dispatch(DROP_USER_DATA());

    return fetchPost(
        apiUrls.users.get.url,
        {userId: userId}
    )
        .then(response => {
          if (response.success) {

            dispatch(
                SET_USER_DATA({
                  user: createUserRedux(response.accountData),
                })
            );
          }

          return response;
        })
        .finally(() => {
          dispatch(SET_USER_DATA_IN_PROCESS(false));
        });
  }
}

export function validateUserData(){

  return function (dispatch, getState) {

    function validateInput(inputValue, validSetter) {
      let result = !isEmptyOrWhitespace(inputValue);

      if (isFunc(validSetter)) validSetter(result);

      return result;
    }

    let
        user = getState().user.user,
        isValid = true;

    isValid &= validateInput(
        user.login.value,
        (isValid) => dispatch(SET_USER_LOGIN_IS_VALID(isValid))
    );

    isValid &= validateInput(
        user.password.value,
        (isValid) => dispatch(SET_USER_PASS_IS_VALID(isValid))
    );

    isValid &= validateInput(
        user.passconfirm.value == user.password.value ? user.passconfirm.value : null,
        (isValid) => dispatch(SET_USER_PASS_CONFIRM_IS_VALID(isValid))
    );

    return Boolean(isValid);
  }
}

export function storeUserData() {

  return (dispatch, getState) => {

    dispatch(SET_USER_DATA_IN_PROCESS(true));
    let user = getState().user.user;

    let parameters = {
      family: user.family.value,
      name: user.name.value,
      phone: user.phone.value,
      login: user.login.value,
      password: user.password.value,
      confirm: user.passconfirm.value,
      roles: user.roles.value,
      establishment: user.establishment.value,
      state: user.state,
    };

    return fetchPost(
        apiUrls.users.add.url,
        parameters
    )
        .then(response => {
          if (response) {
            if (response.success) {

              dispatch(
                  SET_USER_ID(response.userId)
              );
            } else {
              dispatch(
                  SET_USER_ERROR({
                    error: response.error,
                  })
              );
            }
          }
          return response;
        })
        .finally(() => {
          dispatch(SET_USER_DATA_IN_PROCESS(false));
        });
  }
}

export function updateUserData() {

  return (dispatch, getState) => {

    dispatch(SET_USER_DATA_IN_PROCESS(true));
    let user = getState().user.user;
    let state = 0;
    if (user.state === "INACTIVE" ||
        user.state === "ACTIVE") {
      state = user.state === "ACTIVE" ? 1 : 0;
    }
    let parameters = {
      userId: user.id,
      family: user.family.value,
      name: user.name.value,
      phone: user.phone.value,
      roles: user.roles.value,
      establishment: user.establishment.value,
      state,
    };

    return fetchPost(
        apiUrls.users.update.url,
        parameters
    )
        .then(response => {
          if (response) {
            if (response.success) {
              // do nothing
            } else {
              dispatch(
                  SET_USER_ERROR({
                    error: response.error,
                  })
              );
            }
          }
          return response;
        })
        .finally(() => {
          dispatch(SET_USER_DATA_IN_PROCESS(false));
        });
  }
}


export function resetPassword({
                                userId,
                                password,
                                passwordRepeat,
                                passwordOld
                              }) {

  return (dispatch, getState) => {

    dispatch(SET_USER_DATA_IN_PROCESS(true));

    let parameters = {
      userId,
      password,
      confirm: passwordRepeat,
      confirmOld: passwordOld
    };

    return fetchPost(
        apiUrls.users.reset.url,
        parameters
    )
        .then(response => {
          if (response) {
            if (response.success) {
              // do nothing
            } else {
              // dispatch(
              //     SET_USER_ERROR({
              //       error: response.error,
              //     })
              // );
            }
          }

          return response;
        })
        .finally(() => {
          dispatch(SET_USER_DATA_IN_PROCESS(false));
        });
  }
}

export function deleteUser() {
  return (dispatch, getState) => {

    dispatch(SET_USER_DATA_IN_PROCESS(true));
    let user = getState().user.user;

    let parameters = {
      userId: user.id,
    };

    return fetchPost(
        apiUrls.users.remove.url,
        parameters
    )
        .then(response => {
          if (response) {
            if (response.success) {
              dispatch(DELETE_USER(false));

            } else {
              dispatch(
                  SET_USER_ERROR({
                    error: response.error,
                  })
              );
            }
          }
          return response;
        })
        .finally(() => {
          dispatch(SET_USER_DATA_IN_PROCESS(false));
        });
  }
}
