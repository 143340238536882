import {fetchPost} from "../../utils/request";
import {apiUrls} from "../../constants/urls";
import {createAction} from "../../factory/redux/action";
import {loadClientPhotos} from "./cardData";


export const ACTION_TYPE = Object.freeze({
  DROP_VISITOR_DATA: "DROP_VISITOR_DATA",
  SET_VISITOR_DATA: "SET_VISITOR_DATA",
  SET_VISITOR_DATA_IN_PROCESS: "SET_VISITOR_DATA_IN_PROCESS",

  SET_ADD_COMMENT_TO_VISITOR_IN_PROCESS: "SET_ADD_COMMENT_TO_VISITOR_IN_PROCESS",
});

export const DROP_VISITOR_DATA = () => ({
  type: ACTION_TYPE.DROP_VISITOR_DATA,
  payload: null,
});

export const SET_VISITOR_DATA = (payload) => ({
  type: ACTION_TYPE.SET_VISITOR_DATA,
  payload
});

export const SET_VISITOR_DATA_IN_PROCESS = (payload) => ({
  type: ACTION_TYPE.SET_VISITOR_DATA_IN_PROCESS,
  payload
});

export const SET_ADD_COMMENT_TO_VISITOR_IN_PROCESS = (payload) => createAction(
  ACTION_TYPE.SET_ADD_COMMENT_TO_VISITOR_IN_PROCESS,
  payload
);


export function loadVisitorData(visitorId) {

  return (dispatch, getState) => {

    dispatch(SET_VISITOR_DATA_IN_PROCESS(true));
    dispatch(DROP_VISITOR_DATA());

    return fetchPost(
      apiUrls.visitor.getVisitorFullData.url,
      {visitorId,}
    )
      .then(response => {
        if (response.success) {

          dispatch(
            SET_VISITOR_DATA({
              visitor: response.visitor,

              fullMatchCards: response.fullMatchCards,
              partialMatchCards: response.partialMatchCards,
            })
          );
        }
      })
      .finally(() => {
        dispatch(SET_VISITOR_DATA_IN_PROCESS(false));
      });
  }
}

// create client from visitor
export function addCommentToVisitor(
  visitorId,
  comment
) {

  return (dispatch, getState) => {

    const photos = getState().cardCreate.photos;
    dispatch(SET_ADD_COMMENT_TO_VISITOR_IN_PROCESS(true));

    return fetchPost(
      apiUrls.visitor.addComment.url,
      {
        visitorId,
        commentData: {
          type: comment.type.value,
          text: comment.text.value,
        },
        photos: photos.map(photo => ({
          name: photo.name,
          data: photo.data,
        })),
      }
    )
      .finally(() => {
        dispatch(SET_ADD_COMMENT_TO_VISITOR_IN_PROCESS(false));
      });

  }
}