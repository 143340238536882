import {roles} from "../../constants/roles";

export function createUserRedux({
                                  id = -1,
                                  family = '',
                                  name = '',
                                  phone = '',
                                  login = '',
                                  password = '',
                                  passconfirm = '',
                                  state = 1,
                                  roles = 64,
                                  establishment = '',
                                  deleted = false,
                                }) {
  return {
    id,
    state,
    deleted,

    family: {
      value: family,
      isValid: null,
    },
    name: {
      value: name,
      isValid: null,
    },
    phone: {
      value: phone,
      isValid: null,
    },
    login: {
      value: login,
      isValid: null,
    },
    password: {
      value: password,
      isValid: null,
    },
    passconfirm: {
      value: passconfirm,
      isValid: null,
    },
    roles: {
      value: roles,
      isValid: null,
    },
    establishment: {
      value: establishment,
      isValid: null,
    },
  };
}