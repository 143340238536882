import "./Loading.scss";
import React from "react";
import image from  "./images/loader.gif";

// export const Loading = () => (
//     <div className={"loadingElement"}>
//       <img src={image} className={"loadingImage"} alt={"Идёт загрузка..."}/>
//     </div>
// );

export const Loading = (props) => {

  return (
      <div className={"loadingElement"}>
        <img src={image} className={"loadingImage"} alt={"Идёт загрузка..."}/>
      </div>
  );

  if(props.error) {
    return (
        <div className={"loadingElement"}>
          <img src={image} className={"loadingImage"} alt={"Идёт загрузка..."}/>
        </div>
    );
  } else if (props.pastDelay) {
    return (
        <div className={"loadingElement"}>
          <img src={image} className={"loadingImage"} alt={"Идёт загрузка..."}/>
        </div>
    );
  } else {
    return null;
  }

};

