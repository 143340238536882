import {ACTION_TYPE as COMMON_ACTION_TYPE} from "../actions/common";
import {ACTION_TYPE} from "../actions/cards";
import {defaultAmountOnPage} from "../../constants/pagination";

const initialState = {
  inProcess: false,
  list: [],
  newList: [],
  newListUnRead: 0,
  showFilter: false,
  filter: {
    // comment types
    showOnlyType: [],
    familyNameOrPassport: "",
    creatorLogin: "",
  },
  pagination: {
    totalAmount: 0,
    page: 0,
    amountOnPage: defaultAmountOnPage,
  },
  newPagination: {
    totalAmount: 0,
    page: 0,
    amountOnPage: defaultAmountOnPage,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case COMMON_ACTION_TYPE.CLEAR_STORE:
      return {
        ...initialState,
      };
    case ACTION_TYPE.SET_LOAD_CLIENTS_IN_PROCESS:
      return {
        ...state,
        inProcess: action.payload,
      };
    case ACTION_TYPE.SET_CLIENTS:
      return {
        ...state,
        list: [
          ...action.payload
        ],
      };
    case ACTION_TYPE.SET_NEW_CLIENTS:
      return {
        ...state,
        newList: [
          ...action.payload
        ],
      };
    case ACTION_TYPE.SET_NEW_UNREAD_CLIENTS_COUNTER:
      return {
        ...state,
        newListUnRead: action.payload,
      };
    case ACTION_TYPE.ADD_CLIENT:
      return {
        ...state,
        list: [
          action.payload,
          ...state.list,
        ],
      };
    case ACTION_TYPE.DELETE_CLIENT:
      return {
          ...state,
        list: [
            ...state.list.filter((cardData) => {
              return cardData.id !== action.payload
            }),
        ],
      };
    case ACTION_TYPE.UPDATE_CLIENT:
      const newRecord = action.payload;

      // const existingRecord = state.list.find(
      //     (listElement) => (listElement.id === newRecord.id)
      // );

      return {
        ...state,
        list: state.list.map((cardData) => {
          if(cardData.id === newRecord.id) {
            return newRecord;
          } else {
            return cardData;
          }
        })
      };

    case ACTION_TYPE.TOGGLE_CARDS_FILTER:
      return {
        ...state,
        showFilter: !state.showFilter,
      };
    case ACTION_TYPE.CLEAR_CARDS_FILTER:
      return {
        ...state,
        filter: {
          ...initialState.filter,
        }
      };

    case ACTION_TYPE.SET_FILTER_SHOW_ONLY_TYPE:
      let inArray = state.filter.showOnlyType.indexOf(action.payload) > -1;

      let newShowOnlyType;
      if (inArray) {
        newShowOnlyType = state.filter.showOnlyType
            .filter(value => value !== action.payload)
      } else {
        newShowOnlyType = [...state.filter.showOnlyType, action.payload];
      }

      return {
        ...state,
        filter: {
          ...state.filter,
          showOnlyType: newShowOnlyType,
        },
        pagination: {
          ...state.pagination,
          page: 0,
        },
      };
    case ACTION_TYPE.SET_FILTER_FAMILY_NAME_OR_PASSPORT:
      return {
        ...state,
        filter: {
          ...state.filter,
          familyNameOrPassport: action.payload || "",
        },
        pagination: {
          ...state.pagination,
          page: 0,
        },
      };
    case ACTION_TYPE.SET_CLIENTS_FILTER_LOGIN:
      return {
        ...state,
        filter: {
          ...state.filter,
          creatorLogin: action.payload || "",
        },
        pagination: {
          ...state.pagination,
          page: 0,
        },
      };

      // Pagination
    case ACTION_TYPE.SET_TOTAL_AMOUNT:

      return {
        ...state,
        pagination: {
          ...state.pagination,
          totalAmount: action.payload,
        },
      };
    case ACTION_TYPE.SET_NEW_TOTAL_AMOUNT:

      return {
        ...state,
        newPagination: {
          ...state.pagination,
          totalAmount: action.payload,
        },
      };
    case ACTION_TYPE.SET_PAGINATION_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.payload,
        },
      };
    case ACTION_TYPE.SET_PAGINATION_AMOUNT_ON_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          amountOnPage: action.payload,
        },
      };

    default:
      return state;
  }
};