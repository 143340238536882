import {ACTION_TYPE} from "../actions/selectLang";

function getCurrentLang() {
    if (localStorage.getItem('selectedLang') == null) {
        // let userLang = navigator.language || navigator.userLanguage;
        // return userLang.substr(0, 2);
        return 'en';
    } else {
        // return "ru";
        return localStorage.getItem('selectedLang');
    }

}

const initialState = {
    currentLang: getCurrentLang(),
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPE.SET_LANG:
            return {
                ...state,
                currentLang: action.payload,
            };
        default:
            return state;
    }
}
