export const createVisitor = ({
                                id,
// timestamp of creation
                                timestamp,
                                // record type
                                type = 0,
// The visitor is not permitted by tax service
                                taxServiceBan = false,
// indicates that the client may have a new document

                                ageBan = false,

                                documentChange = false,
// document type (passport etc)
                                documentType = "",
// document country (BY, RU etc)
                                documentCountry = "",
// document number
                                documentNumber = "",
// document id
                                documentId = "",
// document issue date
                                documentIssueDate = null,
// document expire date
                                documentExpireDate = null,
// client first name
                                name = "",
// client last name
                                familyName = "",
// client patronymic
                                patronymic = "",
// date of birth
                                dateOfBirth = null,
// id of establishment
                                establishmentId = "",
// name of establishment
                                establishmentName = "-1",
// name of account that checked this visitor
                                requester = "-1",
// id of the guest in Kaloev system
                                kaloevId = "",

// comments found in local database
                                localComments = [],
// comments found in OWL database
                                globalComments = [],

                                globalCommentsLoading = false,

                                /**
                                 * Visitor passport image
                                 */
                                visitorImage = "",
                                clientImage = "",

// connected client card id
                                clientCardId = "",
// the card was previously deleted
                                cardDeleted = false,
                                ...
                                    other
                              }) =>
    ({
      id,
      timestamp,
      type,
      taxServiceBan,
      ageBan,
      documentChange,
      establishmentId,

      clientCardId,
      cardDeleted,

      establishmentName: {
        value: establishmentName,
      },
      documentType: {
        value: documentType,
      },
      documentCountry: {
        value: documentCountry,
      },
      documentNumber: {
        value: documentNumber,
      },
      documentId: {
        value: documentId,
      },
      documentIssueDate: {
        value: documentIssueDate,
      },
      documentExpireDate: {
        value: documentExpireDate,
      },
      name: {
        value: name,
      },
      familyName: {
        value: familyName,
      },
      patronymic: {
        value: dateOfBirth,
      },
      dateOfBirth: {
        value: dateOfBirth,
      },
      requester: {
        value: requester,
      },

      kaloevId: {
        value: kaloevId,
      },
      visitorImage: {
        value: visitorImage,
      },
        clientImage,

      localComments,
      globalComments,
      globalCommentsLoading,

      // localComments: localComments.map(
      //     (commentData) =>
      //         createComment(commentData)
      // ),
      // globalComments: globalComments.map(
      //     (commentData) =>
      //         createComment(commentData)
      // ),

    });