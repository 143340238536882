import {fetchPost} from "../../utils/request";
import {apiUrls} from "../../constants/urls";
import {isEmpty, isEmptyOrWhitespace, shortenString} from "../../utils/string";
import {dateToString, formatDate, isValidDate} from "../../utils/date";

export const ACTION_TYPE = Object.freeze({
  SET_CLIENT_CREATE_IN_PROCESS: "SET_CLIENT_CREATE_IN_PROCESS",
  DROP_CLIENT_CREATE_DATA: "DROP_CLIENT_CREATE_DATA",

  SET_CLIENT_CREATE_FAMILY_NAME: "SET_CLIENT_CREATE_FAMILY_NAME",
  SET_CLIENT_CREATE_NAME: "SET_CLIENT_CREATE_NAME",
  SET_CLIENT_CREATE_PATRONYMIC: "SET_CLIENT_CREATE_PATRONYMIC",
  SET_CLIENT_CREATE_DATE_OF_BIRTH: "SET_CLIENT_CREATE_DATE_OF_BIRTH",
  SET_CLIENT_CREATE_KALOEV_ID: "SET_CLIENT_CREATE_KALOEV_ID",
  SET_CLIENT_CREATE_EXISTS: "SET_CLIENT_CREATE_EXISTS",
  SET_CLIENT_CREATE_SHOW_EXISTS_MODAL: "SET_CLIENT_CREATE_SHOW_EXISTS_MODAL",

  // ADD_CLIENT_CREATE_DOCUMENT: "ADD_CLIENT_CREATE_DOCUMENT",
  // REMOVE_CLIENT_CREATE_DOCUMENT: "REMOVE_CLIENT_CREATE_DOCUMENT",

  SET_CLIENT_CREATE_DOCUMENT_FIELD: "SET_CLIENT_CREATE_DOCUMENT_FIELD",
  // SET_CLIENT_CREATE_DOCUMENT_COUNTRY: "SET_CLIENT_CREATE_DOCUMENT_COUNTRY",
  // SET_CLIENT_CREATE_DOCUMENT_NUMBER: "SET_CLIENT_CREATE_DOCUMENT_NUMBER",
  // SET_CLIENT_CREATE_DOCUMENT_ISSUE_DATE: "SET_CLIENT_CREATE_DOCUMENT_ISSUE_DATE",
  // SET_CLIENT_CREATE_DOCUMENT_EXPIRE_DATE: "SET_CLIENT_CREATE_DOCUMENT_EXPIRE_DATE",
  // SET_CLIENT_CREATE_DOCUMENT_PERSONAL_NUMBER: "SET_CLIENT_CREATE_DOCUMENT_PERSONAL_NUMBER",

  SET_COMMENTS_IS_VALID: "SET_COMMENTS_IS_VALID",
  ADD_CLIENT_COMMENT: "ADD_CLIENT_COMMENT",
  SET_COMMENT_FIELD: "SET_COMMENT_FIELD",
  // SET_COMMENT: "SET_COMMENT",
  DELETE_COMMENT: "DELETE_COMMENT",

  ADD_CLIENT_CREATE_PHOTO: "ADD_CLIENT_CREATE_PHOTO",
  REMOVE_CLIENT_CREATE_PHOTO: "REMOVE_CLIENT_CREATE_PHOTO",

  SET_CLIENT_CREATE_ERROR: "SET_CLIENT_CREATE_ERROR",
});

const SET_CLIENT_CREATE_IN_PROCESS = (payload) => ({
  type: ACTION_TYPE.SET_CLIENT_CREATE_IN_PROCESS,
  payload,
});

export const DROP_CLIENT_CREATE_DATA = () => ({
  type: ACTION_TYPE.DROP_CLIENT_CREATE_DATA,
  payload: null,
});

export const SET_CLIENT_CREATE_FAMILY_NAME = (value, isValid) => ({
  type: ACTION_TYPE.SET_CLIENT_CREATE_FAMILY_NAME,
  payload: {
    value,
    isValid,
  },
});
export const SET_CLIENT_CREATE_NAME = (value, isValid) => ({
  type: ACTION_TYPE.SET_CLIENT_CREATE_NAME,
  payload: {
    value,
    isValid,
  },
});
export const SET_CLIENT_CREATE_PATRONYMIC = (value, isValid) => ({
  type: ACTION_TYPE.SET_CLIENT_CREATE_PATRONYMIC,
  payload: {
    value,
    isValid,
  },
});
export const SET_CLIENT_CREATE_DATE_OF_BIRTH = (value, isValid) => ({
  type: ACTION_TYPE.SET_CLIENT_CREATE_DATE_OF_BIRTH,
  payload: {
    value,
    isValid,
  },
});
export const SET_CLIENT_CREATE_KALOEV_ID = (value, isValid) => ({
  type: ACTION_TYPE.SET_CLIENT_CREATE_KALOEV_ID,
  payload: {
    value,
    isValid,
  },
});
/**
 * Set if the client with provided parameters already exists in system
 * @param value - existence value
 * @param cardId - id of existing client
 * @returns {{type: string, payload: {value: *, cardId: *}}}
 * @constructor
 */
export const SET_CLIENT_CREATE_EXISTS = (value, cardId) => ({
  type: ACTION_TYPE.SET_CLIENT_CREATE_EXISTS,
  payload: {
    value,
    cardId,
  },
});
/**
 * Set ClientExistsModal display value
 * @param payload - true - display
 * @returns {{type: string, payload: *}}
 * @constructor
 */
export const SET_CLIENT_CREATE_SHOW_EXISTS_MODAL = (payload) => ({
  type: ACTION_TYPE.SET_CLIENT_CREATE_SHOW_EXISTS_MODAL,
  payload,
});

// documents

// export const ADD_CLIENT_CREATE_DOCUMENT = () => ({
//   type: ACTION_TYPE.ADD_CLIENT_CREATE_DOCUMENT,
//   payload: null,
// });
// export const REMOVE_CLIENT_CREATE_DOCUMENT = (payload) => ({
//   type: ACTION_TYPE.REMOVE_CLIENT_CREATE_DOCUMENT,
//   payload,
// });

/**
 * Set the field in the corresponding document
 * @param documentDataIndex - index of documentData
 * @param fieldName - field name
 * @param value - new value
 * @param isValid - the field is valid
 * @returns {{type: string, payload: {documentDataIndex: *, fieldName: *, value: *, isValid: *}}}
 * @constructor
 */
export const SET_CLIENT_CREATE_DOCUMENT_FIELD = (
    documentDataIndex,
    fieldName,
    value,
    isValid = null
) => ({
  type: ACTION_TYPE.SET_CLIENT_CREATE_DOCUMENT_FIELD,
  payload: {
    documentDataIndex,
    fieldName,
    value,
    isValid,
  },
});

// export const SET_CLIENT_CREATE_DOCUMENT_COUNTRY = (index, value, isValid) => ({
//   type: ACTION_TYPE.SET_CLIENT_CREATE_DOCUMENT_COUNTRY,
//   payload: {
//     index,
//     value,
//     isValid,
//   },
// });
// export const SET_CLIENT_CREATE_DOCUMENT_NUMBER = (index, value, isValid) => ({
//   type: ACTION_TYPE.SET_CLIENT_CREATE_DOCUMENT_NUMBER,
//   payload: {
//     index,
//     value,
//     isValid,
//   },
// });
// export const SET_CLIENT_CREATE_DOCUMENT_ISSUE_DATE = (index, value, isValid) => ({
//   type: ACTION_TYPE.SET_CLIENT_CREATE_DOCUMENT_ISSUE_DATE,
//   payload: {
//     index,
//     value,
//     isValid,
//   },
// });
// export const SET_CLIENT_CREATE_DOCUMENT_EXPIRE_DATE = (index, value, isValid) => ({
//   type: ACTION_TYPE.SET_CLIENT_CREATE_DOCUMENT_EXPIRE_DATE,
//   payload: {
//     index,
//     value,
//     isValid,
//   },
// });
// export const SET_CLIENT_CREATE_DOCUMENT_PERSONAL_NUMBER = (index, value, isValid) => ({
//   type: ACTION_TYPE.SET_CLIENT_CREATE_DOCUMENT_PERSONAL_NUMBER,
//   payload: {
//     index,
//     value,
//     isValid,
//   },
// });

export const ADD_CLIENT_COMMENT = (payload) => ({
  type: ACTION_TYPE.ADD_CLIENT_COMMENT,
  payload,
});


export const SET_COMMENTS_IS_VALID = (payload) => ({
  type: ACTION_TYPE.SET_COMMENTS_IS_VALID,
  payload,
});


export const SET_COMMENT_FIELD = (
    commentIndex,
    fieldName,
    value,
    isValid=null,
) => ({
  type: ACTION_TYPE.SET_COMMENT_FIELD,
  payload: {
    commentIndex,
    fieldName,
    value,
    isValid,
  },
});

export const DELETE_COMMENT = (payload) => ({
  type: ACTION_TYPE.DELETE_COMMENT,
  payload,
});

export const ADD_CLIENT_CREATE_PHOTO = (file, data) => ({
  type: ACTION_TYPE.ADD_CLIENT_CREATE_PHOTO,
  payload: {
    file,
    data,
  }
});

export const REMOVE_CLIENT_CREATE_PHOTO = (payload) => ({
  type: ACTION_TYPE.REMOVE_CLIENT_CREATE_PHOTO,
  payload
});

// error
export const SET_CLIENT_CREATE_ERROR = (payload) => ({
  type: ACTION_TYPE.SET_CLIENT_CREATE_ERROR,
  payload,
});


/**
 * Check if client with provided parameters already exists in system
 * @returns {Function}
 */
export function checkCardCreateClientExistence() {

  return (dispatch, getState) => {

    const clientCreateState = getState().cardCreate;

    let parametersAreFilled =
        !isEmpty(clientCreateState.personalData.name.value) &&
        !isEmpty(clientCreateState.personalData.familyName.value) //&&
        // !isEmpty(clientCreateState.personalData.patronymic.value) &&
        // !isEmpty(clientCreateState.personalData.dateOfBirth.value)

    if (parametersAreFilled) {
      dispatch(SET_CLIENT_CREATE_IN_PROCESS(true));

      let parameters = {
        personalData: {
          name: clientCreateState.personalData.name.value,
          familyName: clientCreateState.personalData.familyName.value,
          patronymic: clientCreateState.personalData.patronymic.value,
          dateOfBirth: dateToString(
              clientCreateState.personalData.dateOfBirth.value
          ),
        },
        photos: clientCreateState.photos.map(photo => ({
          name: photo.name,
          data: photo.data,
        })),
      };

      fetchPost(
          apiUrls.client.checkExistence.url,
          parameters
      )
          .then(response => {
            if (response.success) {
              dispatch(
                  SET_CLIENT_CREATE_EXISTS(
                      response.exists,
                      response.cardId
                  )
              );

              if (response.exists) {
                dispatch(SET_CLIENT_CREATE_SHOW_EXISTS_MODAL(true));
              }
            }
          })
          .finally(() => {
            dispatch(SET_CLIENT_CREATE_IN_PROCESS(false));
          });

    }
  }
}

/**
 * Validate inputs
 * @returns {function(*, *): boolean}
 */
export function validateCreateClientInputs() {

  return (dispatch, getState) => {

    let result = true;

    const clientCreate = getState().cardCreate;

    function validateTextInput(inputValue, inputValueSetter) {
      let result = !isEmpty(inputValue);
      dispatch(
          inputValueSetter(
              inputValue,
              result
          )
      );
      return result;
    }

    function validateDateInput(inputValue, inputValueSetter) {
      let result = isValidDate(inputValue) || isEmpty(inputValue);
      dispatch(
          inputValueSetter(
              inputValue,
              result
          )
      );
      return result;
    }

    // validate person data
    result &= validateTextInput(
        clientCreate.personalData.familyName.value,
        SET_CLIENT_CREATE_FAMILY_NAME
    );
    result &= validateTextInput(
        clientCreate.personalData.name.value,
        SET_CLIENT_CREATE_NAME
    );
    // result&=checkInput(
    //     clientCreate.personalData.patronymic.value,
    //     SET_CLIENT_CREATE_PATRONYMIC
    // );

    result &= validateDateInput(
        clientCreate.personalData.dateOfBirth.value,
        SET_CLIENT_CREATE_DATE_OF_BIRTH
    );

    // validate document data
    clientCreate.personalData.documentDataList
        .forEach((documentData, index) => {

          // result &= validateInput(
          //     documentData.country.value,
          //     (value, isValid) => SET_CLIENT_CREATE_DOCUMENT_FIELD(
          //         index,
          //         "country",
          //         value,
          //         isValid
          //     )
          //
          // );

          // result &= validateInput(
          //     documentData.number.value,
          //     (value, isValid) => SET_CLIENT_CREATE_DOCUMENT_NUMBER(index, value, isValid)
          // );
          // result &= validateInput(
          //     clientCreate.documentData.issueDate.value,
          //     (value, isValid) => SET_CLIENT_CREATE_DOCUMENT_ISSUE_DATE(index, value, isValid)
          // );
          // result &= validateInput(
          //     clientCreate.documentData.expireDate.value,
          //     (value, isValid) => SET_CLIENT_CREATE_DOCUMENT_EXPIRE_DATE(index, value, isValid)
          // );
          // result &= validateInput(
          //     clientCreate.documentData.personalId.value,
          //     (value, isValid) => SET_CLIENT_CREATE_DOCUMENT_PERSONAL_NUMBER(index, value, isValid)
          // );
        });


    // check if comments were added
    const commentsExist = (clientCreate.comments.length > 0);
    result &= commentsExist;
    dispatch(
        SET_COMMENTS_IS_VALID(commentsExist)
    );

    // iterate through comments and check their text
    clientCreate.comments
        .forEach((comment, index) => {
          const
              typeIsValid = !isEmptyOrWhitespace(comment.type.value),
              textIsValid = !isEmptyOrWhitespace(comment.text.value);

          dispatch(
              SET_COMMENT_FIELD(
                  index,
                  "type",
                  comment.type.value,
                  typeIsValid
              )
          );

          dispatch(
              SET_COMMENT_FIELD(
                  index,
                  "text",
                  comment.text.value,
                  textIsValid
              )
          );

          result &= (typeIsValid && textIsValid);
        });

    return Boolean(result);
  }
}

/**
 * Make create client request
 * @returns {Function}
 */
export function cardCreate() {

  return (dispatch, getState) => {

    dispatch(SET_CLIENT_CREATE_IN_PROCESS(true));

    // pick data from store
    const
        personalData = getState().cardCreate.personalData,
        documentDataList = getState().cardCreate.documentDataList,
        // documentData = getState().cardCreate.documentData,
        comments = getState().cardCreate.comments,
        photos = getState().cardCreate.photos;

    let parameters = {
      personalData: {
        familyName: personalData.familyName.value.charAt(0) + personalData.familyName.value.slice(1).toLowerCase(),
        name: personalData.name.value.charAt(0) + personalData.name.value.slice(1).toLowerCase(),
        patronymic: personalData.patronymic.value,
        dateOfBirth: dateToString(personalData.dateOfBirth.value),
        kaloevId: personalData.kaloevId.value,

        documentDataList: [
          ...personalData.documentDataList.map((documentData) => ({
            country: documentData.country.value,
            number: documentData.number.value,
            issueDate: dateToString(documentData.issueDate.value),
            expireDate: dateToString(documentData.expireDate.value),
            personalId: documentData.personalId.value,
          })),
        ],
      },
      commentList: comments.map(comment => ({
        type: comment.type.value,
        text: comment.text.value,
      })),
      photos: photos.map(photo => ({
        name: photo.name,
        data: photo.data,
      })),
    };

    return fetchPost(
        apiUrls.client.create.url,
        parameters
    )
        .then((response) => {
          return response;
        })
        .finally(() => {
          dispatch(SET_CLIENT_CREATE_IN_PROCESS(false));
        })

  }

}