
export const commentTypes = Object.freeze({
  BLACKLIST: "blacklist",
  INFO: "info",
});

export const commentTypesWeights = Object.freeze({

  "blacklist": 1,
  "attention": 2,
  "poker": 3,
  "roulette": 4,
  "blackjack": 5,
  "slot": 6,
  "bookmaker": 7,
  "info": 8,

});