import {fetchGet, fetchPost} from "../../utils/request";
import {apiUrls} from "../../constants/urls";
import {CLEAR_STORE} from "../actions/common";
import {createError} from "../../factory/redux/error";
import {createAction} from "../../factory/redux/action";
import {getErrorCodeMessage} from "../../constants/apiErrorCode";
import {connect} from "../../utils/ws";
import {t} from "i18next";

export const ACTION_TYPE = Object.freeze({
  SET_AUTHENTICATION_IN_PROCESS: "SET_AUTHENTICATION_IN_PROCESS",
  SET_ACCOUNT_DATA: "SET_ACCOUNT_DATA",
  SET_IS_AUTHENTICATED: "SET_IS_AUTHENTICATED",

  SET_AUTHENTICATION_ERROR: "SET_AUTHENTICATION_ERROR",
});

const SET_ACCOUNT_DATA = (payload) => createAction(
    ACTION_TYPE.SET_ACCOUNT_DATA,
    payload
);

const SET_AUTHENTICATION_IN_PROCESS = (payload) => createAction(
    ACTION_TYPE.SET_AUTHENTICATION_IN_PROCESS,
    payload
);

const SET_IS_AUTHENTICATED = (payload) => createAction(
    ACTION_TYPE.SET_IS_AUTHENTICATED,
    payload
);

const SET_AUTHENTICATION_ERROR = (
    hasError,
    errorCode=null,
    message=null
                                  ) => createAction(
    ACTION_TYPE.SET_AUTHENTICATION_ERROR,
    createError(
      hasError,
      errorCode,
      message
    )
);

/**
 * Load authentication status and put it into store
 * @returns {Function}
 */
export const checkAuthentication = () => {
  return (dispatch, getState) => {
    dispatch(SET_AUTHENTICATION_IN_PROCESS(true));

    fetchGet(apiUrls.authentication.status.url, true)
        .then(response => {
          if (response.success) {
              connect();
            // set account datac
            dispatch(SET_ACCOUNT_DATA(response.accountData));
            // set isAuthenticated
            dispatch(SET_IS_AUTHENTICATED(true));
            document.querySelector('meta[name="viewport"]').setAttribute("content", "width=1260px, initial-scale=1");
          } else {
            // dispatch common action to clear all stores
            dispatch(CLEAR_STORE());
            // dispatch(SET_IS_AUTHENTICATED(false));
            // dispatch(SET_ACCOUNT_DATA(null));
          }
        })
        .finally(() => {
          dispatch(SET_AUTHENTICATION_IN_PROCESS(false));
        });
  }
};

/**
 * Send authentication request to the server and write the result to the store
 * @param authData - authentication data
 * @returns {Function} with in redux-thunk specification
 */
export const authenticate = (authData) => {
  /**
   *
   * @param dispatch - dispatch function to trigger actions
   * @param @getState - function to get current state
   */
  return (dispatch, getState) => {

    dispatch(SET_AUTHENTICATION_IN_PROCESS(true));
    dispatch(SET_AUTHENTICATION_ERROR(false));

    fetchPost(
        apiUrls.authentication.authenticate.url,
        {
          ...authData,
        }
    )
        .then((response) => {
          if (response.success) {

            // check authentication from server
            dispatch(checkAuthentication());

          } else {
            dispatch(SET_AUTHENTICATION_ERROR(
                true,
                response.error && response.error.code,
                t(getErrorCodeMessage(response.error && response.error.code))
            ))
          }
        })
        .finally(() => {
          dispatch(SET_AUTHENTICATION_IN_PROCESS(false));
        });
  }
};

export const signOut = () => {

  return (dispatch, getState) => {
    fetchPost(
        apiUrls.authentication.signOut.url,
        {}
    )
        .then(response => {
          if (response.success) {

            // dispatch common action to clear all stores
            dispatch(CLEAR_STORE());

            // check authentication from server
            dispatch(checkAuthentication());

          }
        });
  };
};

export function pingAuthentication() {
  return function(dispatch, getState) {
    return fetchPost(
        apiUrls.authentication.isAuthenticated.url,
        {}
    );
  }
}