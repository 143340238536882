import {
  ACTION_TYPE, checkAuthentication,
  pingAuthentication
} from "../actions/authentication";
import {
  ACTION_TYPE as COMMON_ACTION_TYPE,
  CLEAR_STORE
} from "../actions/common";
import {loadEstablishments} from "../actions/establishment";
import {loadOptions} from "../actions/options";
import {
  loadVisitors,
  SET_VISITORS,
  SET_VISITORS_FILTER_PERIOD
} from "../actions/visitors";
import {checkAccessRights} from "../../utils/authentication";
import {roles} from "../../constants/roles";
import {periodValues} from "../../constants/period";
import {WS_CONNECT, WS_DISCONNECT} from "../actions/webSocket";


// let authenticationPinger = null;

function _setAuthenticationPinger({
                                   dispatch,
                                   isAuthenticated,
                                 }) {
  // if(isAuthenticated) {
  //   // set scheduler
  //   if (authenticationPinger === null) {
  //     authenticationPinger = setInterval(() => {
  //       dispatch(pingAuthentication())
  //           .then((response) => {
  //
  //             if(response.success) {
  //               // do nothing
  //             } else {
  //               dispatch(CLEAR_STORE());
  //               dispatch(checkAuthentication());
  //             }
  //
  //           })
  //           .catch(() => {
  //             dispatch(CLEAR_STORE());
  //             dispatch(checkAuthentication());
  //           });
  //     }, 30000);
  //   } else {
  //     // take down scheduler
  //     if(authenticationPinger !== null) {
  //       clearInterval(authenticationPinger);
  //       authenticationPinger = null;
  //     }
  //   }
  // }
}

function authenticationMiddleware() {


  return ({dispatch, getState}) => (next) => async (action) => {
    switch (action.type) {

      case ACTION_TYPE.SET_IS_AUTHENTICATED: {
        if (action.payload) {

          await dispatch(loadOptions());
          await dispatch(loadEstablishments());
          await dispatch(loadVisitors());

          const currentAccountRoles =
              getState().authentication.accountData.roles;

          if (!checkAccessRights(
              currentAccountRoles,
              [roles.admin, roles.moderator]
          )) {
            dispatch(SET_VISITORS_FILTER_PERIOD(periodValues.TWO_DAYS));
          }

          // connect websocket

          dispatch(WS_CONNECT());

        } else {
          dispatch(SET_VISITORS());
        }

        // _setAuthenticationPinger({dispatch, isAuthenticated: action.payload});
        return next(action);
      }
      case COMMON_ACTION_TYPE.CLEAR_STORE: {
        // _setAuthenticationPinger({dispatch, isAuthenticated: false});

        dispatch(WS_DISCONNECT());

        return next(action);
      }
      default:
        return next(action);
    }
  }
}

export default authenticationMiddleware();