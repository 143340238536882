import {fetchGet, fetchPost} from "../../utils/request";
import {apiUrls} from "../../constants/urls";
import {createAction} from "../../factory/redux/action";
import {checkAccessRights} from "../../utils/authentication";
import {roles} from "../../constants/roles";

export const ACTION_TYPE = Object.freeze({
  ADD_VISITOR: "ADD_VISITOR",
  DELETE_VISITOR: "DELETE_VISITOR",
  UPDATE_VISITOR: "UPDATE_VISITOR",
  SET_LOAD_VISITORS_IN_PROCESS: "SET_LOAD_VISITORS_IN_PROCESS",
  SET_VISITORS: "SET_VISITORS",

  TOGGLE_VISITORS_FILTER: "TOGGLE_VISITORS_FILTER",
  CLEAR_FILTER: "CLEAR_VISITORS_FILTER",

  SET_FILTER_HIDE_CLEAN: "SET_VISITORS_FILTER_HIDE_CLEAN",
  SET_FILTER_HIDE_OUTER: "SET_VISITORS_FILTER_HIDE_OUTER",
  SET_FILTER_HIDE_LOCAL: "SET_VISITORS_FILTER_HIDE_LOCAL",
  SET_VISITORS_FILTER_HIDE_UPDATED: "SET_VISITORS_FILTER_HIDE_UPDATED",

  SET_FILTER_SHOW_ONLY_TYPE: "SET_VISITORS_FILTER_SHOW_ONLY_TYPE",
  SET_FILTER_FAMILY_NAME_OR_PASSPORT: "SET_VISITORS_FILTER_FAMILY_NAME_OR_PASSPORT",
  SET_VISITORS_FILTER_LOGIN: "SET_VISITORS_FILTER_LOGIN",

  SET_FILTER_PERIOD: "SET_VISITORS_FILTER_PERIOD",
  SET_FILTER_RANGE_FROM: "SET_VISITORS_FILTER_RANGE_FROM",
  SET_FILTER_RANGE_TO: "SET_VISITORS_FILTER_RANGE_TO",

  SET_FILTER_ESTABLISHMENT: "SET_VISITORS_FILTER_ESTABLISHMENT",

  PIN_VISITOR: "PIN_VISITOR",
});

export const ADD_VISITOR = (payload) => ({
  type: ACTION_TYPE.ADD_VISITOR,
  payload,
});

export const UPDATE_VISITOR = (payload) => createAction(
    ACTION_TYPE.UPDATE_VISITOR,
    payload,
);

export const DELETE_VISITOR = (payload) => createAction(
    ACTION_TYPE.DELETE_VISITOR,
    payload,
);

const SET_LOAD_VISITORS_IN_PROCESS = (payload) => ({
  type: ACTION_TYPE.SET_LOAD_VISITORS_IN_PROCESS,
  payload,
});

export const SET_VISITORS = (payload=[]) => ({
  type: ACTION_TYPE.SET_VISITORS,
  payload,
});

export const TOGGLE_VISITORS_FILTER = (payload) => ({
  type: ACTION_TYPE.TOGGLE_VISITORS_FILTER,
  payload,
});

export const CLEAR_VISITORS_FILTER = () => createAction(
  ACTION_TYPE.CLEAR_FILTER,
  null
);

export const SET_VISITORS_FILTER_HIDE_CLEAN = (payload) => ({
  type: ACTION_TYPE.SET_FILTER_HIDE_CLEAN,
  payload,
});

export const SET_VISITORS_FILTER_HIDE_OUTER = (payload) => ({
  type: ACTION_TYPE.SET_FILTER_HIDE_OUTER,
  payload,
});

export const SET_VISITORS_FILTER_HIDE_LOCAL = (payload) => ({
  type: ACTION_TYPE.SET_FILTER_HIDE_LOCAL,
  payload,
});

export const SET_VISITORS_FILTER_HIDE_UPDATED = (payload) => ({
  type: ACTION_TYPE.SET_VISITORS_FILTER_HIDE_UPDATED,
  payload,
});

export const SET_VISITORS_FILTER_SHOW_ONLY_TYPE = (payload) => ({
  type: ACTION_TYPE.SET_FILTER_SHOW_ONLY_TYPE,
  payload,
});

export const SET_VISITORS_FILTER_FAMILY_NAME_OR_PASSPORT = (payload) => ({
  type: ACTION_TYPE.SET_FILTER_FAMILY_NAME_OR_PASSPORT,
  payload,
});

export const SET_VISITORS_FILTER_LOGIN = (payload) => ({
  type: ACTION_TYPE.SET_VISITORS_FILTER_LOGIN,
  payload,
});

export const SET_VISITORS_FILTER_PERIOD = (payload) => ({
  type: ACTION_TYPE.SET_FILTER_PERIOD,
  payload,
});

export const SET_VISITORS_FILTER_RANGE_FROM = (payload) => ({
  type: ACTION_TYPE.SET_FILTER_RANGE_FROM,
  payload,
});

export const SET_VISITORS_FILTER_RANGE_TO = (payload) => ({
  type: ACTION_TYPE.SET_FILTER_RANGE_TO,
  payload,
});

export const SET_VISITORS_FILTER_ESTABLISHMENT = (payload) => ({
  type: ACTION_TYPE.SET_FILTER_ESTABLISHMENT,
  payload,
});

export const PIN_VISITOR = (payload) => ({
  type: ACTION_TYPE.PIN_VISITOR,
  payload,
});


export const loadVisitors = () => {

  return (dispatch, getState) => {
    dispatch(SET_LOAD_VISITORS_IN_PROCESS(true));

    return fetchPost(
        apiUrls.visitor.getAll.url,
        {
          requesterLogin: getState().visitors.filter.login
        }
    )
        .then((response) => {
          if(response.success){
            dispatch(SET_VISITORS(response.visitors));
          }
        })
        .finally(() => {
          dispatch(SET_LOAD_VISITORS_IN_PROCESS(false));
        });
  };
};

export const deleteVisitor = (visitorId) => {

  return (dispatch, getState) => {

    return fetchPost(
      apiUrls.visitor.delete.url,
      {
        visitorId: visitorId
      }
    )
      .then((response) => {
        if(response.success){
          dispatch(loadVisitors());
        }
      })
      .finally(() => {
        dispatch(SET_LOAD_VISITORS_IN_PROCESS(false));
      });
  };
};