import React from "react";
import {ACTION_TYPE as COMMON_ACTION_TYPE} from "../actions/common";
import {ACTION_TYPE} from "../actions/visitorsArchive";
import {createVisitor} from "../../factory/redux/visitor";
import {defaultAmountOnPage} from "../../constants/pagination";


const initialState = {
  inProcess: false,
  showFilter: false,
  filter: {
    hideClean: false,
    hideOuter: false,
    hideLocal: false,

    // comment types
    showOnlyType: [],

    // range
    rangeFrom: null,
    rangeTo: null,

    establishment: 0,

    familyNameOrPassport: "",
  },

  list: [
    // {
    //   name: "",
    //   familyName: "",
    //   // ...
    // }
  ],
  pagination: {
    totalAmount: 0,
    page: 0,
    amountOnPage: defaultAmountOnPage,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case COMMON_ACTION_TYPE.CLEAR_STORE:
      return {
        ...initialState,
      };

    case ACTION_TYPE.SET_ARCHIVE_IN_PROCESS:
      return {
        ...state,
        inProcess: action.payload,
      };

    // case ACTION_TYPE.ADD_VISITOR: {
    //   const visitorData = action.payload;
    //
    //   return {
    //     ...state,
    //     list: [
    //       createVisitor(visitorData),
    //       ...state.list,
    //     ],
    //   };
    // }
    // case ACTION_TYPE.DELETE_VISITOR: {
    //   const
    //       currentList = state.list,
    //       deletedVisitorData = createVisitor(action.payload);
    //
    //   return {
    //     ...state,
    //     list: currentList.filter(
    //         (existingVisitorData) =>
    //             (existingVisitorData.id !== deletedVisitorData.id)),
    //   };
    // }
    // case ACTION_TYPE.UPDATE_VISITOR: {
    //
    //   const
    //       currentList = state.list,
    //       newVisitorData = createVisitor(action.payload);
    //
    //   return {
    //     ...state,
    //     list: currentList.map((existingVisitorData) => {
    //       if(existingVisitorData.id === newVisitorData.id){
    //         return newVisitorData;
    //       } else {
    //         return existingVisitorData;
    //       }
    //     }),
    //   };
    // }

    case ACTION_TYPE.SET_ARCHIVE_LIST:
      return {
        ...state,
        list: action.payload.map((visitorData) => createVisitor(visitorData)),
      };
    case ACTION_TYPE.TOGGLE_ARCHIVE_FILTER:
      return {
        ...state,
        showFilter: !state.showFilter,
      };
    case ACTION_TYPE.CLEAR_ARCHIVE_FILTER:
      return {
        ...state,
        filter: {
          ...initialState.filter,
        },
      };

    case ACTION_TYPE.SET_ARCHIVE_FILTER_VALUE:
      return {
          ...state,
        filter: {
            ...state.filter,
          [action.payload.name]: action.payload.value,
        },
      };

    case ACTION_TYPE.SET_ARCHIVE_PAGINATION_VALUE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          [action.payload.name]: action.payload.value,
        },
      };

    default:
      return state;
  }
}
