import {fetchGet} from "../../utils/request";
import {apiUrls} from "../../constants/urls";
import {createAction} from "../../factory/redux/action";

export const ACTION_TYPE = Object.freeze({
  ADD_USER: "ADD_USER",
  SET_LOAD_USERS_IN_PROCESS: "SET_LOAD_USERS_IN_PROCESS",
  SET_USERS: "SET_USERS",

  CLEAR_FILTER: "CLEAR_USERS_FILTER",
  TOGGLE_USERS_FILTER: "TOGGLE_USERS_FILTER",

  SET_USER_ROLE_FILTER: "SET_USER_ROLE_FILTER",
  SET_USER_NAME_FILTER: "SET_USER_NAME_FILTER",
  SET_USER_ESTABLISHMENT_FILTER: "SET_USER_ESTABLISHMENT_FILTER",
});

export const ADD_USER = (payload) => ({
  type: ACTION_TYPE.ADD_USER,
  payload,
});

const SET_LOAD_USERS_IN_PROCESS = (payload) => ({
  type: ACTION_TYPE.SET_LOAD_USERS_IN_PROCESS,
  payload,
});

export const SET_USERS = (payload = []) => ({
  type: ACTION_TYPE.SET_USERS,
  payload,
});

export const CLEAR_USERS_FILTER = (payload) => ({
  type: ACTION_TYPE.CLEAR_FILTER,
  payload,
});

export const TOGGLE_USERS_FILTER = (payload) => createAction(
    ACTION_TYPE.TOGGLE_USERS_FILTER,
    null
);

export const SET_USER_ROLE_FILTER = (payload) => ({
  type: ACTION_TYPE.SET_USER_ROLE_FILTER,
  payload,
});

export const SET_USER_NAME_FILTER = (payload) => ({
  type: ACTION_TYPE.SET_USER_NAME_FILTER,
  payload,
});

export const SET_USER_ESTABLISHMENT_FILTER = (payload) => ({
  type: ACTION_TYPE.SET_USER_ESTABLISHMENT_FILTER,
  payload,
});

export const loadUsers = () => {

  return (dispatch, getState) => {
    dispatch(SET_LOAD_USERS_IN_PROCESS(true));

    return fetchGet(apiUrls.users.getAll.url)
        .then((response) => {
          if (response.success) {
            dispatch(SET_USERS(response.users));
          }
        })
        .finally(() => {
          dispatch(SET_LOAD_USERS_IN_PROCESS(false));
        });
  };
};