import {createAction} from "../../factory/redux/action";
import {fetchPost} from "../../utils/request";
import {apiUrls} from "../../constants/urls";
import {buildCardData} from "../../utils/card";

export const ACTION_TYPE = Object.freeze({
  DROP_CLIENT_MERGE_DATA: "DROP_CLIENT_MERGE_DATA",

  SET_CARD_MERGE_IN_PROCESS: "SET_CARD_MERGE_IN_PROCESS",
  SET_CARD_MERGE_SHOW_MODAL: "SET_CARD_MERGE_SHOW_MODAL",
  SET_CARD_MERGE_SHOW_ERROR: "SET_CARD_MERGE_SHOW_ERROR",

  SET_CARD_FROM_DATA: "SET_CARD_FROM_DATA",
  SET_CARD_FROM_PHOTO: "SET_CARD_FROM_PHOTO",
  SET_CARD_TO_DATA: "SET_CARD_TO_DATA",
  SET_CARD_TO_PHOTO: "SET_CARD_TO_PHOTO",
});


const SET_CARD_MERGE_IN_PROCESS = (payload) => createAction(
    ACTION_TYPE.SET_CARD_MERGE_IN_PROCESS,
    payload
);

export const SET_CARD_MERGE_SHOW_ERROR = (payload) => createAction(
    ACTION_TYPE.SET_CARD_MERGE_SHOW_ERROR,
    payload
);

export const SET_CARD_MERGE_SHOW_MODAL = (payload) => createAction(
    ACTION_TYPE.SET_CARD_MERGE_SHOW_MODAL,
    payload
);

export const SET_CARD_FROM_DATA = (cardData) => createAction(
    ACTION_TYPE.SET_CARD_FROM_DATA,
    cardData
);

export const SET_CARD_FROM_PHOTO = (cardData) => createAction(
    ACTION_TYPE.SET_CARD_FROM_PHOTO,
    cardData
);

export const SET_CARD_TO_DATA = (cardData) => createAction(
    ACTION_TYPE.SET_CARD_TO_DATA,
    cardData
);

export const SET_CARD_TO_PHOTO = (cardData) => createAction(
    ACTION_TYPE.SET_CARD_TO_PHOTO,
    cardData
);




export function loadCardMergeData(cardFromId, cardToId) {


  return function (dispatch, getState) {

    dispatch(SET_CARD_MERGE_IN_PROCESS(true));

    return loadCardData(cardFromId)

        .then((response) => {
          if (response.success) {
            const responseData = response.data;

            // const cardData = buildCardData(responseData);

            dispatch(SET_CARD_FROM_DATA(responseData));

            return responseData.photoList;

          } else {
            return Promise.reject(new Error("Card merge error"));
          }
        })
        .then((photoList) => {

          if(photoList.length > 0) {
            const firstPhoto = photoList[0];

            loadPhoto(firstPhoto.id)
                .then((response) => {
                  if(response.success) {
                    dispatch(SET_CARD_FROM_PHOTO(response.photoData))
                  }
                })
          }

        })
        .then(() => {

          return loadCardData(cardToId);

        })
        .then((response) => {
          if (response.success) {
            const responseData = response.data;

            // const cardData = buildCardData(responseData);

            dispatch(SET_CARD_TO_DATA(responseData));

            return responseData.photoList;

          } else {
            return Promise.reject(new Error("Card merge error"));
          }
        })
        .then((photoList) => {

          if(photoList.length > 0) {
            const firstPhoto = photoList[0];

            loadPhoto(firstPhoto.id)
                .then((response) => {
                  if(response.success) {
                    dispatch(SET_CARD_TO_PHOTO(response.photoData))
                  }
                })
          }

        })
        .finally(() => {
          dispatch(SET_CARD_MERGE_IN_PROCESS(false));
        });
  }
}

function loadCardData(cardId) {
  return fetchPost(
      apiUrls.client.get.url,
      {
        clientId: cardId,
      }
  );
}

function loadPhoto(photoId) {
  return fetchPost(
      apiUrls.photo.getPhoto.url,
      {
        photoId: photoId,
      }
  );
}

export function mergeCards(cardFromId, cardToId){

  return function(dispatch, getState) {

    dispatch(SET_CARD_MERGE_IN_PROCESS(true));

    return fetchPost(
        apiUrls.client.merge.url,
        {
          cardFromId,
          cardToId
        }
    )
        .then((response) => {
          if(response.success) {

          } else {
            return Promise.reject(new Error("Card merge error"));
          }
        })
        .finally(() => {
          dispatch(SET_CARD_MERGE_IN_PROCESS(false));
        })

  }

}